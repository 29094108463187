// import format from 'date-fns/format';
import * as polyline from 'google-polyline';
import { format } from 'date-fns';
import { Polyline, ProcessedPolylineItem, Vec2, UserSettings, WeatherLike } from '../types';
import { rgbToHex } from './utils';
import { WeatherIcons } from '../components';
import { SETTINGS } from '../settings';
import { isHourlyWeatherArray } from '../types/guards';

// @NOTE this is overly complex due to sharing a file with the backend
// let settings;
// try {
//   settings = require('../config');
//   settings = settings.SETTINGS ? settings.SETTINGS : settings;
// } catch (e) {
//   // @NOTE if you change these settings make sure to update ./config/settings.js
//   settings = {
//     hardness: {
//       outof: 5,
//       maxWindSpeed: 30,
//       getsHardAt: 3,
//     },
//     defaultUserSettings: {
//       units: {
//         distance: 'kilometers',
//         rainfall: 'millimeters',
//         temperature: 'celsius',
//       },
//     },
//   };
// }
// export const SETTINGS = settings;

export const normalizeDate = (date?: Date) => {
  return format(date || new Date(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const easeHardnessInOutQuad = (t: number, b: number, c: number, d: number) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
};

// export const getLineAngle = (start: Vec2, end: Vec2) => {
//   const dy = end[0] - start[0];
//   const dx = end[1] - start[1];
//   let theta = Math.atan2(dy, dx); // range (-PI, PI]
//   theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
//   if (theta < 0) {
//     theta = 360 + theta;
//   } // range [0, 360)
//   return theta;
// };

export const getLineAngle = (p1: Vec2, p2: Vec2) => {
  let angle = (Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * 180) / Math.PI;
  if (angle < 0) {
    angle = 360 + angle;
  }
  return angle;
};

export const mapboxPolyline = (polyline: Polyline): Polyline => {
  return polyline.map(point => {
    return [point[1], point[0]];
  });
};

export const latLngToPoint = (lat: number, lng: number, multiplier = 1): Vec2 => {
  return [
    (lng + 180) * (256 / 360) * multiplier,
    256 / 2 - ((256 * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 180 / 2))) / (2 * Math.PI)) * multiplier,
  ];
};

export const getHardnessColor = (hardness: number, min = 0, max = 0) => {
  let range = 100;
  if (max) {
    range = max - min;
    hardness = hardness - min;
  }
  const color = Math.min(Math.abs(Math.round((255 / range) * hardness)), 255);
  const rgb: [number, number, number] = [255, 255 - color, color];
  return rgbToHex(rgb);
};

export const processPolylines = (path: Polyline, windDir: number, windSpeed: number) => {
  const lines = [];
  let lastPoint = null;
  let lastPxPoint = null;
  for (const point of path) {
    let angle = 0;
    const pxPoint = latLngToPoint(point[1], point[0], 10000);
    if (lastPoint && lastPxPoint) {
      angle = getLineAngle(lastPxPoint, pxPoint);
      let windFactor = Math.abs(90 + windDir - angle);
      if (windFactor > 180) {
        windFactor = Math.abs(windFactor - 360);
      }
      const hardness = (windFactor / 180) * 100;
      const line: ProcessedPolylineItem = {
        color: getHardnessColor(hardness),
        path: [lastPoint, point],
        angle,
        hardness: {
          dir: hardness,
          speed: windSpeed,
        },
      };
      lines.push(line);
    }
    lastPoint = point;
    lastPxPoint = pxPoint;
  }
  return lines;
};

export const getRideHardness = (encodedPolyline: string | null, weather: WeatherLike) => {
  if (!encodedPolyline) {
    return undefined;
  }

  const windDir = weather.windBearing;
  const windSpeed = weather.windSpeed;
  const maxWindSpeed = SETTINGS.HARDNESS_MAX_WIND_SPEED;

  const polylines: Polyline = mapboxPolyline(polyline.decode(encodedPolyline));

  const sections = processPolylines(polylines, windDir, windSpeed);

  // get wind speed hardness
  const windSpeedHardness = Math.min((windSpeed / maxWindSpeed) * 100, 100);

  // get wind dir hardness
  let windDirHardness = 0;
  for (const section of sections) {
    windDirHardness += section.hardness.dir;
  }
  windDirHardness = windDirHardness / sections.length;

  // work out total hardness
  let hardness = ((windSpeedHardness * windDirHardness) / 10000) * 100;
  //console.log(hardness);
  hardness = (hardness / 100) * SETTINGS.HARDNESS_OUT_OF;
  //console.log(hardness);
  hardness = easeHardnessInOutQuad(hardness, 0, SETTINGS.HARDNESS_OUT_OF, SETTINGS.HARDNESS_OUT_OF);
  //console.log(hardness);
  hardness = Math.round(hardness * 10) / 10;
  //console.log(hardness);

  return hardness;
};

export const getWeatherIcon = (weather: WeatherLike) => {
  const current = weather.currently || weather;
  const hour = Number(format(new Date(current.time * 1000), 'HH'));
  let severeRisk = weather.severerisk || 0;
  let precipTypes = weather.precipType || [];
  if (isHourlyWeatherArray(weather?.hourly?.data)) {
    const currentHour = weather.hourly.data[hour];
    severeRisk = currentHour.severerisk || 0;
    precipTypes = currentHour.precipType || [];
  }
  const icon = [];
  if (severeRisk < 70) {
    let precipType = '';
    let cloud = 'clear';
    let precip = 'clear';
    // rain or snow
    if (precipTypes.includes('snow') || precipTypes.includes('freezingrain')) {
      precipType = 'snow';
    } else if (precipTypes.includes('rain')) {
      precipType = 'rain';
    }
    // how much rain or snow
    const daily = current.precipIntensity * 24;
    if (precipType && daily > 1) {
      if (daily > 9) {
        precip = `${precipType}-4`;
      } else if (daily > 6) {
        precip = `${precipType}-3`;
      } else if (daily > 3) {
        precip = `${precipType}-2`;
      } else {
        precip = `${precipType}-1`;
      }
      icon.push(precip);
      // else no rain or snow so just use cloud
    } else {
      if (current.cloudCover > 0.8) {
        cloud = 'cloud-4';
      } else if (current.cloudCover > 0.6) {
        cloud = 'cloud-3';
      } else if (current.cloudCover > 0.4) {
        cloud = 'cloud-2';
      } else if (current.cloudCover > 0.2) {
        cloud = 'cloud-1';
      }
      icon.push(cloud);
    }
    let cycle = 'day';
    if (current.sunriseEpoch && current.sunsetEpoch) {
      const time = current.time * 1000;
      if (time < current.sunriseEpoch || time > current.sunsetEpoch) {
        cycle = 'night';
      }
    } else {
      if (hour < 6 || hour > 19) {
        cycle = 'night';
      }
    }
    icon.push(cycle);
  } else {
    icon.push('storm');
  }
  // cloud fallback
  if (!icon.length) {
    icon.push('cloud-1-day');
  }
  const iconPath = icon.join('-');
  return iconPath as WeatherIcons;
};

export const degToCompass = (n: number) => {
  var val = Math.floor(n / 22.5 + 0.5);
  var arr = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
  return arr[val % 16];
};

export const getUserPrefUnit = (type: keyof UserSettings['unitPrefs'], units: UserSettings['unitPrefs']) => {
  if (units && units[type]) {
    switch (units[type]) {
      case 'kilometers':
        return 'KM';
      case 'millimeters':
        return 'MM';
      default:
        return units[type];
    }
  }
  return type;
};

export const convertToUserPref = (
  number: number,
  type: keyof UserSettings['unitPrefs'],
  units: UserSettings['unitPrefs'],
) => {
  if (!units) {
    return oneDecPlace(number);
  }
  let value = number;
  if (units && units[type]) {
    if (units[type] === 'miles' || units[type] === 'MPH') {
      value = kmToMiles(number);
    } else if (units[type] === 'inches') {
      value = mmToInches(number);
    } else if (units[type] === 'fahrenheit') {
      value = centigradeToFahrenheit(number);
    }
  }
  return oneDecPlace(value);
};

export const kmToMiles = (km: number) => {
  return km / 1.609;
};

export const milesToKm = (miles: number) => {
  return miles * 1.609;
};

export const centigradeToFahrenheit = (c: number) => {
  return (c * 9) / 5 + 32;
};

export const mmToInches = (mm: number) => {
  return mm / 25.4;
};

export const metersToKm = (m: number) => {
  return m / 1000;
};

export const kmToMeters = (km: number) => {
  return km * 1000;
};

export const oneDecPlace = (n: number) => {
  return Math.ceil(n * 10) / 10;
};

export const twoDecPlace = (n: number) => {
  return Math.round((n + Number.EPSILON) * 100) / 100;
};

export const formatApiTimestamp = (stamp: number, pattern: string) => {
  // return format(stamp * 1000, pattern);
  return format(stamp, pattern);
};

import { ParentComponent, Show, createSignal, onCleanup, onMount } from 'solid-js';
import { BeforeInstallPromptEvent } from '../../types';
import { isInstalled, isIosDevice, trackEvent } from '../../utils';
import { Button, ButtonProps } from '../Button';
import { IosAddToHomeDialog } from '../ModalIosAddToHome';

// TODO: Make button link to app store as fallback when app is available

interface Props extends ButtonProps {
  onCancel?: () => void;
  onInstall?: () => void;
  forceShow?: boolean;
  forceIos?: boolean;
}

export const InstallAppButton: ParentComponent<Props> = props => {
  const [canInstall, setCanInstall] = createSignal(window.headwind_installPrompt);
  const [iosModalOpen, setIosModalOpen] = createSignal(false);
  const loggedInstall = localStorage.getItem('ios_installed');
  const installed = isInstalled();
  const isIos = props.forceIos !== undefined ? props.forceIos : isIosDevice();

  onMount(() => {
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
    if (isIos && installed && !loggedInstall) {
      localStorage.setItem('ios_installed', 'true');
      trackEvent('IOS Install Button', 'success');
    }
  });

  onCleanup(() => {
    window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
  });

  const beforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // save the prompt for later use
    window.headwind_installPrompt = e;
    setCanInstall(e);
  };

  const install = () => {
    if (isIos) {
      setIosModalOpen(true);
      return;
    }
    const prompt = canInstall();
    if (!prompt) {
      return;
    }
    prompt.prompt();
    trackEvent('Install App Button', 'click');
    // wait for the user to respond to the prompt
    prompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        props.onInstall && props.onInstall();
        trackEvent('Install App Button', 'success');
      } else {
        props.onCancel && props.onCancel();
        trackEvent('Install App Button', 'cancel');
      }
      window.headwind_installPrompt = undefined;
      setCanInstall(undefined);
    });
  };

  const onIosModalClose = () => {
    setIosModalOpen(false);
  };

  return (
    <Show when={!installed}>
      <IosAddToHomeDialog open={iosModalOpen()} onClose={onIosModalClose} maxWidth={300}>
        <h3>Install Headwind</h3>
        <p>Install Headwind to your home screen for quick and easy access when you are on the go.</p>
      </IosAddToHomeDialog>
      <Show when={canInstall() || isIos || props.forceShow}>
        <Button icon="IconDownload" {...props} onClick={install}>
          Install App
        </Button>
      </Show>
    </Show>
  );
};

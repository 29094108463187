import cn from 'classnames';
import Hammer from 'hammerjs';
import { animate } from 'motion';
import { children, createEffect, createSignal, For, mergeProps, onCleanup, onMount, ParentComponent } from 'solid-js';
import { easing } from 'ts-easing';
import { arrayUnique } from '../../utils';
import { HoverBurst } from '../HoverBurst';
import { themeClass } from '../ThemeProvider';
import styles from './Tabs.module.scss';

interface TabsProps {
  activeIndex?: number;
  stretch?: boolean;
  center?: boolean;
  duration?: number;
  easing?: any; //TODO: type me
  swipeable?: boolean;
  slim?: boolean;
  navClass?: string;
  contentClass?: string;
  recalculateTabHeight?: number;
  fullHeight?: boolean;
  onChange?: (index: number) => void;
  onReady?: () => void;
}

interface TabProps {
  title: string;
  icon?: any;
  hidden?: boolean;
}

interface Tab extends HTMLDivElement {
  props: TabProps;
}

export const Tabs: ParentComponent<TabsProps> = passedProps => {
  const props = mergeProps(
    {
      activeIndex: 0,
      stretch: false,
      slim: false,
      easing: easing.outCubic,
      duration: 0.5,
      swipeable: true,
    },
    passedProps,
  );
  const kids = children(() => props.children);
  const [activeIndex, setActiveIndex] = createSignal(props.activeIndex);
  const [rendered, setRendered] = createSignal<number[]>([]);
  const [tabProps, setTabProps] = createSignal<TabProps[]>([]);
  const tabRefs: HTMLLIElement[] = [];
  const tabContentRefs: HTMLDivElement[] = [];
  let resizeDebounce: number = 0;
  let contentRef: HTMLDivElement | undefined;
  let navRef: HTMLUListElement | undefined;
  let indicatorRef: HTMLLIElement | undefined;
  let initTimeout: number;
  let tabContentHolderOuterRef: HTMLDivElement | undefined;

  onMount(() => {
    props.swipeable && setupSwipe();
    initTimeout = window.setTimeout(() => {
      const tabs = getTabs(kids());
      setTabProps(tabs.map(tab => tab.props));
      goToTab(activeIndex(), false);
      props.onReady && props.onReady();
    });

    window.addEventListener('resize', onResize);
  });

  onCleanup(() => {
    clearTimeout(initTimeout);
    window.removeEventListener('resize', onResize);
  });

  createEffect(prevIndex => {
    if (props.activeIndex !== undefined && prevIndex !== props.activeIndex) {
      goToTab(props.activeIndex);
    }
    return props.activeIndex;
  }, props.activeIndex);

  createEffect(prevRecalculateTabHeight => {
    if (prevRecalculateTabHeight !== props.recalculateTabHeight) {
      setTabContentHeight();
    }
    return props.recalculateTabHeight;
  }, props.recalculateTabHeight);

  const onResize = () => {
    animateIndicator(0);
    clearTimeout(resizeDebounce);
    resizeDebounce = window.setTimeout(() => {
      animateIndicator(0);
      setTabContentHeight();
    }, 200);
  };

  const setupSwipe = () => {
    if (contentRef) {
      const swipeRegion = new Hammer(contentRef);
      swipeRegion.on('swipe', function (e) {
        if (e.direction === 2) {
          goToTab(activeIndex() + 1);
        } else if (e.direction === 4) {
          goToTab(activeIndex() - 1);
        }
      });
    }
  };

  const animateContent = (duration = props.duration) => {
    if (contentRef) {
      const left = `-${100 * activeIndex()}%`;
      animate(
        contentRef,
        { left },
        {
          duration,
          easing: duration ? props.easing : undefined,
        },
      ).finished.then(() => {
        setTabContentHeight();
      });
    }
  };

  const animateIndicator = (duration = props.duration) => {
    if (indicatorRef && navRef) {
      const activeRef = tabRefs[activeIndex()];
      if (activeRef) {
        const width = `${activeRef.clientWidth}px`;
        const left = `${activeRef.offsetLeft}px`;
        animate(
          indicatorRef,
          { width: width, x: left },
          {
            duration,
            easing: duration ? props.easing : undefined,
          },
        );
      }
    }
  };

  const validateTabs = (el: any) => {
    let tabs: Tab[] = [];
    if (el) {
      if (typeof el === 'function') {
        tabs = [...tabs, ...getTabs(el())];
      } else if (el.classList && el.classList.contains(styles.tabContentInner)) {
        tabs.push(el);
      } else {
        console.warn('Warning: Only the <Tab> component is allowed to be rendered as a child of <Tabs>. Found:', el);
      }
    }
    return tabs;
  };

  const getTabs = (els: any) => {
    let tabs: Tab[] = [];
    if (Array.isArray(els)) {
      els.forEach(el => {
        tabs = [...tabs, ...validateTabs(el)];
      });
    } else {
      tabs = [...validateTabs(els)];
    }
    return tabs;
  };

  const goToTab = (index: number, animate = true) => {
    const tabs = getTabs(kids());
    let goto = index;
    if (goto < 0) {
      goto = 0;
    } else if (goto >= tabs.length) {
      goto = tabs.length - 1;
    }
    if (goto !== activeIndex()) {
      props.onChange && props.onChange(goto);
    }
    setRendered(arrayUnique([...rendered(), goto]));
    setActiveIndex(goto);
    animateContent(animate ? props.duration : 0);
    animateIndicator(animate ? props.duration : 0);
  };

  const setTabContentHeight = () => {
    if (tabContentHolderOuterRef && !props.fullHeight) {
      const contentHolder = tabContentRefs[activeIndex()];
      if (contentHolder) {
        const content = contentHolder.querySelector(`.${styles.tabContentInner}`);
        const height = content?.clientHeight ? `${content.clientHeight}px` : '';
        tabContentHolderOuterRef.style.height = height;
      }
    }
  };

  return (
    <div class={themeClass(styles.dark, styles.tabs)}>
      <ul
        ref={navRef}
        class={cn(styles.tabNav, {
          [styles.tabNavStretch]: props.stretch,
          [styles.tabNavCenter]: props.center,
          [styles.tabNavSlim]: props.slim,
          [String(props.navClass)]: props.navClass ? true : false,
        })}
      >
        <For each={tabProps()}>
          {(tab, i) => (
            <li
              onClick={() => goToTab(i())}
              ref={tabRefs[i()]}
              class={cn(styles.tab, {
                [styles.tabActive]: i() === activeIndex(),
              })}
            >
              <HoverBurst class={styles.innerTab}>
                {tab.icon && <i class={styles.tabIcon}>{tab.icon}</i>}
                {tab.title}
              </HoverBurst>
            </li>
          )}
        </For>
        <li ref={indicatorRef} class={styles.tabActiveIndicator}></li>
      </ul>
      <div
        ref={tabContentHolderOuterRef}
        class={cn(styles.tabContentHolderOuter, { [String(props.contentClass)]: props.contentClass ? true : false })}
      >
        <div class={styles.tabContentHolderInner} ref={contentRef}>
          <For each={getTabs(kids())}>
            {(tab, i) => (
              <div ref={tabContentRefs[i()]} class={styles.tabContentOuter}>
                {tab}
              </div>
            )}
            {/*
            TODO: Re-initiate only render if visible functionality. Its currently disabled as it stops the fade in on see functionality.
            {(tab, i) => <div class={styles.tabContentOuter}>{rendered().includes(i()) && tab}</div>}
            */}
          </For>
        </div>
      </div>
    </div>
  );
};

export const Tab: ParentComponent<TabProps> = props => {
  let ref: Tab | undefined;
  onMount(() => {
    if (ref) {
      ref.props = props;
    }
  });
  if (props.hidden) {
    return null;
  }
  return (
    <div class={styles.tabContentInner} ref={ref}>
      {props.children}
    </div>
  );
};

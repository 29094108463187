import cn from 'classnames';
import { ParentComponent, createSignal, onCleanup } from 'solid-js';
import { Icons, Input, Share } from '../';
import styles from './ShareInput.module.scss';

interface Props {
  url: string;
  title?: string;
  content?: string;
  label?: string;
  onlyClipboard?: boolean;
  onShare?: (copyMode: boolean) => void;
  onError?: (e: Error) => void;
  theme?: 'light' | 'dark';
  full?: boolean;
}

export const ShareInput: ParentComponent<Props> = props => {
  const [shared, setShared] = createSignal(false);
  const shareSupported = 'share' in navigator;
  const copyMode = props.onlyClipboard || !shareSupported;
  let inputRef: HTMLDivElement | undefined;
  let shareIconTimeout = 0;

  onCleanup(() => {
    clearTimeout(shareIconTimeout);
  });

  const onShare = () => {
    setShared(true);
    props.onShare && props.onShare(copyMode);
    clearTimeout(shareIconTimeout);

    shareIconTimeout = window.setTimeout(() => {
      setShared(false);
      if (inputRef) {
        const inputs = inputRef.getElementsByTagName('input');
        if (inputs[0]) {
          inputs[0].blur();
        }
      }
    }, 6000);
  };

  const onError = (e: any) => {
    props.onError && props.onError(e);
  };

  const getShareIcon = () => {
    let icon: Icons = 'IconShare';
    if (copyMode) {
      icon = 'IconCopy';
    }
    return icon;
  };

  return (
    <Share
      onShare={onShare}
      onError={onError}
      url={props.url}
      title={props.title}
      content={props.content}
      onlyClipboard={props.onlyClipboard}
      class={cn({
        [styles.full]: props.full,
      })}
    >
      <div ref={inputRef}>
        <Input
          label={props.label === undefined ? 'Share' : props.label}
          name="share"
          value={props.url}
          readOnly
          icon={shared() ? 'IconCheck' : getShareIcon()}
          iconStatus={shared() ? 'success' : 'default'}
          theme={props.theme}
        />
      </div>
    </Share>
  );
};

import { animate } from 'motion';
import { ParentComponent, createEffect, JSXElement, onCleanup, mergeProps, onMount, Show } from 'solid-js';
import styles from './NavDrawer.module.scss';
import { Icon } from '../Icon';
import { HoverPulse } from '../HoverPulse';
import { ScrollContainer } from '../ScrollContainer';
import { GradientBackground } from '../GradientBackground';
import { themeClass } from '../ThemeProvider';

interface Props {
  open: boolean;
  onClose?: () => void;
  position?: 'left' | 'right' | 'static';
  header?: JSXElement;
  footer?: JSXElement;
}

export const NavDrawer: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      position: 'right',
    },
    passedProps,
  );

  let navRef: HTMLDivElement | undefined;
  let overlayRef: HTMLDivElement | undefined;
  let closeRef: HTMLAnchorElement | undefined;

  onMount(() => {
    if (props.open) {
      animateIn(true);
    } else {
      animateOut(true);
    }
  });

  createEffect(prevOpen => {
    if (props.position !== 'static' && prevOpen !== props.open) {
      if (props.open) {
        animateIn();
      } else {
        animateOut();
      }
    } else if (props.position === 'static') {
      animateOut(true);
    }
    return props.open;
  }, props.open);

  const animateIn = (instant = false) => {
    if (navRef) {
      animate(navRef, { x: 0 }, { duration: instant ? 0 : 0.2 });
    }
    if (overlayRef) {
      animate(overlayRef, { opacity: 1 }, { duration: instant ? 0 : 0.2 });
    }
    if (closeRef) {
      animate(closeRef, { opacity: 1, x: 0 }, { duration: instant ? 0 : 0.2, delay: instant ? 0 : 0.3 });
    }
    document.body.classList.add(styles.modalOpen);
  };

  const animateOut = (instant = false) => {
    if (navRef) {
      animate(navRef, { x: props.position === 'left' ? '-100%' : '100%' }, { duration: instant ? 0 : 0.2 });
    }
    if (overlayRef) {
      animate(overlayRef, { opacity: 0 }, { duration: instant ? 0 : 0.2 });
    }
    if (closeRef) {
      animate(closeRef, { opacity: 0, x: props.position === 'left' ? '-100%' : '100%' }, { duration: 0 });
    }
    document.body.classList.remove(styles.modalOpen);
  };

  const close = (e: MouseEvent) => {
    e.preventDefault();
    props.onClose && props.onClose();
  };

  onCleanup(() => {
    document.body.classList.remove(styles.modalOpen);
  });

  return (
    <div
      class={themeClass(styles.dark, styles.navHolder, {
        [styles.open]: props.open || props.position === 'static',
        [styles.left]: props.position === 'left',
        [styles.static]: props.position === 'static',
      })}
    >
      <Show when={props.position !== 'static'}>
        <div ref={overlayRef} class={styles.overlay} onClick={close}></div>
        <a ref={closeRef} class={styles.close} href="#" onClick={close}>
          <HoverPulse>
            <Icon type="IconX" />
          </HoverPulse>
        </a>
      </Show>
      <div ref={navRef} class={styles.navDrawer}>
        {props.header && (
          <div class={styles.header}>
            <GradientBackground>
              <div class={styles.innerHeader}>{props.header}</div>
            </GradientBackground>
          </div>
        )}
        <ScrollContainer class={styles.nav}>{props.children}</ScrollContainer>
        <Show when={props.footer}>
          <div class={styles.footer}>{props.footer}</div>
        </Show>
      </div>
    </div>
  );
};

import { ParentComponent, mergeProps } from 'solid-js';
import styles from './LoadingSpinner.module.scss';
import { themeClass } from '../ThemeProvider';

interface Props {
  text?: string;
  size?: 'small' | 'medium' | 'large';
}

export const LoadingSpinner: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      size: 'medium',
    },
    passedProps,
  );

  return (
    <div
      class={themeClass(styles.dark, styles.container, {
        [String(styles[props.size])]: props.size ? true : false,
      })}
    >
      <div class={styles.spinner}>
        <div class={styles.spinner}></div>
      </div>
      {props.text && <div class={styles.text}>{props.text}</div>}
    </div>
  );
};

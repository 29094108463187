import { format } from 'date-fns';
import mapboxgl from 'mapbox-gl';
import { ParentComponent, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { Icon } from '../Icon';
import { themeClass, useTheme } from '../ThemeProvider';
import { showToast } from '../Toaster';
import styles from './RainRadarOverlay.module.scss';

interface Props {
  map?: mapboxgl.Map;
}

export const RainRadarOverlay: ParentComponent<Props> = props => {
  const [theme] = useTheme();
  const { mode } = theme();
  const [visible, setVisible] = createSignal(true);
  const [playing, setPlaying] = createSignal(true);
  const [loading, setLoading] = createSignal(true);
  const [activeFrame, setActiveFrame] = createSignal(0);
  const [radarError, setRadarError] = createSignal(false);
  const radarTheme = mode === 'dark' ? 3 : 8;
  const radarOpacity = 0.6;
  const animationSpeed = 1000;
  let animationTimeout = 0;
  let radarTimestamps: number[] = [];

  onMount(() => {
    if (props.map) {
      getRadarTimestamps()
        .then(timestamps => {
          radarTimestamps = timestamps;
          addRadarToMap();
          animateRadar();
        })
        .catch(() => {
          showToast('Sorry. An error occurred whilst loading the rain radar.', 'error');
          setRadarError(true);
        });
    }
  });

  onCleanup(() => {
    clearTimeout(animationTimeout);
    radarTimestamps.forEach((_, i) => {
      if (props.map) {
        const id = `radar-${i}`;
        props.map.getLayer(id) && props.map.removeLayer(id);
        props.map.getSource(id) && props.map.removeSource(id);
      }
    });
  });

  createEffect(prevActiveFrame => {
    const active = activeFrame();
    if (!props.map) {
      return active;
    } else if (active !== prevActiveFrame) {
      hideRadar();
      showActiveFrame();
    }
    return active;
  });

  createEffect(prevVisible => {
    const isVisible = visible();
    if (prevVisible !== isVisible) {
      if (!isVisible) {
        hideRadar();
      } else {
        showActiveFrame();
      }
    }
    return isVisible;
  });

  const getRadarTiles = (layer: number) => {
    return `https://tilecache.rainviewer.com/v2/radar/${layer}/512/{z}/{x}/{y}/${radarTheme}/1_1.png`;
  };

  const getRadarTimestamps = (): Promise<number[]> => {
    return new Promise((resolve, reject) => {
      fetch('https://api.rainviewer.com/public/maps.json')
        .then(res => {
          return res.json();
        })
        .then(json => {
          resolve(json);
        })
        .catch(e => {
          reject(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  // Find the index of the first symbol layer in the map style.
  // Useful for adding layers below map labels.
  const getFirstSymbolLayer = () => {
    let id;
    if (!props.map) {
      return id;
    }
    const layers = props.map.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        id = layers[i].id;
        break;
      }
    }
    return id;
  };

  const addRadarToMap = () => {
    const firstSymbolLayerID = getFirstSymbolLayer();
    radarTimestamps.forEach((layer, i) => {
      if (props.map) {
        const id = `radar-${i}`;
        props.map.addSource(id, {
          type: 'raster',
          tiles: [getRadarTiles(layer)],
          tileSize: 512,
        });
        props.map.addLayer(
          {
            id,
            type: 'raster',
            source: id,
            paint: {
              'raster-opacity': 0,
              'raster-resampling': 'linear',
              'raster-fade-duration': 0,
            },
          },
          firstSymbolLayerID,
        );
      }
    });
  };

  const hideRadar = () => {
    if (props.map) {
      for (let i = 0; i <= radarTimestamps.length - 1; i++) {
        const hideID = `radar-${i}`;
        const hideLayer = props.map.getLayer(hideID);
        if (hideLayer) {
          props.map.setPaintProperty(hideID, 'raster-opacity', 0);
        }
      }
    }
  };

  const showActiveFrame = () => {
    if (props.map) {
      const active = activeFrame();
      const showID = `radar-${active}`;
      const showLayer = props.map.getLayer(showID);
      if (showLayer) {
        props.map.setPaintProperty(showID, 'raster-opacity', visible() ? radarOpacity : 0);
      }
    }
  };

  const animateRadar = () => {
    let active = activeFrame() + 1;
    const speed = active === radarTimestamps.length - 1 ? animationSpeed * 2 : animationSpeed;
    if (active >= radarTimestamps.length) {
      active = 0;
    }

    playing() && setActiveFrame(active);

    animationTimeout = window.setTimeout(() => {
      requestAnimationFrame(() => {
        animateRadar();
      });
    }, speed);
  };

  const toggleVisibility = () => {
    setVisible(!visible());
  };

  const toggleAnimation = () => {
    setPlaying(!playing());
  };

  const radarNiceTime = () => {
    const time = radarTimestamps[activeFrame()];
    if (time) {
      return format(time * 1000, 'hh:mma');
    }
    return '';
  };

  const nextFrame = () => {
    const active = activeFrame();
    if (active < radarTimestamps.length - 1) {
      setActiveFrame(active + 1);
    }
  };

  const prevFrame = () => {
    const active = activeFrame();
    if (active > 0) {
      setActiveFrame(activeFrame() - 1);
    }
  };

  const startFrame = () => {
    setActiveFrame(0);
  };

  const endFrame = () => {
    setActiveFrame(radarTimestamps.length - 1);
  };

  const getRadarStatus = () => {
    if (loading()) {
      return 'loading...';
    } else if (!visible()) {
      return 'off';
    } else {
      return radarNiceTime();
    }
  };

  return (
    <div class={themeClass(styles.dark, styles.container, { [styles.error]: radarError() })}>
      <div class={styles.left}>
        <button onClick={toggleVisibility}>
          {visible() && <Icon type="IconEye" size="small" />}
          {!visible() && <Icon type="IconEyeOff" size="small" />}
        </button>
        <span class={styles.status}>Rain Radar: {getRadarStatus()}</span>
      </div>
      <div class={styles.right}>
        <button onClick={startFrame} disabled={playing() || activeFrame() === 0 || !visible()}>
          <Icon type="IconPlayerSkipBackFilled" size="small" />
        </button>
        <button onClick={prevFrame} disabled={playing() || activeFrame() === 0 || !visible()}>
          <Icon type="IconChevronLeft" size="small" />
        </button>
        <button onClick={toggleAnimation} disabled={!visible()}>
          {!playing() && <Icon type="IconPlayerPlayFilled" size="small" />}
          {playing() && <Icon type="IconPlayerPauseFilled" size="small" />}
        </button>
        <button onClick={nextFrame} disabled={playing() || activeFrame() === radarTimestamps.length - 1 || !visible()}>
          <Icon type="IconChevronRight" size="small" />
        </button>
        <button onClick={endFrame} disabled={playing() || activeFrame() === radarTimestamps.length - 1 || !visible()}>
          <Icon type="IconPlayerSkipForwardFilled" size="small" />
        </button>
      </div>
    </div>
  );
};

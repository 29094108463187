import { ParentComponent, mergeProps } from 'solid-js';
import { Icon } from '../Icon';
import styles from './Message.module.scss';
import cn from 'classnames';

export interface MessageProps {
  type: 'success' | 'info' | 'warning' | 'error' | 'default';
  icon?: boolean;
  slim?: boolean;
  rounded?: boolean;
  marginBottom?: boolean;
}

export const Message: ParentComponent<MessageProps> = passedProps => {
  const props = mergeProps(
    {
      icon: true,
      slim: false,
      rounded: false,
    },
    passedProps,
  );
  const getIcon = (type: MessageProps['type']) => {
    switch (type) {
      case 'success':
        return 'IconCircleCheck';
      case 'info':
        return 'IconInfoSquareRounded';
      case 'warning':
        return 'IconAlertTriangle';
      case 'error':
        return 'IconCircleX';
      default:
        return 'IconAlertCircle';
    }
  };

  return (
    <div
      class={cn(styles.message, styles[props.type], {
        [styles.slim]: props.slim,
        [styles.rounded]: props.rounded,
        [styles.marginBottom]: props.marginBottom,
      })}
    >
      {props.icon && !props.slim && <Icon size="medium" type={getIcon(props.type)} />}
      {props.children}
    </div>
  );
};

// Icon list: https://tablericons.com
import * as icons from '@tabler/icons-solidjs';
import cn from 'classnames';
import { Component, ParentComponent, createEffect, createSignal, mergeProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { useTheme } from '../ThemeProvider';
import * as CustomIconStrings from './custom-icon-strings';
import styles from './icon.module.scss';
import { Icons, StringIconTypes, TextIconTypes, WeatherIconTypes, WeatherIcons } from './Icon.types';
import * as WeatherIconImages from './weather-icons';

interface IconProps {
  type: Icons;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'full';
  stroke?: number;
}

interface WeatherIconProps {
  type: WeatherIcons;
}

const WeatherIcon: Component<WeatherIconProps> = props => {
  const [icon, setIcon] = createSignal((WeatherIconImages.default as any)[props.type]);

  createEffect(() => {
    setIcon((WeatherIconImages.default as any)[props.type]);
  }, props.type);

  if (icon()) {
    return <img src={icon()} />;
  }
  return null;
};

const CustomStringIcon: Component<WeatherIconProps> = props => {
  let ref: HTMLSpanElement | undefined;
  const icon = (CustomIconStrings as any)[props.type];

  createEffect(() => {
    if (ref && icon) {
      ref.innerHTML = icon;
    }
  });

  if (icon) {
    return <span class={styles.customStringIcon} ref={ref}></span>;
  }
  return null;
};

const TextIcon: Component<WeatherIconProps> = props => {
  if (props.type) {
    return <span class={styles.textIcon}>{props.type}</span>;
  }
  return null;
};

const iconComponents = {
  ...icons,
  IconWeather: WeatherIcon,
  IconCustomString: CustomStringIcon,
  IconText: TextIcon,
};

export const Icon: ParentComponent<IconProps> = passedProps => {
  const props = mergeProps(
    {
      type: passedProps.type as Icons,
      size: 'full',
      stroke: passedProps.size === 'large' ? 1 : 2,
    },
    passedProps,
  );
  const [theme] = useTheme();
  const { mode } = theme();
  const [iconKey, setIconKey] = createSignal(props.type);
  const [iconType, setIconType] = createSignal(props.type);

  createEffect(() => {
    // NOTE: have to use any... see here -> https://fettblog.eu/typescript-array-includes
    let type: any = props.type;
    let key: any = props.type;

    if (WeatherIconTypes.includes(type)) {
      type = `${type}-${mode}`;
      key = 'IconWeather';
    } else if (StringIconTypes.includes(type)) {
      key = 'IconCustomString';
    } else if (TextIconTypes.includes(type)) {
      key = 'IconText';
    }

    setIconKey(key);
    setIconType(type);
  }, props.type);

  return (
    <span
      class={cn(styles.icon, {
        [styles[props.size]]: passedProps.size,
      })}
    >
      {iconKey() && iconType() && (
        // TODO: fix typing here
        <Dynamic component={(iconComponents as any)[iconKey()]} stroke={props.stroke} type={iconType()} />
      )}
    </span>
  );
};

import cn from 'classnames';
import { animate } from 'motion';
import { createEffect, createSignal, For, mergeProps, onCleanup, onMount, ParentComponent } from 'solid-js';
import { InputValue } from '../../types';
import { capitalizeFirstLetter, niceString, objectUpdated, safeString } from '../../utils';
import { Icon } from '../Icon';
import { themeClass } from '../ThemeProvider';
import { ValidationError } from '../ValidationError/ValidationError';
import styles from './Select.module.scss';

export interface Option {
  label: string;
  value: any;
}

interface Props {
  label: string;
  name: string;
  options: Option[];
  value?: Option;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  touched?: boolean;
  onChange?: (option: InputValue) => void;
  onMount?: (value: InputValue) => void;
  theme?: 'light' | 'dark';
  noPleaseSelect?: boolean;
}

export const Select: ParentComponent<Props> = passedProps => {
  const defaultValue = {
    label: 'Please Select',
    value: null,
  };

  const props = mergeProps(
    {
      value: passedProps.options && passedProps.noPleaseSelect ? passedProps.options[0] : defaultValue,
      options: [defaultValue],
    },
    passedProps,
  );
  const ID = safeString(props.name);
  const niceName = capitalizeFirstLetter(niceString(props.name));
  const [value, setValue] = createSignal<Option>(props.value);
  const [valid, setValid] = createSignal(true);
  const [error, setError] = createSignal('');
  const [touched, setTouched] = createSignal(props.touched);
  const [isOpen, setIsOpen] = createSignal(false);
  let selectRef: HTMLDivElement | undefined;
  let dropdownRef: HTMLUListElement | undefined;
  let blurDelay = 0;

  const selectOption = (option: Option) => {
    if (objectUpdated(option, value())) {
      const isValid = validate(option);
      setValid(isValid);
      setValue(option);
      props.onChange && props.onChange({ value: option.value, name: ID, valid: isValid });
    }
  };

  const validate = (option: Option) => {
    if (props.required) {
      if ((option.value === null || option.value === undefined) && touched()) {
        setValid(false);
        setError(`${niceName} is required`);
        return false;
      } else {
        setValid(true);
        setError('');
        return true;
      }
    } else {
      setValid(true);
      setError('');
      return true;
    }
  };

  onMount(() => {
    const isValid = validate(value());
    props.onMount && props.onMount({ value: value().value, name: ID, valid: isValid });
  });

  createEffect((prevValue: Option) => {
    if (objectUpdated(prevValue, props.value)) {
      selectOption(props.value);
    }
    return props.value;
  }, props.value);

  const open = () => {
    setTouched(true);
    setIsOpen(true);
    if (dropdownRef && selectRef) {
      dropdownRef.style.pointerEvents = 'all';
      selectRef.style.zIndex = '2';
      animate(
        dropdownRef,
        { opacity: 1, y: 0 },
        {
          duration: 0.1,
        },
      );
    }
  };

  const close = () => {
    setIsOpen(false);
    blurDelay = window.setTimeout(async () => {
      if (dropdownRef) {
        animate(
          dropdownRef,
          { opacity: 0, y: -10 },
          {
            duration: 0.2,
          },
        ).finished.then(() => {
          if (dropdownRef && selectRef) {
            dropdownRef.style.pointerEvents = '';
            selectRef.style.zIndex = '1';
          }
        });
      }
    }, 0);
  };

  const onClick = () => {
    if (isOpen()) {
      close();
    } else {
      open();
    }
  };

  onCleanup(() => {
    clearTimeout(blurDelay);
  });

  return (
    <>
      <div
        class={themeClass(props.theme ? styles[props.theme] : styles.dark, styles.selectHolder, {
          [styles.disabled]: props.disabled,
          [styles.readOnly]: props.readOnly,
        })}
      >
        {props.label && (
          <label for={ID}>
            {props.label} {props.required && <sup>*</sup>}
          </label>
        )}
        <div ref={selectRef} class={styles.select}>
          <input
            type="text"
            id={ID}
            placeholder="Please Select"
            value={value().label === defaultValue.label ? '' : value().label}
            readOnly
            onClick={onClick}
            onBlur={close}
            disabled={props.disabled}
          />
          <div class={styles.handle}>
            {isOpen() && <Icon type="IconChevronUp" size="medium" />}
            {!isOpen() && <Icon type="IconChevronDown" size="medium" />}
          </div>
          <ul ref={dropdownRef} class={styles.dropdown}>
            {passedProps.options && !passedProps.noPleaseSelect && (
              <li onClick={selectOption.bind(null, defaultValue)} class={styles.item}>
                {defaultValue.label}
              </li>
            )}
            <For each={props.options}>
              {(option, i) => (
                <li
                  onClick={selectOption.bind(null, option)}
                  class={cn(styles.item, { [styles.activeItem]: option.value === value().value })}
                >
                  {option.label}
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
      {!valid() && error() && <ValidationError>{error()}</ValidationError>}
    </>
  );
};

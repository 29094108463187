import { ParentComponent, mergeProps } from 'solid-js';

interface Props {
  title?: string;
  content?: string;
  url?: string;
  onlyClipboard?: boolean;
  onShare?: () => void;
  onError?: (e: string) => void;
  withBlurb?: boolean;
  class?: string;
}

export const Share: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      title: document.title,
      content: '',
      url: window.location.href,
    },
    passedProps,
  );

  const shareSupported = 'share' in navigator;
  const copySupported = 'clipboard' in navigator && 'writeText' in navigator.clipboard;

  const onShare = () => {
    if (shareSupported && !props.onlyClipboard) {
      onNativeShare();
    } else {
      onFallbackShare();
    }
  };

  const onFallbackShare = async () => {
    let text = props.url;
    if (props.withBlurb) {
      text = `${props.title} ${props.content && `- ${props.content}`} -> ${props.url}`;
    }
    if (copySupported) {
      try {
        await navigator.clipboard.writeText(text);
        props.onShare && props.onShare();
      } catch (e: any) {
        const error = e.message || e;
        props.onError && props.onError(error);
      }
    } else {
      props.onError && props.onError('Neither native share or copy to clipboard supported.');
    }
  };

  const onNativeShare = async () => {
    try {
      await navigator.share({
        title: props.title,
        text: props.content,
        url: props.url,
      });
      props.onShare && props.onShare();
    } catch (e: any) {
      const error = e.message || e;
      if (error && !error.includes('canceled')) {
        onFallbackShare();
      } else if (error) {
        props.onError && props.onError(error);
      }
    }
  };

  if (!shareSupported && !copySupported) {
    return null;
  }

  return (
    <div onClick={onShare} class={props.class}>
      {props.children}
    </div>
  );
};

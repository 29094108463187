import { Amplify } from 'aws-amplify';
import * as amplifyConfig from '../amplifyconfiguration.json';
import { isLiveEnvironment } from './utils';

export const getApiBasePath = () => {
  let endpoint = 'https://api.develop.headwind.app';
  if (isLiveEnvironment()) {
    endpoint = 'https://api.headwind.app';
  }
  return endpoint;
};

export const configureAmplify = (debug = false) => {
  const endpoint = getApiBasePath();
  if (Array.isArray(amplifyConfig.aws_cloud_logic_custom)) {
    amplifyConfig.aws_cloud_logic_custom[0].endpoint = endpoint;
  }
  Amplify.configure(amplifyConfig);
  if (debug) {
    (window as any).LOG_LEVEL = 'DEBUG';
  }
};

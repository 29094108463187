import { addDays, format } from 'date-fns';
import { ParentComponent } from 'solid-js';
import { SETTINGS } from '../../settings';
import { convertToUserPref, getUserPrefUnit, getWindHardnessColor } from '../../utils';
import { formatApiTimestamp, getHardnessColor } from '../../utils/shared';
import { CenterContainer } from '../CenterContainer';
import { WeatherIcons } from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';
import { StatItem } from '../StatItem';
import { themeClass } from '../ThemeProvider';
import { WeatherIcon } from '../WeatherIcon';
import styles from './ForecastItem.module.scss';

interface Props {
  time: number;
  summary: string;
  temp: number;
  rainfall: number;
  windSpeed: number;
  icon: WeatherIcons;
  windGust?: number;
  windDir?: number;
  hardness?: number;
  variation?: 'slim' | 'default' | 'large';
  onClick?: () => void;
  ready?: boolean;
}

export const ForecastItem: ParentComponent<Props> = props => {
  const getHour = () => {
    return formatApiTimestamp(props.time, 'HH:mm');
  };

  const getNiceTime = () => {
    const forecastDate = formatApiTimestamp(props.time, `yyyy-MM-dd'T'00:00:00`);
    const todaysDate = format(new Date(), `yyyy-MM-dd'T'00:00:00`);
    const tomorrowsDate = format(addDays(new Date(todaysDate), 1), `yyyy-MM-dd'T'00:00:00`);
    let niceTime = formatApiTimestamp(props.time, 'EEE MMMM do');

    if (forecastDate === todaysDate) {
      niceTime = 'Today';
    } else if (forecastDate === tomorrowsDate) {
      niceTime = 'Tomorrow';
    }
    return niceTime;
  };

  const getColor = () => {
    if (props.hardness !== undefined) {
      return getHardnessColor(props.hardness, 0, SETTINGS.HARDNESS_OUT_OF);
    }
    return getWindHardnessColor(props.windSpeed);
  };

  const getStatSize = () => {
    switch (props.variation) {
      case 'slim':
        return 'small';
      case 'large':
        return 'large';
      default:
        return 'medium';
    }
  };

  return (
    <div
      class={themeClass(styles.dark, styles.container, {
        [styles[String(props.variation)]]: props.variation ? true : false,
        [styles.loading]: props.ready === false,
      })}
      style={{ 'border-left-color': getColor() }}
    >
      <div class={styles.header}>
        {props.variation !== 'slim' && (
          <div class={styles.icon}>
            <WeatherIcon type={props.icon} />
          </div>
        )}
        <div class={styles.title}>
          <span class={styles.date}>
            {getNiceTime()} at {getHour()}
          </span>
          <span class={styles.summary}>{props.summary}</span>
        </div>
      </div>
      <div class={styles.stats}>
        {props.variation === 'slim' && (
          <div class={styles.stat}>
            <div class={styles.icon}>
              <WeatherIcon type={props.icon} size="small" />
            </div>
          </div>
        )}
        <div class={styles.stat}>
          <StatItem
            value={convertToUserPref(props.temp, 'temperature')}
            label="temp"
            units={getUserPrefUnit('temperature')}
            size={getStatSize()}
          />
        </div>
        <div class={styles.stat}>
          <StatItem
            value={convertToUserPref(props.rainfall, 'rainfall')}
            label="rain"
            units={getUserPrefUnit('rainfall')}
            size={getStatSize()}
          />
        </div>
        {props.windDir !== undefined && (
          <div class={styles.stat}>
            <StatItem value={props.windDir} label="wind dir" type="wind" windColor={getColor()} size={getStatSize()} />
          </div>
        )}
        <div class={styles.stat}>
          <StatItem
            value={convertToUserPref(props.windSpeed, 'speed')}
            label="wind speed"
            units={getUserPrefUnit('speed')}
            size={getStatSize()}
          />
        </div>
        {props.windGust !== undefined && (
          <div class={styles.stat}>
            <StatItem
              value={convertToUserPref(props.windGust, 'speed')}
              label="wind gust"
              units={getUserPrefUnit('speed')}
              size={getStatSize()}
            />
          </div>
        )}
        {props.hardness !== undefined && (
          <div class={styles.stat}>
            <StatItem
              value={props.hardness}
              label="difficulty"
              units={`out of ${SETTINGS.HARDNESS_OUT_OF}`}
              warning={props.hardness >= SETTINGS.HARDNESS_GETS_HARD_AT}
              size={getStatSize()}
            />
          </div>
        )}
      </div>
      <CenterContainer class={styles.loader}>
        <LoadingSpinner size="small" />
      </CenterContainer>
    </div>
  );
};

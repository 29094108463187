import cn from 'classnames';
import merge from 'deepmerge';
import {
  Accessor,
  Component,
  Context,
  createContext,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  ParentComponent,
  Setter,
  useContext,
} from 'solid-js';
import { Settings, SETTINGS } from '../../settings';
import breakpoints from '../../styles/breakpoints.module.scss';
import colors from '../../styles/colors.module.scss';
import fonts from '../../styles/fonts.module.scss';
import layers from '../../styles/layers.module.scss';
import misc from '../../styles/misc.module.scss';
import spacing from '../../styles/spacing.module.scss';
import { FalsyTruthyObject, StringObject } from '../../types';
import { storage } from '../../utils';
import styles from './ThemeProvider.module.scss';

// TODO: work out how to type css imports
export interface Theme {
  mode: 'dark' | 'light';
  layout: 'mobile' | 'desktop';
  colors: StringObject;
  spacing: StringObject;
  layers: StringObject;
  misc: StringObject;
  breakpoints: StringObject;
  fonts: StringObject;
  settings: Settings;
}

interface Props {
  theme: Theme;
}

type Themer = [Accessor<Theme>, Setter<Theme>];

export const defaultTheme: Theme = {
  mode: 'light',
  layout: 'mobile',
  colors,
  spacing,
  layers,
  misc,
  breakpoints,
  fonts,
  settings: SETTINGS,
};

const defaultSetter = (theme: Theme) => {
  console.warn(`Theme setter not set up yet`, theme);
};

const ThemeWatcher: Component<Props> = props => {
  createEffect(() => {
    // Set the body class based on theme
    const themeClass = props.theme.mode === 'dark' ? styles.dark : styles.light;
    document.body.classList.remove(styles.dark);
    document.body.classList.remove(styles.light);
    document.body.classList.add(themeClass);

    // Update storage
    storage.setItem('theme', props.theme.mode);
  });
  return null;
};

const ThemeContext: Context<Themer> = createContext([defaultTheme, defaultSetter] as any as Themer);

export const ThemeProvider: ParentComponent<Props> = props => {
  const [theme, setTheme] = createSignal(merge(defaultTheme, props.theme));
  let resizeTimeoutRef: number = 0;

  onMount(() => {
    updateLayout();
    window.addEventListener('resize', resize);
  });

  onCleanup(() => {
    window.removeEventListener('resize', resize);
  });

  const resize = () => {
    clearTimeout(resizeTimeoutRef);
    resizeTimeoutRef = window.setTimeout(updateLayout, 200);
  };

  const updateLayout = () => {
    if (window.matchMedia(`(min-width: ${breakpoints.tablet})`).matches) {
      setTheme({ ...theme(), layout: 'desktop' });
    } else {
      setTheme({ ...theme(), layout: 'mobile' });
    }
  };

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <ThemeWatcher theme={theme()} />
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const isDarkMode = () => {
  const [theme] = useTheme();
  let mode: Theme['mode'] = 'light';
  if (typeof theme === 'function') {
    mode = theme().mode;
  }
  return mode === 'dark';
};

export const isMobileLayout = () => {
  const [theme] = useTheme();
  const layout = theme().layout;
  return layout === 'mobile';
};

export const themeClass = (darkModeClass: string, mainClass: string, classes?: FalsyTruthyObject) => {
  const classNames = {
    [darkModeClass]: isDarkMode(),
    ...classes,
  };
  return cn(mainClass, classNames);
};

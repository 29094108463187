import * as Sentry from '@sentry/browser';
import { SETTINGS } from '../settings';
import { isLiveEnvironment } from './utils';

export const sentryEnabled = () => {
  return APP_VERSION && isLiveEnvironment();
};

export const initSentry = () => {
  if (sentryEnabled()) {
    Sentry.init({
      dsn: SETTINGS.SENTRY_DSN,
      release: APP_VERSION,
      // Only report on errors from js files in the assets dir on the live domain name
      allowUrls: [/^https:\/\/headwind.app\/assets\/.*\.js$/],
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        // Don't report network errors
        if (error && String(error).toLowerCase().includes('network error')) {
          return null;
        }
        return event;
      },
    });
  }
};

export const sendErrorToSentry = (error: string) => {
  if (sentryEnabled()) {
    Sentry.captureException(new Error(error));
  }
};

import cn from 'classnames';
import { For, ParentComponent, createEffect, createSignal, onMount } from 'solid-js';
import { getUserPrefUnit, isMobileDevice, objectUpdated } from '../../utils';
import { ScrollContainer } from '../ScrollContainer';
import { StatItem } from '../StatItem';
import { themeClass } from '../ThemeProvider';
import styles from './HourlyWind.module.scss';

interface Day {
  windDir: number;
  windSpeed: number;
  time: string;
  active?: boolean;
}

interface Props {
  days: Day[];
  onSelectHour: (index: number, time: string) => void;
}

export const HourlyWind: ParentComponent<Props> = props => {
  const isMobile = isMobileDevice();
  let scrollContainerRef: HTMLDivElement | undefined;
  const hourItemRefs: HTMLDivElement[] = [];
  const [days, setDays] = createSignal(props.days);

  onMount(() => {
    const activeIndex = props.days.findIndex(day => day.active);
    const activeRef = hourItemRefs[activeIndex];
    if (scrollContainerRef && activeRef) {
      const activeOffset = activeRef.offsetWidth * activeIndex;
      const halfActiveRefWidth = activeRef.offsetWidth / 2;
      const halfScrollContainerWidth = scrollContainerRef.offsetWidth / 2;
      const scrollLeft = activeOffset + halfActiveRefWidth - halfScrollContainerWidth;
      scrollContainerRef.scrollLeft = scrollLeft;
    }
  });

  createEffect((prevDays: Props['days']) => {
    if (objectUpdated(props.days, prevDays)) {
      setDays(props.days);
    }
    return props.days;
  }, props.days);

  return (
    <ScrollContainer
      direction="horizontal"
      class={themeClass(styles.dark, styles.container, { [styles.isMobile]: isMobile })}
      ref={scrollContainerRef}
      thin={false}
    >
      <For each={days()}>
        {(day, i) => (
          <div
            class={cn(styles.item, { [styles.active]: day.active })}
            onClick={props.onSelectHour.bind(null, i(), day.time)}
            ref={hourItemRefs[i()]}
          >
            <StatItem
              type="wind"
              value={day.windDir}
              label={day.time}
              units={`${day.windSpeed} ${getUserPrefUnit('speed')}`}
              animate={false}
            />
          </div>
        )}
      </For>
    </ScrollContainer>
  );
};

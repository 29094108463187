import { Link as RouterLink } from '@solidjs/router';
import { ParentComponent } from 'solid-js';
import { themeClass } from '../ThemeProvider';
import styles from './Link.module.scss';

interface Props {
  href: string;
  onClick?: () => void;
  primary?: boolean;
  class?: string;
}

export const LinkExternal: ParentComponent<Props> = props => {
  return (
    <a
      target="_blank"
      class={themeClass(styles.dark, styles.link, {
        [styles.primary]: props.primary,
        [String(props.class)]: props.class !== undefined,
      })}
      href={props.href}
      onClick={props.onClick}
    >
      {props.children}
    </a>
  );
};

export const Link: ParentComponent<Props> = props => {
  return (
    <RouterLink
      class={themeClass(styles.dark, styles.link, {
        [styles.primary]: props.primary,
        [String(props.class)]: props.class !== undefined,
      })}
      href={props.href}
      onClick={props.onClick}
    >
      {props.children}
    </RouterLink>
  );
};

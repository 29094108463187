import { ParentComponent, mergeProps } from 'solid-js';
import styles from './GradientBackground.module.scss';
import { useTheme } from '../ThemeProvider';
import cn from 'classnames';

interface Props {
  direction?: 'vertical' | 'horizontal' | 'diagonal' | number;
  borderOnly?: boolean;
  startColor?: string;
  endColor?: string;
}

export const GradientBackground: ParentComponent<Props> = passedProps => {
  const [theme] = useTheme();
  const { colors } = theme();

  const props = mergeProps(
    {
      direction: 'diagonal',
      startColor: colors.primary,
      endColor: colors.secondary,
    },
    passedProps,
  );

  const gradient = () => {
    if (typeof props.direction === 'number') {
      return `linear-gradient(${props.direction}deg, ${props.startColor}, ${props.endColor})`;
    }
    switch (props.direction) {
      case 'diagonal':
        return `linear-gradient(135deg, ${props.startColor}, ${props.endColor})`;
      case 'horizontal':
        return `linear-gradient(90deg, ${props.startColor}, ${props.endColor})`;
      default:
        return `linear-gradient(${props.startColor}, ${props.endColor})`;
    }
  };

  return (
    <span
      style={{
        'background-color': props.borderOnly ? undefined : props.startColor,
        'background-image': props.borderOnly ? undefined : gradient(),
        'border-image-source': gradient(),
      }}
      class={cn(styles.container, { [styles.borderOnly]: props.borderOnly })}
    >
      {props.children}
    </span>
  );
};

import { ParentComponent, createEffect, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import styles from './WindDirIcon.module.scss';
import { Icon } from '../Icon';
import { themeClass } from '../ThemeProvider';

interface Props {
  windDir: number;
  type?: 'nautical' | 'plain';
  color?: string;
  class?: string;
  size?: 'medium' | 'large' | 'small';
  animate?: boolean;
}

export const WindDirIcon: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      type: 'plain',
      size: 'medium',
      animate: true,
    },
    passedProps,
  );

  const [direction, setDirection] = createSignal(props.animate ? 180 : props.windDir);
  let delayTimeout: number = 0;

  onMount(() => {
    if (props.animate) {
      delayTimeout = window.setTimeout(() => {
        setDirection(props.windDir);
      }, 1000);
    }
  });

  onCleanup(() => {
    clearTimeout(delayTimeout);
  });

  createEffect(prevWindDir => {
    if (prevWindDir !== props.windDir) {
      setDirection(props.windDir);
    }
    return props.windDir;
  }, props.windDir);

  return (
    <div
      class={themeClass(styles.dark, styles.container, {
        [props.class || '']: props.class ? true : false,
        [styles[props.type]]: props.type ? true : false,
        [styles[props.size]]: props.size ? true : false,
      })}
      style={{ transform: `rotate(${direction()}deg)`, color: props.color }}
    >
      {props.type === 'plain' && <Icon type="IconArrowDown" />}
    </div>
  );
};

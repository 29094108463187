import { Component, createEffect, createSignal, For, mergeProps, onMount } from 'solid-js';
import { capitalizeFirstLetter, niceString, safeString } from '../../utils';
import styles from './Input.module.scss';
import { z } from 'zod';
import { InputValidator, InputValue } from '../../types';
import { themeClass } from '../ThemeProvider';
import { Icons, Icon } from '../Icon';
import { DatePicker, datePickerMode } from '../DatePicker';
import cn from 'classnames';
import { ValidationError } from '../ValidationError';

export interface InputProps {
  name: string;
  label?: string;
  value?: string | number | Date;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'email' | 'url' | 'password' | 'date' | 'time' | 'datetime';
  required?: boolean;
  touched?: boolean;
  theme?: 'light' | 'dark';
  icon?: Icons;
  iconStatus?: 'default' | 'error' | 'info' | 'success' | 'warning' | 'muted';
  iconAction?: () => void;
  iconPosition?: 'left' | 'right';
  passwordOptions?: {
    minLength?: number;
    maxLength?: number;
    enforceMixedCase?: boolean;
    includeNumber?: boolean;
    includeSpecialCharacter?: boolean;
  };
  onChange?: (value: InputValue) => void;
  onMount?: (value: InputValue, validate: InputValidator) => void;
  class?: string;
  highContrast?: boolean;
}

const regexHasNumber = /^(?=.*\d).{0,}$/;
const regexMixedCase = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{0,}$/;
const regexSpecialCharacter = /^(?=.*[^a-zA-Z0-9]).{0,}$/;

const getIcon = (type?: string, icon?: Icons) => {
  if (type === 'date' || type === 'datetime') {
    return 'IconCalendar';
  } else if (type === 'time') {
    return 'IconClock';
  }
  return icon;
};

const getDatePickerMode = (type?: string) => {
  const modes = ['date', 'time', 'datetime'];
  if (type && modes.includes(type)) {
    return type as datePickerMode;
  }
  return undefined;
};

export const Input: Component<InputProps> = passedProps => {
  const defaultProps = {
    value: '',
    type: 'text',
    iconStatus: 'default',
    iconPosition: 'right',
    passwordOptions: {
      minLength: 8,
      maxLength: Infinity,
      includeNumber: true,
      enforceMixedCase: true,
      includeSpecialCharacter: true,
    },
  };

  const props = mergeProps(defaultProps, passedProps);

  const niceName = capitalizeFirstLetter(niceString(props.name));
  const ID = safeString(props.name);
  const [value, setValue] = createSignal(props.value);
  const [valid, setValid] = createSignal(true);
  const [touched, setTouched] = createSignal(props.touched);
  const [errors, setErrors] = createSignal<string[]>([]);
  const [icon, setIcon] = createSignal<Icons | undefined>(getIcon(props.type, props.icon));
  const hasDatePicker = props.type === 'date' || props.type === 'time' || props.type === 'datetime';
  const type = hasDatePicker ? 'text' : props.type;
  let inputRef: HTMLInputElement | undefined;

  const string = z.string().min(1, { message: `${niceName} is required` });
  const number = z.number({
    required_error: `${niceName} is required`,
    invalid_type_error: `${niceName} must be a valid number`,
  });
  const email = z.string().email({ message: `${niceName} must be a valid email address` });
  const url = z.string().url({ message: `${niceName} must be a valid url` });
  const date = z.date({
    required_error: `${niceName} is required`,
    invalid_type_error: `${niceName} must be a valid date`,
  });
  const password = z
    .string()
    .min(props.passwordOptions.minLength || defaultProps.passwordOptions.minLength, {
      message: `${niceName} must include at least ${props.passwordOptions.minLength} character(s)`,
    })
    .max(props.passwordOptions.maxLength || defaultProps.passwordOptions.maxLength, {
      message: `${niceName} must be no more than ${props.passwordOptions.maxLength} character(s) long`,
    });
  const isMixedCase = z
    .string()
    .regex(regexMixedCase, { message: `${niceName} must contain both lowercase and uppercase characters` });
  const hasNumber = z.string().regex(regexHasNumber, { message: `${niceName} must include at least 1 number` });
  const hasSpecialCharacter = z
    .string()
    .regex(regexSpecialCharacter, { message: `${niceName} must include at least 1 special character` });

  onMount(() => {
    const isValid = validate(props.value);
    props.onMount && props.onMount({ name: ID, value: props.value, valid: isValid }, validate);
  });

  createEffect(() => {
    setIcon(getIcon(props.type, props.icon));
  });

  createEffect(prevValue => {
    if (prevValue !== props.value) {
      onInput(props.value instanceof Date ? props.value : String(props.value), false);
    }
  }, props.value);

  const validate = (value: InputValue['value'], skipTouched = false) => {
    let isValid = true;
    let result: any;

    switch (props.type) {
      case 'time':
      case 'date':
      case 'datetime':
        result = date.safeParse(value);
        setErrors(result?.error?.format()?._errors || []);
        isValid = result.success;
        break;
      case 'text':
        result = string.safeParse(value);
        setErrors(result?.error?.format()?._errors || []);
        isValid = result.success;
        break;
      case 'number':
        value = value === '' ? '' : Number(value);
        result = number.safeParse(value);
        setErrors(result?.error?.format()?._errors || []);
        isValid = result.success;
        break;
      case 'email':
        result = email.safeParse(value);
        setErrors(result?.error?.format()?._errors || []);
        isValid = result.success;
        break;
      case 'url':
        result = url.safeParse(value);
        setErrors(result?.error?.format()?._errors || []);
        isValid = result.success;
        break;
      case 'password':
        let combinedErrors: string[] = [];
        let v = [true];

        result = password.safeParse(value);
        v[0] = result.success;
        combinedErrors = [...combinedErrors, ...(result?.error?.format()?._errors || [])];

        const { enforceMixedCase, includeNumber, includeSpecialCharacter } = props.passwordOptions;

        if (enforceMixedCase) {
          result = isMixedCase.safeParse(value);
          v.push(result.success);
          combinedErrors = [...combinedErrors, ...(result?.error?.format()?._errors || [])];
        }

        if (includeNumber) {
          result = hasNumber.safeParse(value);
          v.push(result.success);
          combinedErrors = [...combinedErrors, ...(result?.error?.format()?._errors || [])];
        }

        if (includeSpecialCharacter) {
          result = hasSpecialCharacter.safeParse(value);
          v.push(result.success);
          combinedErrors = [...combinedErrors, ...(result?.error?.format()?._errors || [])];
        }

        isValid = v.find(v => !v) === undefined;
        setErrors(combinedErrors);
        break;
    }

    if ((!props.required && String(value) === '') || (!touched() && !skipTouched)) {
      isValid = true;
      setErrors([]);
    }

    setValid(isValid);
    return isValid;
  };

  const onInput = (val: string | Date, triggerOnChange = true) => {
    const value = val || '';
    setTouched(true);
    if (!(value instanceof Date)) setValue(value);
    const isValid = validate(value);
    props.onChange && triggerOnChange && props.onChange({ name: ID, value, valid: isValid });
  };

  const onIconClick = () => {
    if (inputRef) {
      inputRef.focus();
    }
  };

  const onDatePickerSelect = (date: Date) => {
    onInput(date);
  };

  const renderInput = () => {
    return (
      <>
        <input
          type={type}
          id={ID}
          value={String(value())}
          onInput={(e: any) => onInput(e.target?.value)}
          disabled={props.disabled}
          readOnly={hasDatePicker || props.readOnly}
          placeholder={props.placeholder}
          class={cn({
            [styles.hasIcon]: icon(),
            [styles.inValid]: !valid(),
          })}
          ref={inputRef}
        />
        {icon() && (
          <div class={styles.icon} onClick={props.iconAction || onIconClick}>
            <div class={cn(styles.iconInner, { [styles[props.iconStatus]]: props.iconStatus })}>
              <Icon type={icon() as Icons} size="medium" />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      class={themeClass(props.theme ? styles[props.theme] : styles.dark, styles.container, {
        [styles.disabled]: props.disabled,
        [styles.readOnly]: props.readOnly,
        [styles.iconLeft]: props.iconPosition === 'left',
        [String(props.class)]: props.class ? true : false,
        [styles.highContrast]: props.highContrast,
      })}
    >
      {props.label && (
        <label for={ID}>
          {props.label} {props.required && <sup>*</sup>}
        </label>
      )}
      <div class={styles.inputHolder}>
        {hasDatePicker && (
          <DatePicker
            position="center"
            value={props.value instanceof Date ? props.value : undefined}
            onChange={onDatePickerSelect}
            mode={getDatePickerMode(props.type)}
          >
            {renderInput()}
          </DatePicker>
        )}
        {!hasDatePicker && <>{renderInput()}</>}
      </div>
      {!valid() && <For each={errors()}>{(error: string) => <ValidationError>{error}</ValidationError>}</For>}
    </div>
  );
};

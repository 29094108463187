import { Navigate, Route, Routes, useLocation, useNavigate } from '@solidjs/router';
import cn from 'classnames';
import { Component, Match, Show, Switch, createEffect, createSignal, lazy, onMount } from 'solid-js';
import { checkForAuthToken, getUser, initAuth } from './api';
import styles from './App.module.scss';
import {
  CenterContainer,
  DonateButton,
  Footer,
  GeolocationErrorModal,
  HeaderBar,
  InstallAppButton,
  Link,
  LinkExternal,
  LoadingBar,
  LoadingSpinner,
  NavDrawer,
  NavDrawerHeader,
  NavDrawerItem,
  ScrollContainer,
  StravaButton,
  Toaster,
  isMobileLayout,
} from './components';
import { SETTINGS } from './settings';
import { Tabs, useStore } from './store';
import { isAdmin, isDemo, isEmbeddedRide, isSharedRide, trackPageView } from './utils';

const App: Component = () => {
  const { store, updateStore } = useStore();
  const [navOpen, setNavOpen] = createSignal(false);
  const location = useLocation();
  const [pathName, setPathName] = createSignal(location.pathname);
  const [pathSearch, setPathSearch] = createSignal(location.search);
  const navigate = useNavigate();
  let scrollContainerRef: HTMLDivElement | undefined;

  onMount(async () => {
    updateStore.scrollContainer(scrollContainerRef);

    await checkForAuthToken();

    if (store().stravaToken) {
      getUser(user => {
        // Update the user data
        if (user.data && user.success) {
          updateStore.stravaUserID(String(user.data.id));
          updateStore.stravaUserName(user.data.username);
          updateStore.stravaUserAvatar(user.data.profile_medium);
          if (user.data.headwind?.unitPrefs && user.data.headwind?.commute) {
            updateStore.settings(user.data.headwind);
          } else {
            // If no settings then redirect to the settings page
            navigate('/settings');
          }
        }
      });
    }
  });

  createEffect(() => {
    setPathName(location.pathname);
    setPathSearch(location.search);
    trackPageView(location.pathname, location.search);
  }, location.pathname);

  const onNavOpen = () => {
    setNavOpen(true);
  };

  const onNavClose = () => {
    setNavOpen(false);
  };

  const onChangeTab = (tab: Tabs) => {
    updateStore.activeTab(tab);
    onNavClose();
    trackPageView(pathName(), pathSearch());
  };

  return (
    <>
      {store().loading && <LoadingBar />}
      <Show
        when={!store().requestingStravaToken}
        fallback={
          <CenterContainer>
            <LoadingSpinner text="Connecting to Strava..." />
          </CenterContainer>
        }
      >
        <HeaderBar
          title={store().title}
          showBack={store().showBackButton}
          showMenuIcon={isMobileLayout() && store().stravaToken ? true : false}
          onMenuClick={onNavOpen}
          showAuthButton={isSharedRide() && !store().stravaToken}
          showPoweredBy={isEmbeddedRide() && !store().stravaToken}
        />
        <Switch>
          <Match when={!store().stravaToken}>
            <ScrollContainer thin={false}>
              <Routes>
                <Route path="/" component={lazy(() => import('./pages/login'))} />
                <Route path="/privacy" component={lazy(() => import('./pages/privacy'))} />
                <Route path="/demo" component={lazy(() => import('./pages/demo'))} />
                <Route path="/share/:type/:uid/:id/:time/:meta/:sid?" component={lazy(() => import('./pages/ride'))} />
                <Route path="/embed/:type/:uid/:id/:time/:meta/:sid?" component={lazy(() => import('./pages/ride'))} />
                <Route path="*" element={<Navigate href="/" />} />
              </Routes>
            </ScrollContainer>
          </Match>
          <Match when={store().stravaToken && !store().stravaUserID}>
            <CenterContainer>
              <LoadingSpinner text="Loading Strava user..." />
            </CenterContainer>
          </Match>
          <Match when={store().stravaUserID}>
            <div class={styles.container}>
              <NavDrawer
                open={navOpen()}
                onClose={onNavClose}
                header={
                  <NavDrawerHeader
                    name={store().stravaUserName || 'Anon'}
                    image={store().stravaUserAvatar || undefined}
                  />
                }
                footer={
                  <div class={styles.navFooter}>
                    <Show when={!isDemo()} fallback={<StravaButton full onClick={initAuth} />}>
                      <DonateButton amount={SETTINGS.DONATION_AMOUNT_CENTS} donated={store().settings.donated} full />
                    </Show>
                    <InstallAppButton full />
                    <p class={styles.version}>Version: {APP_VERSION}</p>
                  </div>
                }
                position={isMobileLayout() ? 'right' : 'static'}
              >
                <NavDrawerItem
                  icon="IconBike"
                  href="/"
                  onClick={onChangeTab.bind(null, Tabs.Rides)}
                  active={store().activeTab === Tabs.Rides && pathName() === '/'}
                >
                  Rides
                </NavDrawerItem>
                <NavDrawerItem
                  icon="IconStarFilled"
                  href="/"
                  onClick={onChangeTab.bind(null, Tabs.Starred)}
                  active={store().activeTab === Tabs.Starred && pathName() === '/'}
                >
                  Starred
                </NavDrawerItem>
                <NavDrawerItem
                  icon="IconRoute"
                  href="/"
                  onClick={onChangeTab.bind(null, Tabs.Routes)}
                  active={store().activeTab === Tabs.Routes && pathName() === '/'}
                >
                  Routes
                </NavDrawerItem>
                <NavDrawerItem icon="IconSunFilled" href="/forecast" onClick={onNavClose}>
                  7 day forecast
                </NavDrawerItem>
                <NavDrawerItem icon="IconCloudRain" href="/rain-radar" onClick={onNavClose}>
                  Rain Radar
                </NavDrawerItem>
                <NavDrawerItem icon="IconSettingsFilled" href="/settings" onClick={onNavClose}>
                  Settings
                </NavDrawerItem>
                <NavDrawerItem icon="IconLogout" href="/logout" onClick={onNavClose}>
                  Log out
                </NavDrawerItem>
                {isAdmin() && (
                  <NavDrawerItem icon="IconDashboard" href="/admin-dashboard" onClick={onNavClose}>
                    Admin Dashboard
                  </NavDrawerItem>
                )}
              </NavDrawer>
              <ScrollContainer thin={false} ref={scrollContainerRef} noOverscroll>
                <Routes>
                  <Route path="/" component={lazy(() => import('./pages/dashboard'))} />
                  <Route path="/forecast/:type?/:id?" component={lazy(() => import('./pages/forecast'))} />
                  <Route path="/rain-radar" component={lazy(() => import('./pages/rainRadar'))} />
                  <Route path="/settings" component={lazy(() => import('./pages/settings'))} />
                  <Route path="/privacy" component={lazy(() => import('./pages/privacy'))} />
                  <Route path="/logout" component={lazy(() => import('./pages/logout'))} />
                  <Route path="/:type/:id/:time" component={lazy(() => import('./pages/ride'))} />
                  <Route
                    path="/share/:type/:uid/:id/:time/:meta/:sid?"
                    component={lazy(() => import('./pages/ride'))}
                  />
                  <Route
                    path="/embed/:type/:uid/:id/:time/:meta/:sid?"
                    component={lazy(() => import('./pages/ride'))}
                  />
                  <Route path="/demo" component={lazy(() => import('./pages/demo'))} />
                  {isAdmin() && (
                    <Route path="/admin-dashboard" component={lazy(() => import('./pages/adminDashboard'))} />
                  )}
                  <Route path="*" component={lazy(() => import('./pages/notFound'))} />
                </Routes>
              </ScrollContainer>
            </div>
          </Match>
        </Switch>
        <Footer>
          &copy; Headwind | <Link href="/privacy">Privacy</Link>
          <Show when={store().stravaUserID}>
            <>
              {' '}
              | <LinkExternal href="mailto:contact@headwind.app">Contact</LinkExternal>
            </>
          </Show>
        </Footer>
        <Toaster
          position="top"
          class={cn({
            [styles.loggedInToast]: store().stravaToken,
          })}
        />
        <GeolocationErrorModal />
      </Show>
    </>
  );
};

export default App;

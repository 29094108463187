import { ParentComponent } from 'solid-js';
import { SETTINGS } from '../../settings';
import { getWindHardnessColor } from '../../utils';
import { getHardnessColor } from '../../utils/shared';
import { isMobileLayout, themeClass } from '../ThemeProvider';
import { WindDirIcon } from '../WindDirIcon';
import styles from './SummaryItem.module.scss';

export interface SummaryItemProps {
  day: string;
  windDir: number;
  windSpeed: number;
  hardness?: number;
}

export const SummaryItem: ParentComponent<SummaryItemProps> = props => {
  const getColor = () => {
    if (props.hardness !== undefined) {
      return getHardnessColor(props.hardness, 0, SETTINGS.HARDNESS_OUT_OF);
    }
    return getWindHardnessColor(props.windSpeed);
  };

  return (
    <div class={themeClass(styles.dark, styles.container)} style={{ 'border-top-color': getColor() }}>
      <span class={styles.day}>{props.day}</span>
      <div class={styles.wind}>
        <WindDirIcon windDir={props.windDir} size={isMobileLayout() ? 'medium' : 'large'} color={getColor()} />
      </div>
      <span class={styles.hardness}>
        <span class={styles.label}>
          {props.hardness && <>Difficulty</>} {!props.hardness && <>Wind Speed</>}
        </span>
        <span class={styles.value}>{props.hardness || props.windSpeed}</span>
      </span>
    </div>
  );
};

import { ParentComponent, mergeProps } from 'solid-js';
import { animate } from 'motion';
import { InView } from '../InView';
import { easing } from 'ts-easing';

interface Props {
  duration?: number;
  easing?: any; // TODO: type me
  y?: number;
  delay?: number;
  container?: HTMLElement;
}

export const FadeInOnSee: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      duration: 0.5,
      easing: easing.outCubic,
      y: 200,
      delay: 0,
    },
    passedProps,
  );
  let ref: HTMLDivElement | undefined;

  const fadeIn = () => {
    if (ref) {
      animate(
        ref,
        { y: [props.y, 0], opacity: 1 },
        {
          delay: props.delay,
          duration: props.duration,
          easing: props.easing,
        },
      );
    }
  };

  return (
    <InView onSeen={fadeIn} container={props.container}>
      <div ref={ref} style={{ opacity: 0 }}>
        {props.children}
      </div>
    </InView>
  );
};

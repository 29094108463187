// TODO: type the storage

// Items we hardly ever want to clear from storage
export const persistentStorageKeys = ['version', 'theme', 'remember_me'];

export const storage = {
  remember: (remember: boolean) => {
    localStorage.clear();
    sessionStorage.clear();
    if (remember) {
      localStorage.setItem('remember_me', 'true');
    } else {
      localStorage.removeItem('remember_me');
    }
  },
  shouldRemember: () => {
    return localStorage.getItem('remember_me') ? true : false;
  },
  setItem: (key: string, item: string) => {
    const remember = localStorage.getItem('remember_me');
    if (remember) {
      localStorage.setItem(key, item);
    } else {
      sessionStorage.setItem(key, item);
    }
  },
  getItem: (key: string) => {
    const remember = localStorage.getItem('remember_me');
    if (remember) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  },
  removeItem: (key: string) => {
    const remember = localStorage.getItem('remember_me');
    if (remember) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  },
  clear: (excludeKeys: string[] = []) => {
    return new Promise(resolve => {
      const exclude = [...persistentStorageKeys, ...excludeKeys];
      const items = [localStorage, sessionStorage];
      items.forEach((storage, i) => {
        Object.keys(storage).forEach(key => !exclude.includes(key) && storage.removeItem(key));
        if (i === items.length - 1) {
          resolve(true);
        }
      });
    });
  },
  clearAll: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};

import { ParentComponent } from 'solid-js';
import styles from './NavDrawerHeader.module.scss';
import { Avatar } from '../Avatar';
import { EllipsisText } from '../EllipsisText';

interface Props {
  name: string;
  image?: string;
}

export const NavDrawerHeader: ParentComponent<Props> = props => {
  return (
    <div class={styles.container}>
      <Avatar name={props.name} image={props.image} />
      <EllipsisText class={styles.name}>{props.name}</EllipsisText>
    </div>
  );
};

import cn from 'classnames';
import { animate } from 'motion';
import { For, ParentComponent, createSignal, mergeProps, onCleanup } from 'solid-js';
import { sendErrorToSentry } from '../../utils';
import { Message, MessageProps } from '../Message';
import styles from './Toaster.module.scss';

interface Props {
  position?: 'top' | 'bottom';
  class?: string;
}

interface ToastProps {
  message: string;
  type: MessageProps['type'];
}

const [messages, setMessages] = createSignal<ToastProps[]>([]);
let refs: HTMLDivElement[] = [];
let holderRef: HTMLDivElement | undefined;
let animateOutTimeout: number = 0;
let removeTimeout: number = 0;
const animSpeed = 300;

export const showToast = (message: string, type: MessageProps['type'], delay = 0, showFor = 6000) => {
  setTimeout(() => {
    // If there is already a message with the same content, don't show it again
    const messageExists = messages().findIndex(m => m.message === message);
    if (messageExists !== -1) {
      return;
    }

    setMessages([{ message, type }, ...messages()]);

    const inRef = refs[0];
    const outRef = refs.shift();

    const getChildrenHeight = () => {
      let childrenHeight = 0;
      if (holderRef) {
        for (let i = 0; i <= holderRef.children.length; i++) {
          const child = holderRef.children[i];
          if (child) {
            childrenHeight += child.clientHeight;
          }
        }
      }
      return childrenHeight;
    };

    const getVisibleChildren = () => {
      const visible = [];
      if (holderRef) {
        for (let i = 0; i <= holderRef.children.length; i++) {
          const child: Element | null = holderRef.children[i];
          if (child && !child.classList.contains(styles.seen)) {
            visible.push(child);
          }
        }
      }
      return visible;
    };

    if (inRef && holderRef) {
      const height = getChildrenHeight();
      animate(holderRef, { height: `${height}px` }, { duration: animSpeed / 1000 });
    }

    if (inRef) {
      animate(
        inRef,
        { opacity: 1, top: 0 },
        {
          duration: animSpeed / 1000,
        },
      );
    }

    animateOutTimeout = window.setTimeout(() => {
      dismissToast(outRef || null);
    }, showFor + animSpeed);

    removeTimeout = window.setTimeout(() => {
      if (holderRef) {
        const visibleChildren = getVisibleChildren();
        if (!visibleChildren.length) {
          setMessages([]);
          animate(holderRef, { height: '0px' }, { duration: animSpeed / 1000 });
        }
      }
    }, showFor + animSpeed * 3);

    // Log to sentry if it's an error
    if (type === 'error') {
      sendErrorToSentry(message);
    }
  }, delay);
};

const dismissToast = (el: HTMLDivElement | EventTarget | null) => {
  if (el) {
    const toast = el as HTMLDivElement;
    const isBottom = toast.parentElement?.classList.contains(styles.bottom);
    toast.classList.add(styles.seen);
    animate(
      toast,
      { opacity: 0, top: isBottom ? '50px' : '-50px' },
      {
        duration: animSpeed / 1000,
      },
    );
  }
};

export const Toaster: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      position: 'top',
    },
    passedProps,
  );

  onCleanup(() => {
    clearTimeout(animateOutTimeout);
    clearTimeout(removeTimeout);
  });

  return (
    <div
      ref={holderRef}
      class={cn(styles.toaster, {
        [styles.bottom]: props.position === 'bottom',
        [String(props.class)]: props.class,
      })}
    >
      <For each={messages()}>
        {(toast, i) => (
          <div ref={refs[i()]} class={styles.toast} onTouchMove={(e: TouchEvent) => dismissToast(e.currentTarget)}>
            <Message type={toast.type}>{toast.message}</Message>
          </div>
        )}
      </For>
    </div>
  );
};

import { ParentComponent } from 'solid-js';
import styles from './EllipsisText.module.scss';
import cn from 'classnames';

interface Props {
  class?: string;
  heading?: boolean;
}

export const EllipsisText: ParentComponent<Props> = props => {
  if (props.heading) {
    return <h1 class={cn(styles.text, { [String(props.class)]: props.class !== undefined })}>{props.children}</h1>;
  }
  return <span class={cn(styles.text, { [String(props.class)]: props.class !== undefined })}>{props.children}</span>;
};

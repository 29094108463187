import { ParentComponent, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import headwindLogo from '../../assets/images/headwind-logo.svg';
import { isInstalled, isIosDevice, trackEvent } from '../../utils';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { Modal, ModalProps } from '../Modal/Modal';
import { themeClass } from '../ThemeProvider';
import styles from './ModalIosAddToHome.module.scss';

interface Props extends Omit<ModalProps, 'open' | 'onClose' | 'image' | 'footer'> {
  showAfterVisits: number;
  delayBeforeOpen?: number;
  overrideChecks?: boolean;
}

export const ModalIosAddToHome: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      delayBeforeOpen: 4000,
    },
    passedProps,
  );

  const isIos = isIosDevice();
  const installed = isInstalled();
  const loggedInstall = localStorage.getItem('ios_installed');
  const [open, setOpen] = createSignal(false);
  const id = 'IOSInstallModal';
  let timeoutID: number = 0;

  onMount(() => {
    const timesSeenString = localStorage.getItem(id);

    if (timesSeenString === 'true') {
      if (isIos && installed && !loggedInstall) {
        localStorage.setItem('ios_installed', 'true');
        trackEvent('IOS Install Prompt', 'installed');
      }
      return;
    } else if (timesSeenString && timesSeenString !== 'true') {
      const timesSeen = Number(timesSeenString) + 1;

      if (timesSeen >= props.showAfterVisits) {
        timeoutID = window.setTimeout(() => {
          setOpen(true);
          trackEvent('IOS Install Prompt', 'seen');
        }, props.delayBeforeOpen);
      }
      localStorage.setItem(id, String(timesSeen));
    } else {
      localStorage.setItem(id, String(1));
    }
  });

  onCleanup(() => {
    clearTimeout(timeoutID);
  });

  const onClose = () => {
    localStorage.setItem(id, 'true');
    setOpen(false);
    trackEvent('IOS Install Prompt', 'dismiss');
  };

  if ((isIos && !installed) || props.overrideChecks) {
    return <IosAddToHomeDialog {...props} open={open()} onClose={onClose} />;
  }

  return null;
};

interface IosAddToHomeDialogProps extends Omit<ModalProps, 'image' | 'footer'> {}

export const IosAddToHomeDialog: ParentComponent<IosAddToHomeDialogProps> = props => {
  return (
    <Modal
      {...props}
      footer={
        <div class={themeClass(styles.dark, styles.footer)}>
          Just tap{' '}
          <div class={styles.icon}>
            <Icon type="iosAddToHome" size="medium" />
          </div>{' '}
          then 'Add to home screen'
        </div>
      }
    >
      <div class={themeClass(styles.dark, styles.content)}>
        <div class={styles.header}>
          <div class={styles.logoHolder}>
            <div class={styles.blankLogo}></div>
            <div class={styles.blankLogo}></div>
            <div class={styles.blankLogo}></div>
            <div class={styles.logo}>
              <Image src={headwindLogo} alt="Headwind" transparentBg width={40} class={styles.logo} />
            </div>
            <div class={styles.blankLogo}></div>
            <div class={styles.blankLogo}></div>
            <div class={styles.blankLogo}></div>
          </div>
        </div>
        <div class={styles.children}>{props.children}</div>
      </div>
    </Modal>
  );
};

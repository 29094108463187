import { SETTINGS } from '../settings';
import { Tabs, useStore } from '../store';
import { isDevelopEnvironment, isDevMode, isInstalled, isLiveEnvironment } from './utils';

let GA_ID: string = SETTINGS.GOOGLE_ANALYTICS_LOCAL;
if (isDevelopEnvironment()) {
  GA_ID = SETTINGS.GOOGLE_ANALYTICS_DEVELOP;
} else if (isLiveEnvironment()) {
  GA_ID = SETTINGS.GOOGLE_ANALYTICS_LIVE;
}

const gtag: Gtag.Gtag = function () {
  if (window.dataLayer) {
    window.dataLayer.push(arguments);
  }
};

export const initAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
  document.head.appendChild(script);
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', GA_ID, {
      send_page_view: false,
    });
    if (isDevMode()) {
      console.log('GA', 'initialized');
    }
  };
};

// NOTE: Need to debounce the page view tracking as the use of activeTab in the store
// causes multiple calls of this function
let trackPageViewTimeoutID = 0;
export const trackPageView = (pathName: string, pathSearch?: string) => {
  clearTimeout(trackPageViewTimeoutID);
  const { store } = useStore();
  const activeTab = store().activeTab;
  // NOTE: Have to build the url from the solid useLocation hook rather than use window.location.href as
  // the window object contains the previous url when the effect is run
  trackPageViewTimeoutID = window.setTimeout(() => {
    let tabPath = '';
    if (pathName === '/') {
      switch (activeTab) {
        case Tabs.Starred:
          tabPath = 'starred';
          break;
        case Tabs.Routes:
          tabPath = 'routes';
          break;
        case Tabs.Rides:
          tabPath = 'rides';
          break;
      }
    }
    let url = `${window.location.protocol}//${window.location.host}${pathName}${tabPath}${pathSearch}`;
    if (isInstalled()) {
      url = url.split('?')[0] + '/?installed=1';
    }
    gtag('config', GA_ID, {
      page_title: document.title,
      page_location: url,
    });
    if (isDevMode()) {
      console.log('GA', 'Track page', url);
    }
  }, 2000);
};

export const trackEvent = (action: string, category: string, label?: string) => {
  gtag('event', action, {
    event_category: category,
    event_label: label,
  });
  if (isDevMode()) {
    console.log('GA', 'Track event', { action, category, label });
  }
};

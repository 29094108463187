import { ParentComponent } from 'solid-js';
import styles from './Footer.module.scss';
import { themeClass } from '../ThemeProvider';
import { Image } from '../Image';
import poweredByStrava from './images/powered-by-strava.svg';

export const Footer: ParentComponent = props => {
  return (
    <div class={themeClass(styles.dark, styles.footer)}>
      <div class={styles.left}>{props.children}</div>
      <div class={styles.right}>
        <Image
          src={poweredByStrava}
          alt="Powered by Strava"
          transparentBg
          hideLoading
          lazyload={false}
          objectFit="contain"
        />
      </div>
    </div>
  );
};

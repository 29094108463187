import { apiRequest } from '../api';
import { PaymentApiResponse } from '../types';

interface Props {
  email?: string;
}

export const getPaymentIntent = (props: Props, callback: (resp: PaymentApiResponse) => void) => {
  let path = `/payments/get/donate`;
  if (props.email) {
    path = `${path}/${props.email}`;
  }
  apiRequest(path, callback, true);
};

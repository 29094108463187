import { SETTINGS } from '../settings';
import { Currency, CurrencyApiResponse, IPGeolocationApiResponse } from '../types';
import { storage } from '../utils';
import { apiRequest } from './api';

const defaultCurrency: Currency = {
  code: 'AUD',
  name: 'Australian Dollar',
  symbol: 'A$',
  default: true,
};

export const getExchangeRates = (callback: (resp: CurrencyApiResponse) => void) => {
  return apiRequest('/currency', callback, true);
};

export const getCurrency = async (callback: (currency: Currency) => void) => {
  // Want this to fail silently and default to AUD in case ad
  // blockers block ip location service
  const error = () => {
    storage.setItem('currency', JSON.stringify(defaultCurrency));
    callback(defaultCurrency);
  };

  const currencyData = storage.getItem('currency');
  let currency: Currency = defaultCurrency;
  if (currencyData) {
    currency = JSON.parse(currencyData);
  }

  if (!currency.default) {
    callback(currency);
  } else {
    try {
      const resp = await fetch(SETTINGS.IP_GEOLOCATION);
      if (resp.ok) {
        const json: IPGeolocationApiResponse = await resp.json();
        if (json.currency) {
          storage.setItem('currency', JSON.stringify(json.currency));
          callback(json.currency);
        } else {
          error();
        }
      } else {
        error();
      }
    } catch (e) {
      error();
    }
  }
};

export const getPriceInLocalCurrency = (priceInAUDCents: number): Promise<string> => {
  return new Promise(resolve => {
    getExchangeRates(exchangeRates => {
      getCurrency(currency => {
        let amount = priceInAUDCents;
        let symbol = defaultCurrency.symbol;

        if (exchangeRates.data && exchangeRates.success) {
          const rates = exchangeRates.data.rates;
          const AUDRate = rates['AUD'];
          const rate = rates[currency.code];

          if (AUDRate !== undefined && rate !== undefined) {
            const AUDtoEuro = Number(priceInAUDCents / 100) / AUDRate;
            amount = AUDtoEuro * rate;
            symbol = currency.symbol;
          }
        }

        const stringAmount = `${symbol}${amount.toFixed(2)}`;
        resolve(stringAmount);
      });
    });
  });
};

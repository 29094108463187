import { ParentComponent } from 'solid-js';
import { GradientBackground } from '../GradientBackground';
import { HoverBurst } from '../HoverBurst';
import { Icons, Icon } from '../Icon';
import styles from './IconButton.module.scss';
import { HoverPulse } from '../HoverPulse';
import cn from 'classnames';

interface Props {
  icon?: Icons;
  onClick?: () => void;
  class?: string;
  dark?: boolean;
  burstColor?: string;
  burstSize?: 'small' | 'large';
}

const IconButtonBackground: ParentComponent<Props> = props => {
  if (!props.dark) {
    return (
      <GradientBackground>
        <HoverBurst theme="light">{props.children}</HoverBurst>
      </GradientBackground>
    );
  } else {
    return props.children;
  }
};

export const IconButton: ParentComponent<Props> = props => {
  return (
    <HoverPulse color={props.burstColor} size={props.burstSize}>
      <button
        onClick={props.onClick}
        class={cn(styles.button, props.class, {
          [styles.dark]: props.dark,
        })}
      >
        <IconButtonBackground dark={props.dark}>
          <span class={styles.content}>
            {props.icon && (
              <span class={styles.icon}>
                <Icon type={props.icon} size="medium" />
              </span>
            )}
            {!props.icon && props.children}
          </span>
        </IconButtonBackground>
      </button>
    </HoverPulse>
  );
};

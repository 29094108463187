import { Component, createEffect, createSignal } from 'solid-js';
import styles from './Star.module.scss';
import { themeClass } from '../ThemeProvider';
import { Icon } from '../Icon';

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const Star: Component<Props> = props => {
  const [checked, setChecked] = createSignal<boolean>(props.checked ? true : false);

  createEffect(prevChecked => {
    if (prevChecked !== props.checked) {
      setChecked(props.checked ? true : false);
    }
    return props.checked;
  }, props.checked);

  const onClick = () => {
    setChecked(!checked());
    props.onChange && props.onChange(checked());
  };

  return (
    <div
      onClick={props.disabled ? undefined : onClick}
      class={themeClass(styles.dark, styles.star, {
        [styles.checked]: checked(),
        [styles.disabled]: props.disabled,
      })}
    >
      <Icon type="IconStarFilled" size="large" />
    </div>
  );
};

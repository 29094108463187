import { ParentComponent } from 'solid-js';
import styles from './HoverBurst.module.scss';
import { Theme, themeClass } from '../ThemeProvider';

interface Props {
  theme?: Theme['mode'];
  class?: string;
  onClick?: () => void;
}

export const HoverBurst: ParentComponent<Props> = props => {
  return (
    <span
      onClick={props.onClick}
      class={themeClass(styles.dark, styles.container, {
        [styles[String(props.theme)]]: props.theme ? true : false,
        [String(props.class)]: props.class ? true : false,
      })}
    >
      <span>{props.children}</span>
    </span>
  );
};

import { ParentComponent } from 'solid-js';
import styles from './HoverPulse.module.scss';
import { themeClass } from '../ThemeProvider';

interface Props {
  color?: string;
  size?: 'small' | 'large';
  class?: string;
  active?: boolean;
  fast?: boolean;
}

export const HoverPulse: ParentComponent<Props> = props => {
  return (
    <span
      class={themeClass(styles.dark, styles.container, {
        [styles.large]: props.size === 'large',
        [styles.active]: props.active,
        [styles.fast]: props.fast,
        [props.class || '']: props.class ? true : false,
        [styles.customColor]: props.color !== undefined,
      })}
      style={{ color: props.color ? props.color : undefined }}
    >
      <span>{props.children}</span>
    </span>
  );
};

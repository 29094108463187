import cn from 'classnames';
import { animate, spring, stagger } from 'motion';
import { ParentComponent, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { useStore } from '../../store';
import { Hamburger } from '../Hamburger';
import { Icon } from '../Icon';
import { Icons } from '../Icon/Icon.types';
import { IconButton } from '../IconButton';
import styles from './ActionButton.module.scss';

interface Item {
  label: string;
  action: () => void;
  icon?: Icons;
  element?: HTMLDivElement;
}

export type ActionButtonItems = Item[];

interface Props {
  items: ActionButtonItems;
  class?: string;
  buttonOffset?: number;
}

export const ActionButton: ParentComponent<Props> = props => {
  const getScrollTop = () => {
    return store().scrollContainer?.scrollTop || window.scrollY;
  };

  const { store } = useStore();
  const container = store().scrollContainer || window;
  const closeAfterScrolled = 10;
  let itemRef: HTMLUListElement | undefined;
  let itemRefs: HTMLLIElement[] = [];
  let lastScrollTop = getScrollTop();
  const [open, setOpen] = createSignal(false);

  onMount(() => {
    container.addEventListener('scroll', onScroll);
    hide();
  });

  onCleanup(() => {
    container.removeEventListener('scroll', onScroll);
  });

  const onScroll = () => {
    const scrollTop = getScrollTop();
    const scrolled = Math.abs(scrollTop - lastScrollTop);
    if (scrolled > closeAfterScrolled && open()) {
      setOpen(false);
    }
    lastScrollTop = scrollTop;
  };

  const onClick = () => {
    setOpen(!open());
  };

  createEffect(() => {
    !open() ? hide() : show();
  });

  const show = () => {
    if (itemRef) {
      animate(itemRef, { y: ['100%', '0%'], opacity: 1 }, { duration: 0.02 });
    }
    if (itemRefs.length) {
      animate(itemRefs, { y: [100, 0], opacity: 1 }, { duration: 0, delay: stagger(0.05) as any, easing: spring() });
    }
  };

  const hide = () => {
    if (itemRef) {
      animate(itemRef, { y: '100%', opacity: 0 }, { duration: 0.1 });
    }
    if (itemRefs.length) {
      animate(itemRefs, { y: '100%', opacity: 0 }, { duration: 0.1, delay: 0 });
    }
  };

  const onItemClick = (item: Item) => {
    setOpen(false);
    item.action();
  };

  return (
    <div
      class={cn(styles.container, props.class, {
        [styles.open]: open(),
      })}
    >
      <div
        class={styles.innerContainer}
        style={{
          bottom: props.buttonOffset ? `${props.buttonOffset}px` : undefined,
        }}
      >
        <ul class={styles.items} ref={itemRef}>
          {props.items.map((item, i) => (
            <li class={styles.item} onClick={onItemClick.bind(null, item)} ref={itemRefs[i]}>
              <div class={styles.label}>
                <span>{item.label}</span>
              </div>
              <div class={styles.icon}>
                {item.element && <>{item.element}</>}
                {!item.element && <Icon type={item.icon || 'IconClick'} />}
              </div>
            </li>
          ))}
        </ul>
        <IconButton onClick={onClick} class={styles.button}>
          <Hamburger open={open()} />
        </IconButton>
      </div>
    </div>
  );
};

import clearDayLight from './weather-icons-light/clear-day-light.svg';
import clearNightLight from './weather-icons-light/clear-night-light.svg';
import cloud1DayLight from './weather-icons-light/cloud-1-day-light.svg';
import cloud1NightLight from './weather-icons-light/cloud-1-night-light.svg';
import cloud2DayLight from './weather-icons-light/cloud-2-day-light.svg';
import cloud2NightLight from './weather-icons-light/cloud-2-night-light.svg';
import cloud3DayLight from './weather-icons-light/cloud-3-day-light.svg';
import cloud3NightLight from './weather-icons-light/cloud-3-night-light.svg';
import cloud4DayLight from './weather-icons-light/cloud-4-day-light.svg';
import cloud4NightLight from './weather-icons-light/cloud-4-night-light.svg';
import rain1DayLight from './weather-icons-light/rain-1-day-light.svg';
import rain1NightLight from './weather-icons-light/rain-1-night-light.svg';
import rain2DayLight from './weather-icons-light/rain-2-day-light.svg';
import rain2NightLight from './weather-icons-light/rain-2-night-light.svg';
import rain3DayLight from './weather-icons-light/rain-3-day-light.svg';
import rain3NightLight from './weather-icons-light/rain-3-night-light.svg';
import rain4DayLight from './weather-icons-light/rain-4-day-light.svg';
import rain4NightLight from './weather-icons-light/rain-4-night-light.svg';
import snow1DayLight from './weather-icons-light/snow-1-day-light.svg';
import snow1NightLight from './weather-icons-light/snow-1-night-light.svg';
import snow2DayLight from './weather-icons-light/snow-2-day-light.svg';
import snow2NightLight from './weather-icons-light/snow-2-night-light.svg';
import snow3DayLight from './weather-icons-light/snow-3-day-light.svg';
import snow3NightLight from './weather-icons-light/snow-3-night-light.svg';
import snow4DayLight from './weather-icons-light/snow-4-day-light.svg';
import snow4NightLight from './weather-icons-light/snow-4-night-light.svg';
import stormLight from './weather-icons-light/storm-light.svg';

import clearDayDark from './weather-icons-dark/clear-day-dark.svg';
import clearNightDark from './weather-icons-dark/clear-night-dark.svg';
import cloud1DayDark from './weather-icons-dark/cloud-1-day-dark.svg';
import cloud1NightDark from './weather-icons-dark/cloud-1-night-dark.svg';
import cloud2DayDark from './weather-icons-dark/cloud-2-day-dark.svg';
import cloud2NightDark from './weather-icons-dark/cloud-2-night-dark.svg';
import cloud3DayDark from './weather-icons-dark/cloud-3-day-dark.svg';
import cloud3NightDark from './weather-icons-dark/cloud-3-night-dark.svg';
import cloud4DayDark from './weather-icons-dark/cloud-4-day-dark.svg';
import cloud4NightDark from './weather-icons-dark/cloud-4-night-dark.svg';
import rain1DayDark from './weather-icons-dark/rain-1-day-dark.svg';
import rain1NightDark from './weather-icons-dark/rain-1-night-dark.svg';
import rain2DayDark from './weather-icons-dark/rain-2-day-dark.svg';
import rain2NightDark from './weather-icons-dark/rain-2-night-dark.svg';
import rain3DayDark from './weather-icons-dark/rain-3-day-dark.svg';
import rain3NightDark from './weather-icons-dark/rain-3-night-dark.svg';
import rain4DayDark from './weather-icons-dark/rain-4-day-dark.svg';
import rain4NightDark from './weather-icons-dark/rain-4-night-dark.svg';
import snow1DayDark from './weather-icons-dark/snow-1-day-dark.svg';
import snow1NightDark from './weather-icons-dark/snow-1-night-dark.svg';
import snow2DayDark from './weather-icons-dark/snow-2-day-dark.svg';
import snow2NightDark from './weather-icons-dark/snow-2-night-dark.svg';
import snow3DayDark from './weather-icons-dark/snow-3-day-dark.svg';
import snow3NightDark from './weather-icons-dark/snow-3-night-dark.svg';
import snow4DayDark from './weather-icons-dark/snow-4-day-dark.svg';
import snow4NightDark from './weather-icons-dark/snow-4-night-dark.svg';
import stormDark from './weather-icons-dark/storm-dark.svg';

export default {
  'clear-day-light': clearDayLight,
  'clear-night-light': clearNightLight,
  'cloud-1-day-light': cloud1DayLight,
  'cloud-1-night-light': cloud1NightLight,
  'cloud-2-day-light': cloud2DayLight,
  'cloud-2-night-light': cloud2NightLight,
  'cloud-3-day-light': cloud3DayLight,
  'cloud-3-night-light': cloud3NightLight,
  'cloud-4-day-light': cloud4DayLight,
  'cloud-4-night-light': cloud4NightLight,
  'rain-1-day-light': rain1DayLight,
  'rain-1-night-light': rain1NightLight,
  'rain-2-day-light': rain2DayLight,
  'rain-2-night-light': rain2NightLight,
  'rain-3-day-light': rain3DayLight,
  'rain-3-night-light': rain3NightLight,
  'rain-4-day-light': rain4DayLight,
  'rain-4-night-light': rain4NightLight,
  'snow-1-day-light': snow1DayLight,
  'snow-1-night-light': snow1NightLight,
  'snow-2-day-light': snow2DayLight,
  'snow-2-night-light': snow2NightLight,
  'snow-3-day-light': snow3DayLight,
  'snow-3-night-light': snow3NightLight,
  'snow-4-day-light': snow4DayLight,
  'snow-4-night-light': snow4NightLight,
  'storm-light': stormLight,
  'clear-day-dark': clearDayDark,
  'clear-night-dark': clearNightDark,
  'cloud-1-day-dark': cloud1DayDark,
  'cloud-1-night-dark': cloud1NightDark,
  'cloud-2-day-dark': cloud2DayDark,
  'cloud-2-night-dark': cloud2NightDark,
  'cloud-3-day-dark': cloud3DayDark,
  'cloud-3-night-dark': cloud3NightDark,
  'cloud-4-day-dark': cloud4DayDark,
  'cloud-4-night-dark': cloud4NightDark,
  'rain-1-day-dark': rain1DayDark,
  'rain-1-night-dark': rain1NightDark,
  'rain-2-day-dark': rain2DayDark,
  'rain-2-night-dark': rain2NightDark,
  'rain-3-day-dark': rain3DayDark,
  'rain-3-night-dark': rain3NightDark,
  'rain-4-day-dark': rain4DayDark,
  'rain-4-night-dark': rain4NightDark,
  'snow-1-day-dark': snow1DayDark,
  'snow-1-night-dark': snow1NightDark,
  'snow-2-day-dark': snow2DayDark,
  'snow-2-night-dark': snow2NightDark,
  'snow-3-day-dark': snow3DayDark,
  'snow-3-night-dark': snow3NightDark,
  'snow-4-day-dark': snow4DayDark,
  'snow-4-night-dark': snow4NightDark,
  'storm-dark': stormDark,
};

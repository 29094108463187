import { Component, Match, Show, Switch } from 'solid-js';
import { useStore } from '../../store';
import { getBrowser, isAndroidDevice, isIosDevice, isMacDevice, isSafari, isWindowsDevice } from '../../utils';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { ShareInput } from '../ShareInput';
import { showToast } from '../Toaster';
import styles from './GeolocationErrorModal.module.scss';

interface Props {
  open?: boolean;
}

export const GeolocationErrorModal: Component<Props> = props => {
  const { store, updateStore } = useStore();

  const closeAndReload = () => {
    onClose();
    window.location.reload();
  };

  const onClose = () => {
    updateStore.osGeolocationError(false);
    updateStore.browserGeolocationError(false);
  };

  const getBrowserSettingsUrl = () => {
    const settingsUrl = `${getBrowser()}://settings/content/siteDetails?site=${encodeURIComponent(
      window.location.origin,
    )}`;
    return settingsUrl;
  };

  const renderOSFooterInstructions = () => {
    return (
      <Switch
        fallback={
          <Button full onClick={closeAndReload}>
            Done
          </Button>
        }
      >
        <Match when={isAndroidDevice()}>
          <Button full onClick={() => window.open('https://support.google.com/accounts/answer/6179507?hl=en')}>
            Enable location on Android
          </Button>
        </Match>
        <Match when={isIosDevice()}>
          <Button full onClick={() => window.open('https://support.apple.com/en-au/102515')}>
            Enable location on IOS
          </Button>
        </Match>
        <Match when={isMacDevice()}>
          <Button full onClick={() => window.open('https://support.apple.com/en-au/guide/mac-help/mh35873/mac')}>
            Enable location on a Mac
          </Button>
        </Match>
        <Match when={isWindowsDevice()}>
          <Button
            full
            onClick={() =>
              window.open(
                'https://support.microsoft.com/en-au/windows/windows-location-service-and-privacy-3a8eee0a-5b0b-dc07-eede-2a5ca1c49088',
              )
            }
          >
            Enable location on Windows
          </Button>
        </Match>
      </Switch>
    );
  };

  const onCopyBrowserSettingsUrl = () => {
    showToast('Settings url successfully copied to clipboard', 'success');
  };

  const renderBrowserFooterInstructions = () => {
    return (
      <Show
        when={isSafari()}
        fallback={
          <ShareInput url={getBrowserSettingsUrl()} label="" onlyClipboard full onShare={onCopyBrowserSettingsUrl} />
        }
      >
        <Show
          when={isMacDevice()}
          fallback={
            <div class={styles.footerContent}>
              <p>
                Tap the{' '}
                <span class={styles.icon}>
                  <small>A</small>A
                </span>{' '}
                icon next to the url in the address bar and then choose 'website settings' to enable location services.
              </p>
            </div>
          }
        >
          <Button full onClick={() => window.open('https://support.apple.com/en-au/guide/safari/ibrwe2159f50/mac')}>
            Enable location on a Mac
          </Button>
        </Show>
      </Show>
    );
  };

  return (
    <Modal
      open={props.open !== undefined ? props.open : store().osGeolocationError || store().browserGeolocationError}
      onClose={onClose}
      maxWidth={400}
      image="/geolocation-error-generic.png"
      footer={
        <div class={styles.footer}>
          <Show when={store().osGeolocationError} fallback={renderBrowserFooterInstructions()}>
            {renderOSFooterInstructions()}
          </Show>
        </div>
      }
    >
      <Switch>
        <Match when={store().osGeolocationError}>
          <h4>Headwind requires location services to be enabled on your device.</h4>
          <p>
            Your location is used to fetch the weather in your area and to center maps on your current location. Please
            make sure location services are enabled in your operating system settings.
          </p>
        </Match>
        <Match when={store().browserGeolocationError}>
          <h4>Browser location services disabled</h4>
          <p>
            Your location is used to fetch the weather in your area and to center maps on your current location. Please
            make sure location services are enabled in your browser settings.
          </p>
          <Show when={!isSafari()}>
            <p>To change the location settings in {getBrowser()} open up a new tab and paste in the below url.</p>
          </Show>
        </Match>
      </Switch>
    </Modal>
  );
};

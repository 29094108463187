import { ParentComponent, mergeProps } from 'solid-js';
import styles from './NavDrawerItem.module.scss';
import { Icon, Icons } from '../Icon';
import { themeClass } from '../ThemeProvider';
import { NavLink } from '@solidjs/router';
import cn from 'classnames';

interface Props {
  icon?: Icons;
  onClick?: () => void;
  href?: string;
  active?: boolean;
}

export const NavDrawerItem: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      icon: 'IconLink' as Icons,
    },
    passedProps,
  );

  const renderInnerLink = () => {
    return (
      <>
        <div class={styles.icon}>
          <Icon type={props.icon} size="medium" />
        </div>
        <div class={styles.text}>{props.children}</div>
      </>
    );
  };

  return (
    <div class={themeClass(styles.dark, styles.container)} onClick={props.onClick}>
      {props.href && (
        <NavLink
          href={props.href}
          class={cn(styles.link, { [styles.active]: props.active })}
          activeClass={props.active === undefined ? styles.active : undefined}
        >
          {renderInnerLink()}
        </NavLink>
      )}
      {!props.href && <div class={styles.link}>{renderInnerLink()}</div>}
    </div>
  );
};

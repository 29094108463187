import { useNavigate, useParams } from '@solidjs/router';
import cn from 'classnames';
import { format } from 'date-fns';
import { Component, For, Show, createEffect, createSignal, onMount } from 'solid-js';
import colors from '../../styles/colors.module.scss';
import { WeatherHour, WeatherHourly } from '../../types';
import { isHourlyWeatherArray } from '../../types/guards';
import { reversePolyline } from '../../utils';
import { getRideHardness, getWeatherIcon, normalizeDate } from '../../utils/shared';
import { CenterContainer } from '../CenterContainer';
import { DailySummary } from '../DailySummary';
import { DatePicker } from '../DatePicker';
import { FadeInOnSee } from '../FadeInOnSee';
import { ForecastItem } from '../ForecastItem';
import { HoverPulse } from '../HoverPulse';
import { Icon } from '../Icon';
import { themeClass } from '../ThemeProvider';
import styles from './Forecast.module.scss';

interface ForecastDay extends WeatherHour {
  hardness?: number;
  link?: string;
}

interface Props {
  time: string;
  days: WeatherHourly['data'];
  onTimeChange: (time: string) => void;
  polyline?: string;
  reversed?: boolean;
  title?: string;
}

export const Forecast: Component<Props> = props => {
  const params = useParams();
  const navigate = useNavigate();
  const [time, setTime] = createSignal(props.time);
  const [overSummaryTimePicker, setOverSummaryTimePicker] = createSignal(false);
  const [overForecastTimePicker, setOverForecastTimePicker] = createSignal(false);
  const [days, setDays] = createSignal<ForecastDay[]>([]);
  const [polyline, setPolyline] = createSignal<string | undefined>(props.polyline);

  onMount(() => {
    if (props.reversed && props.polyline) {
      const reversedPolyline = reversePolyline(props.polyline);
      setPolyline(reversedPolyline);
    }
    processDays();
  });

  createEffect(() => {
    if (time() !== props.time) {
      processDays();
    }
  }, time);

  const processDays = () => {
    if (!isHourlyWeatherArray(props.days)) {
      const [hours, mins] = time().split(':');
      let newHour = Number(hours);
      if (Number(mins) >= 30) {
        newHour += 1;
      }
      if (newHour > 23) {
        newHour = 0;
      }
      const hour = String(newHour).padStart(2, '0');
      const processedDays: ForecastDay[] = [];
      const line = polyline();
      const curDayOfMonth = format(new Date(), 'dd');
      const curHour = new Date().getHours();
      Object.keys(props.days)
        .sort()
        .forEach(dayOfMonth => {
          let skipDay = false;
          // Make sure we skip the current day if the hour is in the past
          if (dayOfMonth === curDayOfMonth && newHour <= curHour) {
            skipDay = true;
          }
          if (!skipDay && !isHourlyWeatherArray(props.days)) {
            const day = props.days[dayOfMonth as string];
            const data = day[hour];
            if (data) {
              processedDays.push({
                ...data,
                hardness: line ? getRideHardness(line, data) : undefined,
                link:
                  params.type &&
                  params.id &&
                  `/${params.type}/${params.id}/${normalizeDate(new Date(data.time * 1000))}`,
              });
            }
          }
        });
      // We only want to show the next 7 days
      setDays(processedDays.slice(0, 7));
    }
  };

  const updateTime = (time: Date) => {
    const timeString = format(time, 'HH:mm');
    setTime(timeString);
    props.onTimeChange(timeString);
  };

  const getTimeValue = () => {
    const timeString = normalizeDate();
    const parts = timeString.split('T');
    return new Date(`${parts[0]}T${time()}`);
  };

  const onForecastItemClick = (link: string | undefined) => {
    link && navigate(link);
  };

  return (
    <CenterContainer>
      <div class={themeClass(styles.dark, styles.container)}>
        <Show when={props.title}>
          <h2 class={styles.title}>
            {props.title}
            <Show when={props.reversed}> (reversed)</Show>
          </h2>
        </Show>
        <div class={styles.summary}>
          <div class={styles.header}>
            <h3>Daily summary for {time()}</h3>
            <div
              onPointerOver={() => setOverSummaryTimePicker(true)}
              onPointerOut={() => setOverSummaryTimePicker(false)}
              class={styles.timePicker}
            >
              <DatePicker mode="time" position="center" onChange={updateTime} value={getTimeValue()}>
                Change Time{' '}
                <HoverPulse active={overSummaryTimePicker()} fast size="large" color={colors.secondary}>
                  <Icon type="IconClock" size="medium" />
                </HoverPulse>
              </DatePicker>
            </div>
          </div>

          <DailySummary
            days={days().map(day => ({
              day: format(day.time * 1000, 'E'),
              windDir: day.windBearing,
              windSpeed: day.windSpeed,
              hardness: day.hardness,
              link: day.link,
            }))}
          />
        </div>

        <div class={styles.forecast}>
          <div class={styles.header}>
            <h3>Daily forecast for {time()}</h3>
            <div
              onPointerOver={() => setOverForecastTimePicker(true)}
              onPointerOut={() => setOverForecastTimePicker(false)}
              class={styles.timePicker}
            >
              <DatePicker mode="time" position="center" onChange={updateTime} value={getTimeValue()}>
                Change Time{' '}
                <HoverPulse active={overForecastTimePicker()} fast size="large" color={colors.secondary}>
                  <Icon type="IconClock" size="medium" />
                </HoverPulse>
              </DatePicker>
            </div>
          </div>
          <ul class={styles.items}>
            <For each={days()}>
              {day => (
                <li
                  class={cn(styles.item, { [styles.hasLink]: day.link })}
                  onClick={onForecastItemClick.bind(null, day.link)}
                >
                  <FadeInOnSee>
                    <ForecastItem
                      time={day.time * 1000}
                      rainfall={day.precipIntensity}
                      summary={day.summary}
                      windDir={day.windBearing}
                      windSpeed={day.windSpeed}
                      windGust={day.hardness === undefined ? day.windGust : undefined}
                      temp={day.apparentTemperature}
                      icon={getWeatherIcon(day)}
                      hardness={day.hardness}
                    />
                  </FadeInOnSee>
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
    </CenterContainer>
  );
};

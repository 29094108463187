import { ParentComponent, createSignal, onCleanup, onMount } from 'solid-js';

interface Props {
  onSee?: () => void;
  onSeen?: () => void;
  onGone?: () => void;
  container?: HTMLElement;
}

export const InView: ParentComponent<Props> = props => {
  const [seen, setSeen] = createSignal(false);
  let ref: HTMLDivElement | undefined;
  let observer: IntersectionObserver;

  onMount(() => {
    if (ref) {
      const options = {
        threshold: 0.5,
      };
      observer = new IntersectionObserver(onSee, options);
      observer.observe(ref);
      if (isVisible(ref)) {
        see();
      }
    }
  });

  onCleanup(() => {
    if (observer) {
      observer.disconnect();
    }
  });

  const isVisible = (el: HTMLDivElement) => {
    const bounds = el.getBoundingClientRect();
    if (props.container) {
      const containerBounds = props.container.getBoundingClientRect();
      return bounds.top >= containerBounds.top && bounds.bottom <= containerBounds.bottom;
    } else {
      return bounds.top >= 0 && bounds.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }
  };

  const onSee = (entries: IntersectionObserverEntry[]) => {
    if (entries[0] && entries[0].isIntersecting) {
      see();
    } else if (entries[0] && !entries[0].isIntersecting) {
      onGone();
    }
  };

  const onSeen = () => {
    props.onSeen && props.onSeen();
  };

  const onGone = () => {
    if (ref && !isVisible(ref) && seen()) {
      props.onGone && props.onGone();
    }
  };

  const see = () => {
    props.onSee && props.onSee();
    if (!seen()) {
      onSeen();
      setSeen(true);
    }
  };

  return <div ref={ref}>{props.children}</div>;
};

import { animate } from 'motion';
import { ParentComponent, createEffect, createSignal } from 'solid-js';
import { InView } from '../InView';
import styles from './Image.module.scss';
import { LoadingSpinner } from '../LoadingSpinner';
import { themeClass } from '../ThemeProvider';

export interface ImageProps {
  src: string;
  alt: string;
  lazyload?: boolean;
  width?: number | '100%';
  height?: number;
  objectFit?: 'contain' | 'cover' | 'fill' | 'scale-down' | 'none';
  aspectRatio?: '1:1' | '16:9' | '4:3';
  onLoad?: () => void;
  transparentBg?: boolean;
  hideLoading?: boolean;
  class?: string;
}

export const Image: ParentComponent<ImageProps> = props => {
  const [src, setSrc] = createSignal(props.lazyload ? '' : props.src);
  const [loading, setLoading] = createSignal(true);
  let imgRef: HTMLImageElement | undefined;

  createEffect(prevSrc => {
    if (prevSrc !== props.src) {
      setSrc(props.src);
    }
    return props.src;
  }, props.src);

  const onSeen = () => {
    if (props.lazyload) {
      setSrc(props.src);
    }
  };

  const onLoad = () => {
    setLoading(false);
    fadeIn();
    props.onLoad && props.onLoad();
  };

  const fadeIn = () => {
    if (imgRef) {
      animate(imgRef, { opacity: 1 }, { duration: 0.2 });
    }
  };

  const getWidth = () => {
    if (typeof props.width === 'number') {
      return `${props.width}px`;
    }
    return props.width;
  };

  return (
    <div
      class={themeClass(styles.dark, styles.imageHolder, {
        [styles.transparentBg]: props.transparentBg,
        [styles.square]: props.aspectRatio === '1:1',
        [styles.tv]: props.aspectRatio === '4:3',
        [styles.movie]: props.aspectRatio === '16:9',
        [styles.fullWidth]: props.width === '100%',
        [String(props.class)]: props.class !== undefined,
      })}
      style={{
        width: getWidth(),
        height: props.height && !props.aspectRatio ? `${props.height}px` : undefined,
      }}
    >
      <InView onSeen={onSeen}>
        {loading() && !props.hideLoading && (
          <div class={styles.loading}>
            <LoadingSpinner size="small" />
          </div>
        )}
        {src() && (
          <img
            class={styles.image}
            ref={imgRef}
            src={src()}
            alt={props.alt}
            width={props.width === '100%' ? undefined : props.width}
            height={props.height}
            onLoad={onLoad}
            style={{
              'object-fit': props.objectFit || 'cover',
              width: getWidth(),
              height: props.height && !props.aspectRatio ? `${props.height}px` : undefined,
            }}
          />
        )}
      </InView>
    </div>
  );
};

import { Component, Show, createEffect, createSignal, onMount } from 'solid-js';
import styles from './UpdateAppToast.module.scss';
import { Button } from '../Button';
import { registerSW } from 'virtual:pwa-register';
import { animate } from 'motion';
import { deleteCache } from '../../api';
import { isLiveEnvironment } from '../../utils';

interface Props {
  forceShow?: boolean;
}

export const UpdateAppToast: Component<Props> = props => {
  const [needsUpdate, setNeedsUpdate] = createSignal(false);
  let containerRef: HTMLDivElement | undefined = undefined;

  onMount(() => {
    if (props.forceShow) {
      setNeedsUpdate(true);
    }
  });

  const show = () => {
    setTimeout(() => {
      if (containerRef) {
        animate(containerRef, { y: ['100%', '0%'] }, { duration: 0.2 });
      }
    });
  };

  const updateSW = registerSW({
    onNeedRefresh() {
      if (!isLiveEnvironment()) {
        console.log('SW needs refreshing');
      }
      setNeedsUpdate(true);
    },
    onOfflineReady() {
      if (!isLiveEnvironment()) {
        console.log('SW is installed and offline ready');
      }
    },
  });

  const update = async () => {
    await deleteCache();
    if (!isLiveEnvironment()) {
      console.log('Successfully deleted app cache');
    }
    await updateSW();
    if (!isLiveEnvironment()) {
      console.log('Successfully re-registered SW');
    }
    window.location.reload();
  };

  createEffect(prevNeedsUpdate => {
    if (!prevNeedsUpdate && needsUpdate()) {
      show();
    }
    return needsUpdate();
  }, needsUpdate());

  return (
    <Show when={needsUpdate()}>
      <div class={styles.container} ref={containerRef}>
        <p>Update available. Click reload button to update.</p>
        <Button icon="IconRefreshAlert" appearance="muted" onClick={update}>
          Reload
        </Button>
      </div>
    </Show>
  );
};

import cn from 'classnames';
import { Component } from 'solid-js';
import { HoverBurst } from '../HoverBurst';
import stravaButtonImage from './images/strava-login-orange.svg';
import stravaButtonImageLight from './images/strava-login-white.svg';
import styles from './StravaButton.module.scss';

interface Props {
  full?: boolean;
  onClick?: () => void;
  light?: boolean;
}

export const StravaButton: Component<Props> = props => {
  return (
    <button
      class={cn(styles.button, { [styles.full]: props.full, [styles.light]: props.light })}
      onClick={props.onClick}
    >
      <HoverBurst theme="light">
        <img src={props.light ? stravaButtonImageLight : stravaButtonImage} />
      </HoverBurst>
    </button>
  );
};

import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  Context,
  createContext,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  ParentComponent,
  Show,
  useContext,
} from 'solid-js';
import WebFont from 'webfontloader';
import headwindLogo from '../../assets/images/headwind-logo.svg';
import breakpoints from '../../styles/breakpoints.module.scss';
import { Button } from '../Button';
import { CenterContainer } from '../CenterContainer';
import { Splash } from '../Splash';
import styles from './AssetsProvider.module.scss';

interface Props {
  stripeKey: string;
  stripeInstance?: Stripe | null;
  fontReady?: boolean;
  logoReady?: boolean;
}

const defaultProps: Props = {
  stripeKey: 'pk_test_stripekeyhere',
  stripeInstance: null,
  fontReady: false,
  logoReady: false,
};

const AssetsContext: Context<Props> = createContext(defaultProps);

export const AssetsProvider: ParentComponent<Props> = passedProps => {
  const props = mergeProps(defaultProps, passedProps);
  const [assetsProps, setAssetsProps] = createSignal(props);
  const [assetsLoaded, setAssetsLoaded] = createSignal(false);
  const [hideSplash, setHideSplash] = createSignal(false);
  const [errorLoading, setErrorLoading] = createSignal(false);
  const giveUpAfter = 8000;
  let assetLoadTimeout: number = 0;
  let splashTimeout: number = 0;

  onMount(async () => {
    // load stripe
    try {
      const stripe = await loadStripe(props.stripeKey);
      setAssetsProps(mergeProps(assetsProps(), { stripeInstance: stripe }));
    } catch (e: any) {
      throw new Error(e);
    }

    // load fonts
    // NOTE: fonts are loaded here and in index.html to stop font loading jitters
    WebFont.load({
      google: {
        families: ['Roboto:300,400,500', 'Raleway:300,400,600'],
      },
      active: () => {
        setAssetsProps(mergeProps(assetsProps(), { fontReady: true }));
      },
    });

    // Load up headwind logo
    const logo = new Image();
    logo.onload = () => {
      setAssetsProps(mergeProps(assetsProps(), { logoReady: true }));
    };
    logo.src = headwindLogo;

    assetLoadTimeout = window.setTimeout(() => {
      setErrorLoading(true);
    }, giveUpAfter);
  });

  onCleanup(() => {
    clearTimeout(assetLoadTimeout);
    clearTimeout(splashTimeout);
  });

  createEffect(() => {
    const assets = assetsProps();
    if (assets.fontReady && assets.logoReady && assets.stripeInstance) {
      setAssetsLoaded(true);
      clearTimeout(assetLoadTimeout);
      splashTimeout = window.setTimeout(
        () => {
          setHideSplash(true);
        },
        isDesktopSplash() ? 0 : 2000,
      );
    }
  }, assetsProps());

  const isDesktopSplash = () => {
    return window.matchMedia(`(min-width: ${breakpoints.desktop})`).matches;
  };

  return (
    <>
      <Show when={errorLoading()} fallback={<Splash version={APP_VERSION} loaded={hideSplash()} />}>
        <CenterContainer>
          <div class={styles.errorContainer}>
            <h4>Sorry there was an error loading Headwind.</h4>
            <Button icon="IconRefresh" onClick={() => window.location.reload()}>
              Try Again
            </Button>
          </div>
        </CenterContainer>
      </Show>
      <Show when={assetsLoaded()}>
        <AssetsContext.Provider value={assetsProps()}>{props.children}</AssetsContext.Provider>
      </Show>
    </>
  );
};

export const useAssets = () => {
  return useContext(AssetsContext);
};

import { useNavigate } from '@solidjs/router';
import cn from 'classnames';
import { For, ParentComponent } from 'solid-js';
import { SummaryItem, SummaryItemProps } from '../SummaryItem';
import { themeClass } from '../ThemeProvider';
import styles from './DailySummary.module.scss';

interface Day extends SummaryItemProps {
  link?: string;
}

interface Props {
  days: Day[];
}

export const DailySummary: ParentComponent<Props> = props => {
  const navigate = useNavigate();

  const onClick = (link: string | undefined) => {
    link && navigate(link);
  };

  return (
    <div class={themeClass(styles.dark, styles.container)}>
      <For each={props.days}>
        {item => (
          <div class={cn(styles.item, { [styles.hasLink]: item.link })} onClick={onClick.bind(null, item.link)}>
            <SummaryItem day={item.day} windSpeed={item.windSpeed} hardness={item.hardness} windDir={item.windDir} />
          </div>
        )}
      </For>
    </div>
  );
};

import { animate } from 'motion';
import { JSXElement, ParentComponent, createEffect, createSignal } from 'solid-js';
import { Portal } from 'solid-js/web';
import { easing } from 'ts-easing';
import { isDescendant } from '../../utils';
import { HoverPulse } from '../HoverPulse';
import { Icon } from '../Icon';
import { Image } from '../Image';
import { ScrollContainer } from '../ScrollContainer';
import { themeClass } from '../ThemeProvider';
import styles from './Modal.module.scss';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  image?: string;
  footer?: JSXElement;
  maxWidth?: number;
  light?: boolean;
}

export const Modal: ParentComponent<ModalProps> = props => {
  const [isOpen, setIsOpen] = createSignal(props.open);
  let overlayRef: HTMLDivElement | undefined;
  let modalRef: HTMLDivElement | undefined;
  let closeRef: HTMLAnchorElement | undefined;

  createEffect(prevOpen => {
    if (prevOpen !== props.open) {
      if (props.open) {
        openModal();
      } else {
        closeModal();
      }
    }
    return props.open;
  }, props.open);

  const openModal = () => {
    if (overlayRef && modalRef) {
      setIsOpen(true);
      animate(overlayRef, { opacity: 1 }, { duration: 0.2 });
      animate(modalRef, { y: [50, 0] }, { duration: 0.2, easing: easing.outCubic });
    }
  };

  const closeModal = () => {
    if (overlayRef && modalRef) {
      animate(overlayRef, { opacity: 0 }, { duration: 0.2 }).finished.then(() => {
        setIsOpen(false);
      });
      animate(modalRef, { y: [0, 50] }, { duration: 0.2 });
    }
  };

  const onClose = (e: MouseEvent) => {
    const target = e.target;
    if (isDescendant(closeRef, target) || target === overlayRef) {
      props.onClose && props.onClose();
    }
  };

  return (
    <Portal>
      <div
        ref={overlayRef}
        class={themeClass(props.light ? '' : styles.dark, styles.overlay, { [styles.open]: isOpen() })}
        onClick={onClose}
      >
        <div class={styles.modal} style={{ 'max-width': props.maxWidth ? `${props.maxWidth}px` : undefined }}>
          <a class={styles.close} ref={closeRef}>
            <HoverPulse>
              <Icon type="IconX" />
            </HoverPulse>
          </a>
          <div ref={modalRef} class={styles.modalInner}>
            {props.title && <div class={styles.title}>{props.title}</div>}
            <ScrollContainer class={styles.content}>
              {props.image && (
                <div class={styles.imageContainer}>
                  <Image
                    width="100%"
                    src={props.image}
                    lazyload={false}
                    alt={props.title ? `${props.title} image` : 'Modal image'}
                  />
                </div>
              )}
              {props.children}
            </ScrollContainer>
            {props.footer && <div class={styles.footer}>{props.footer}</div>}
          </div>
        </div>
      </div>
    </Portal>
  );
};

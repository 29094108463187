import { addDays, format, isAfter, isBefore, parse, subDays } from 'date-fns';
import datepicker from 'js-datepicker';
import 'js-datepicker/dist/datepicker.min.css';
import { ParentComponent, createEffect, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import { Portal } from 'solid-js/web';
import { solidComponentToHTMLElement } from '../../utils';
import { normalizeDate } from '../../utils/shared';
import { themeClass } from '../ThemeProvider';
import { TimePicker } from '../TimePicker';
import styles from './DatePicker.module.scss';

type Instance = { [key: string]: any }; // TODO: type this

export type datePickerMode = 'time' | 'date' | 'datetime';

type Positions = 'topleft' | 'bottomleft' | 'topright' | 'bottomright' | 'center';

enum POSITION {
  'topleft' = 'tl',
  'bottomleft' = 'bl',
  'topright' = 'tr',
  'bottomright' = 'br',
  'center' = 'c',
}

interface Props {
  value?: Date;
  placeholder?: string;
  dateFormat?: string;
  onChange?: (date: Date) => void;
  onShow?: () => void;
  onHide?: () => void;
  onMonthChange?: (instance: Instance) => void;
  position?: Positions;
  maxDate?: Date;
  minDate?: Date;
  showAllDates?: boolean;
  disableYearOverlay?: boolean;
  noWeekends?: boolean;
  disabler?: (date: Date) => boolean;
  disabledDates?: Date[];
  mode?: datePickerMode;
  show24hr?: boolean;
  class?: string;
  theme?: 'dark' | 'light';
  open?: boolean;
  timeInterval?: number;
}

export const DatePicker: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      value: new Date(),
      placeholder: 'DD/MM/YYYY',
      dateFormat: 'dd/MM/yyyy',
      position: 'bottomleft' as Positions,
      defaultView: 'calendar',
      minDate: subDays(passedProps.value || new Date(), 5),
      maxDate: addDays(passedProps.value || new Date(), 14),
      showAllDates: false,
      disableYearOverlay: true,
      noWeekends: false,
      show24hr: true,
      mode: 'date',
      includeTime: passedProps.mode === 'datetime' || passedProps.mode === 'time',
      onlyTime: passedProps.mode === 'time',
      timeInterval: 15,
    },
    passedProps,
  );

  const getValue = (date?: Date, timeOverride?: string) => {
    let value = date ? format(date, props.dateFormat) : '';
    if (props.includeTime && value && (time() || timeOverride)) {
      value = `${value} ${time() || timeOverride}`;
    }
    return value;
  };

  const getTime = (value: Date) => {
    const time = format(value, 'HH:mm');
    return time;
  };

  const [timeFormat, setTimeFormat] = createSignal<string>();
  const [date, setDate] = createSignal<Date>(new Date(props.value));
  const [time, setTime] = createSignal<string>(getTime(new Date(props.value)));
  const [open, setOpen] = createSignal(props.open);
  const [hasSelected, setHasSelected] = createSignal<boolean>(false);
  const [value, setValue] = createSignal<string>(getValue(props.value));

  let pickerContainerRef: HTMLDivElement | undefined;
  let childrenContainerRef: HTMLDivElement | undefined;
  let pickerRef: HTMLDivElement | undefined;
  let picker: any; // TODO: sort out typing

  onMount(() => {
    let selected = props.value;
    if (pickerRef) {
      if (isBefore(props.value, props.minDate)) {
        selected = props.minDate;
      } else if (isAfter(props.value, props.maxDate)) {
        selected = props.maxDate;
      }
      picker = datepicker(pickerRef, {
        onSelect: (instance: Instance, date: Date) => {
          const dateAndTimeString = `${normalizeDate(date).split('T')[0]}T${time()}:00`;
          const dateAndTime = new Date(dateAndTimeString);
          setDate(dateAndTime);
          if (hasSelected() || !props.includeTime) {
            onChange();
          } else {
            setInputValue();
          }
        },
        disabler: props.disabler,
        onMonthChange: props.onMonthChange,
        position: POSITION[props.position],
        defaultView: props.defaultView,
        alwaysShow: true,
        dateSelected: selected,
        maxDate: props.maxDate,
        minDate: props.minDate,
        showAllDates: props.showAllDates,
        disableYearOverlay: props.disableYearOverlay,
        noWeekends: props.noWeekends,
        disabledDates: props.disabledDates,
      });
    }

    if (pickerContainerRef && props.includeTime) {
      const container = pickerContainerRef.querySelector('.qs-datepicker-container');
      if (container) {
        if (props.onlyTime) {
          container.innerHTML = '';
        }

        const timePicker = solidComponentToHTMLElement(
          <TimePicker
            class={styles.timePicker}
            show24hr={props.show24hr}
            onMount={onTimePickerMount}
            onChange={onTimeChange}
            value={date()}
            theme={props.theme}
            interval={props.timeInterval}
          />,
        );

        if (timePicker) {
          container.appendChild(timePicker);
        }

        if (!props.onlyTime) {
          const selectTimeButton = (
            <button class={styles.selectButton} onClick={onSelect}>
              Select
            </button>
          );
          if (selectTimeButton) {
            container.appendChild(selectTimeButton as HTMLElement);
          }
        }
      }
    }

    setInputValue();
  });

  createEffect((prevValue: Date | undefined) => {
    if (normalizeDate(props.value) !== normalizeDate(prevValue)) {
      const date = new Date(props.value);
      setDate(date);
      const time = getTime(date);
      setTime(time);
      setInputValue();
    }
    return props.value;
  });

  createEffect(prevOpen => {
    if (prevOpen !== props.open) {
      if (props.open) {
        showPicker();
      } else {
        hidePicker();
      }
    }
    return props.open;
  }, props.open);

  onCleanup(() => {
    picker && picker.remove();
  });

  const showPicker = () => {
    setOpen(true);
    props.onShow && props.onShow();
  };

  const hidePicker = () => {
    setOpen(false);
    setHasSelected(false);
    props.onHide && props.onHide();
  };

  const onTimePickerMount = (initTime: string | Date, timeFormat: string) => {
    setTime(String(initTime));
    setTimeFormat(timeFormat);
    setValue(getValue(date(), String(initTime)));
    setInputValue();
  };

  const onTimeChange = (time: Date | string) => {
    setTime(String(time));
    setInputValue();
    if (props.onlyTime) {
      onSelect();
    }
  };

  const onSelect = () => {
    setHasSelected(true);
    onChange();
  };

  const setInputValue = () => {
    const val = getValue(date());
    if (childrenContainerRef) {
      const input = childrenContainerRef.getElementsByTagName('input')[0];
      if (input) {
        if (props.onlyTime) {
          input.value = time() || '';
        } else {
          input.value = val;
        }
      }
    }
    setValue(val);
  };

  const onChange = () => {
    if (hasSelected() || !props.includeTime) {
      hidePicker();
    }
    setInputValue();
    let format = props.dateFormat;
    if (timeFormat()) {
      format = `${format} ${timeFormat()}`;
    }
    const parsedDate = parse(value(), format, new Date());
    props.onChange && props.onChange(parsedDate);
  };

  if (props.position === 'center') {
    // If in modal mode
    return (
      <>
        <Portal>
          <div
            class={themeClass(props.theme ? styles[props.theme] : styles.dark, styles.datePicker, {
              [styles.isModal]: true,
              [styles.includeTime]: props.includeTime,
              [styles.open]: open(),
              [styles.onlyTime]: props.onlyTime,
            })}
            ref={pickerContainerRef}
          >
            <div ref={pickerRef}></div>
            <div class={styles.overlay} onClick={hidePicker}></div>
          </div>
        </Portal>
        <div onClick={showPicker} class={props.class} ref={childrenContainerRef}>
          {props.children}
        </div>
      </>
    );
  } else {
    // Not in modal mode
    return (
      <div
        class={themeClass(props.theme ? styles[props.theme] : styles.dark, styles.datePicker, {
          [styles.includeTime]: props.includeTime,
          [styles.open]: open(),
          [styles.onlyTime]: props.onlyTime,
        })}
        ref={pickerContainerRef}
      >
        <div onClick={showPicker} ref={pickerRef} class={props.class}>
          <div ref={childrenContainerRef}>{props.children}</div>
        </div>
      </div>
    );
  }
};

import { ParentComponent, mergeProps } from 'solid-js';
import styles from './WeatherIcon.module.scss';
import { Icon, WeatherIcons } from '../Icon';
import { themeClass } from '../ThemeProvider';

interface Props {
  type: WeatherIcons;
  size?: 'medium' | 'small';
}

export const WeatherIcon: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      size: 'medium',
    },
    passedProps,
  );
  return (
    <div class={themeClass(styles.dark, styles.container, { [styles[props.size]]: props.size ? true : false })}>
      <Icon type={props.type} />
    </div>
  );
};

import { ParentComponent, createEffect, createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import { clamp, roundToNearest } from '../../utils';
import { themeClass } from '../ThemeProvider';
import styles from './RangeSlider.module.scss';

interface Props {
  min: number;
  max: number;
  value: number;
  label?: string;
  direction?: 'horizontal' | 'vertical';
  step?: number;
  onChange?: (value: number) => void;
  valueSuffix?: string;
  alwaysShowValue?: boolean;
  trackColor?: string;
}

export const RangeSlider: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      direction: 'horizontal',
      step: 1,
      alwaysShowValue: true,
    },
    passedProps,
  );

  let slider: HTMLDivElement | undefined;
  let valueTrack: HTMLDivElement | undefined;
  let handle: HTMLDivElement | undefined;
  let updateTimeout = 0;
  let resizeTimeout = 0;

  const parseSliderValue = (val: number) => {
    return roundToNearest(clamp(val, props.min, props.max), props.step);
  };

  const [pointerDown, setPointerDown] = createSignal(false);
  const [value, setValue] = createSignal(parseSliderValue(props.value));

  onMount(() => {
    propsUpdated();
    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
    window.addEventListener('resize', onResize);
  });

  onCleanup(() => {
    document.removeEventListener('pointermove', onPointerMove);
    document.removeEventListener('pointerup', onPointerUp);
    window.removeEventListener('resize', onResize);
    clearTimeout(updateTimeout);
    clearTimeout(resizeTimeout);
  });

  createEffect(prevValue => {
    if (props.value !== prevValue) {
      propsUpdated();
    }
    return props.value;
  }, props.value);

  createEffect(prevMax => {
    if (props.max !== prevMax) {
      propsUpdated();
    }
    return props.max;
  }, props.max);

  const onResize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = window.setTimeout(() => {
      updateSlider();
    }, 100);
  };

  const propsUpdated = () => {
    const parsedValue = parseSliderValue(props.value);
    setSliderValue(parsedValue);
    if (!pointerDown()) {
      const x = valueToPx(parsedValue);
      setHandlePosition(x);
    }
  };

  const getElementSize = (el: HTMLDivElement) => {
    if (!el) {
      return 0;
    }
    return props.direction === 'horizontal' ? el.clientWidth : el.clientHeight;
  };

  const valueToPx = (val: number) => {
    if (!slider || !handle) {
      return 0;
    }
    const range = props.max - props.min;
    const realValue = val - props.min;
    const percent = realValue / range;
    const sliderSize = getElementSize(slider) - getElementSize(handle);
    let px = sliderSize * percent;
    if (props.direction === 'vertical') {
      px = sliderSize - px;
    }
    return roundToNearest(px, props.step);
  };

  const pxToValue = (px: number) => {
    if (!slider || !handle) {
      return 0;
    }
    const size = getElementSize(slider);
    if (!size) {
      return 0;
    }
    const range = props.max - props.min;
    const percent = px / getElementSize(slider);
    let val = range * percent;
    if (props.direction === 'vertical') {
      val = range - val;
    }
    return roundToNearest(val + props.min, props.step);
  };

  const setHandlePosition = (pos: number) => {
    if (handle && slider && valueTrack) {
      const sliderSize = getElementSize(slider);
      const x = clamp(pos, 0, sliderSize - getElementSize(handle));
      if (props.direction === 'horizontal') {
        handle.style.left = `${x}px`;
        valueTrack.style.width = `${x}px`;
      } else {
        handle.style.top = `${x}px`;
        valueTrack.style.height = `${sliderSize - x}px`;
      }
    }
  };

  const setSliderValue = (val: number) => {
    const parsedValue = parseSliderValue(val);
    if (parsedValue !== value()) {
      setValue(parsedValue);
      props.onChange && props.onChange(parsedValue);
    }
  };

  const updateSlider = (e?: PointerEvent) => {
    if (slider && handle) {
      const pos = {
        x: 0,
        y: 0,
      };
      if (!e) {
        const handlePosition = handle.getBoundingClientRect();
        pos.x = handlePosition.x;
        pos.y = handlePosition.y;
      } else {
        pos.x = e.pageX;
        pos.y = e.pageY;
      }
      const sliderOffset = slider.getBoundingClientRect();
      const pointerPosition = props.direction === 'horizontal' ? pos.x : pos.y;
      const offset = props.direction === 'horizontal' ? sliderOffset.x : sliderOffset.y;
      const position = pointerPosition - offset - getElementSize(handle) / 2;
      setHandlePosition(position);
      setSliderValue(pxToValue(position));

      document.body.style.userSelect = 'none';
      clearTimeout(updateTimeout);
      updateTimeout = window.setTimeout(() => {
        document.body.style.userSelect = 'auto';
      }, 500);
    }
  };

  const onPointerDown = () => {
    setPointerDown(true);
  };

  const onPointerUp = () => {
    setPointerDown(false);
  };

  const onPointerMove = (e: PointerEvent) => {
    if (pointerDown()) {
      updateSlider(e);
    }
  };

  return (
    <div
      class={themeClass(styles.dark, styles.slider, {
        [styles.vertical]: props.direction === 'vertical',
        [styles.hasLabel]: props.label ? true : false,
      })}
      ref={slider}
      onPointerUp={updateSlider}
    >
      {props.label && <span class={styles.label}>{props.label}</span>}
      <div class={styles.innerSlider}>
        <div class={styles.track} style={{ background: props.trackColor || undefined }}></div>
        <div class={styles.valueTrack} ref={valueTrack}></div>
        <div class={styles.handle} ref={handle} onPointerDown={onPointerDown}>
          <div class={styles.dragHelper}></div>
          {(pointerDown() || props.alwaysShowValue) && (
            <div class={styles.value}>
              <span>
                {value()}
                {props.valueSuffix}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { ParentComponent, Show, mergeProps } from 'solid-js';
import { GradientBackground } from '../GradientBackground';
import { GradientText } from '../GradientText';
import { HoverBurst } from '../HoverBurst';
import { Icons, Icon } from '../Icon';
import cn from 'classnames';
import styles from './Button.module.scss';
import { LoadingSpinner } from '../LoadingSpinner';

export interface ButtonProps {
  icon?: Icons;
  onClick?: () => void;
  type?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  full?: boolean;
  class?: string;
  appearance?: 'default' | 'light' | 'muted' | 'outline';
}

export const Button: ParentComponent<ButtonProps> = passedProps => {
  const props = mergeProps({ style: 'default' }, passedProps);
  const renderInnerButton = () => {
    return (
      <HoverBurst theme={props.appearance === 'light' ? 'light' : 'dark'}>
        <span class={styles.content}>
          {props.icon && (
            <span class={styles.icon}>
              <Icon type={props.icon} size="medium" />
            </span>
          )}
          <Show when={props.appearance === 'outline'} fallback={<>{props.children}</>}>
            <GradientText>{props.children}</GradientText>
          </Show>
        </span>
      </HoverBurst>
    );
  };

  return (
    <button
      onClick={props.onClick}
      type={props.type || 'button'}
      class={cn(styles.button, {
        [styles.disabled]: props.disabled,
        [styles.loading]: props.loading,
        [styles.full]: props.full,
        [styles.outline]: props.appearance === 'outline',
        [styles.light]: props.appearance === 'light',
        [styles.muted]: props.appearance === 'muted',
        [String(props.class)]: props.class ? true : false,
      })}
    >
      {props.loading && (
        <span class={styles.loadingSpinner}>
          <LoadingSpinner size="small" />
        </span>
      )}
      <Show
        when={props.appearance === 'light' || props.appearance === 'muted'}
        fallback={
          <GradientBackground borderOnly={props.appearance === 'outline'}>{renderInnerButton()}</GradientBackground>
        }
      >
        <>{renderInnerButton()}</>
      </Show>
    </button>
  );
};

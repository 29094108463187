import cn from 'classnames';
import { ParentComponent, Show, mergeProps } from 'solid-js';
import { Image } from '../Image';
import styles from './Avatar.module.scss';

interface Props {
  name: string;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  image?: string;
}

export const Avatar: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      size: 'medium',
    },
    passedProps,
  );

  const getInitials = () => {
    const names = props.name.split(' ');
    const firstName = names[0];
    if (names.length === 1) {
      return firstName.charAt(0);
    }
    const lastName = names[names.length - 1];
    return `${firstName.charAt(0)} ${lastName.charAt(0)}`;
  };

  return (
    <div class={cn(styles.container, styles[props.size])}>
      <div class={styles.image}>
        <Show
          when={props.image}
          fallback={
            <Image
              src={`https://api.dicebear.com/6.x/bottts-neutral/svg?seed=${props.name}`}
              alt=""
              transparentBg
              hideLoading
              width="100%"
            />
          }
        >
          <Image src={String(props.image)} alt="" width="100%" transparentBg hideLoading />
        </Show>
      </div>
      <span class={styles.initials}>{getInitials()}</span>
    </div>
  );
};

import {
  Appearance,
  PaymentIntent,
  PaymentRequest,
  StripeElements,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import cn from 'classnames';
import { ParentComponent, Show, createEffect, createSignal, mergeProps, onMount } from 'solid-js';
import { getPaymentIntent } from '../../api';
import { useStore } from '../../store';
import colors from '../../styles/colors.module.scss';
import fonts from '../../styles/fonts.module.scss';
import { InputValidator, InputValue, PaymentApiResponse } from '../../types';
import { trackEvent } from '../../utils';
import { useAssets } from '../AssetsProvider';
import { Button, ButtonProps } from '../Button';
import { Icons } from '../Icon';
import { Input } from '../Input';
import { LoadingSpinner } from '../LoadingSpinner';
import { Modal } from '../Modal';
import styles from './StripePaymentRequestButton.module.scss';

interface Props extends ButtonProps {
  amount: number;
  paymentModalTitle: string;
  icon?: Icons;
  onSuccess?: (message: string, email: string) => void;
  onError?: (error: string) => void;
  onReady?: () => void;
  class?: string;
}

const MESSAGES = {
  success: 'Payment successful',
  failed: 'Payment failed, please try again',
  unexpected: 'An unexpected error occurred with the payment',
  processing: 'Your payment is processing, please check your email for a receipt',
};

export const StripePaymentRequestButton: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      icon: 'IconCurrencyDollar' as Icons,
    },
    passedProps,
  );
  const { store } = useStore();
  const { stripeInstance } = useAssets();
  const [paymentIntentError, setPaymentIntentError] = createSignal(false);
  const [validPaymentRequest, setValidPaymentRequest] = createSignal<PaymentRequest | false>();
  const [modalOpen, setModalOpen] = createSignal(false);
  const [loading, setLoading] = createSignal(false);
  const [email, setEmail] = createSignal(store().settings.email || '');
  let paymentFormRef: HTMLFormElement | undefined;
  let stripePaymentElementRef: HTMLDivElement | undefined;
  let validateEmail: InputValidator;

  // If the user email loads from settings make sure we update the internal email state
  createEffect(prevUserEmail => {
    const userEmail = store().settings.email;
    if (prevUserEmail !== userEmail && userEmail) {
      setEmail(userEmail);
    }
    return userEmail;
  }, store().settings.email);

  const init = () => {
    // Look for a successful payment and show messages if needed
    handlePaymentFormRedirection();

    // Get the payment intent from the server
    getPaymentIntent({ email: email() }, async resp => {
      if (resp.success) {
        const paymentIntent = resp.data;
        // If we have a payment intent and stripe initiated
        if (stripeInstance && paymentIntent && paymentIntent.clientSecret) {
          // Try to init a google pay / apple pay button
          const paymentRequest = await loadPaymentButton(paymentIntent);
          setValidPaymentRequest(paymentRequest);

          // If we cant use google or apple pay fallback to card form
          if (paymentRequest === false) {
            loadPaymentForm(paymentIntent);
          }

          props.onReady && props.onReady();
        }
      } else {
        setPaymentIntentError(true);
      }
    });
  };

  onMount(init);

  const onSuccess = () => {
    props.onSuccess && props.onSuccess(MESSAGES.success, email());
    init();
  };

  const loadPaymentForm = (intent: PaymentApiResponse['data']) => {
    if (intent && stripePaymentElementRef && paymentFormRef && intent.clientSecret && stripeInstance) {
      const appearance: Appearance = {
        theme: 'flat',
        variables: {
          colorPrimary: colors.secondary,
          colorDanger: colors.error,
          colorWarning: colors.warning,
          colorSuccess: colors.success,
          fontFamily: fonts.body,
          borderRadius: '0px',
        },
        rules: {
          '.Label': {
            textTransform: 'uppercase',
            fontSize: '0.8rem',
            color: colors.dark,
            fontWeight: '400',
          },
        },
      };
      const fontOptions = [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500',
        },
      ];
      const options: StripePaymentElementOptions = {
        layout: 'tabs',
        wallets: {
          applePay: 'auto',
          googlePay: 'auto',
        },
        business: {
          name: 'Headwind App',
        },
      };
      const elements = stripeInstance.elements({ fonts: fontOptions, appearance, clientSecret: intent.clientSecret });
      const paymentElement = elements.create('payment', options);
      paymentElement.mount(stripePaymentElementRef);

      paymentFormRef.addEventListener('submit', e => {
        e.preventDefault();
        onPaymentFormSubmit(elements);
      });
    } else {
      setPaymentIntentError(true);
    }
  };

  const onPaymentFormSubmit = async (elements: StripeElements) => {
    if (stripeInstance) {
      setLoading(true);

      // If no valid email then stop as we need an email to send a receipt
      const validEmail = validateEmail(email(), true);
      if (!validEmail) {
        setLoading(false);
        return;
      }

      const { error, paymentIntent } = await stripeInstance.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.href}`,
          receipt_email: email(),
        },
        redirect: 'if_required',
      });

      // If form error report it then return and stop loading, keeping the modal open
      if (error) {
        if (error.message && (error.type === 'card_error' || error.type === 'validation_error')) {
          props.onError && props.onError(error.message);
        } else {
          props.onError && props.onError(MESSAGES.unexpected);
        }
        setLoading(false);
        return;
      }

      // If no form error then check the payment intent
      const success = onPaymentFormComplete(paymentIntent);

      // If successful close the modal
      if (success) {
        setLoading(false);
        setModalOpen(false);
      }
    }
  };

  const onPaymentFormComplete = (paymentIntent?: PaymentIntent) => {
    switch (paymentIntent?.status) {
      case 'succeeded':
        onSuccess();
        trackEvent('Donation', 'Form', 'success');
        return true;
      case 'processing':
        props.onError && props.onError(MESSAGES.processing);
        trackEvent('Donation', 'Form', 'error');
        return true;
      case 'requires_payment_method':
        props.onError && props.onError(MESSAGES.failed);
        trackEvent('Donation', 'Form', 'error');
        return false;
      default:
        props.onError && props.onError(MESSAGES.unexpected);
        trackEvent('Donation', 'Form', 'error');
        return false;
    }
  };

  const handlePaymentFormRedirection = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const clientSecret = params.get('payment_intent_client_secret');
    if (!clientSecret || !stripeInstance) {
      return;
    }
    const { paymentIntent } = await stripeInstance.retrievePaymentIntent(clientSecret);
    onPaymentFormComplete(paymentIntent);
  };

  const loadPaymentButton = async (intent: PaymentApiResponse['data']): Promise<PaymentRequest | false> => {
    if (!stripeInstance) {
      return false;
    }

    const paymentRequest = stripeInstance.paymentRequest({
      country: 'AU',
      currency: 'aud',
      total: {
        label: props.paymentModalTitle,
        amount: props.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API first.
    const canPay = await paymentRequest.canMakePayment();

    if (canPay) {
      paymentRequest.on('paymentmethod', async e => {
        // If no intent or client secret then we cant continue
        if (!intent || !intent.clientSecret) {
          props.onError && props.onError(MESSAGES.failed);
          return false;
        }

        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await stripeInstance.confirmCardPayment(
          intent.clientSecret,
          { payment_method: e.paymentMethod.id, receipt_email: e.payerEmail },
          { handleActions: false },
        );

        // Save the email if it exists
        if (e.payerEmail) {
          setEmail(e.payerEmail);
        }

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          e.complete('fail');
          trackEvent('Donation', 'Payment Request', 'error');
          props.onError && props.onError(MESSAGES.failed);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          e.complete('success');
          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripeInstance.confirmCardPayment(intent.clientSecret);
            if (error) {
              // The payment failed - ask your customer for a new payment method.
              props.onError && props.onError(MESSAGES.failed);
              trackEvent('Donation', 'Payment Request', 'error');
            } else {
              // The payment has succeeded.
              onSuccess();
              trackEvent('Donation', 'Payment Request', 'success');
            }
          } else {
            // The payment has succeeded.
            onSuccess();
            trackEvent('Donation', 'Payment Request', 'success');
          }
        }
      });
      return paymentRequest;
    }
    return false;
  };

  const onClick = () => {
    const paymentRequest = validPaymentRequest();
    if (paymentRequest) {
      paymentRequest.show();
      trackEvent('Donation', 'Payment Request', 'start');
    } else {
      setModalOpen(true);
      trackEvent('Donation', 'Form', 'start');
    }
  };

  const onModalClose = () => {
    setModalOpen(false);
    trackEvent('Donation', 'Form', 'cancel');
  };

  const updateEmail = (e: any) => {
    setEmail(e.value);
  };

  const onEmailInputMount = (_: InputValue, validate: InputValidator) => {
    validateEmail = validate;
  };

  return (
    <Show when={stripeInstance}>
      <Button
        {...props}
        icon={paymentIntentError() ? 'IconCurrencyDollarOff' : props.icon}
        onClick={onClick}
        loading={props.loading}
        disabled={props.disabled || paymentIntentError()}
      >
        <Show when={!paymentIntentError()} fallback={<>Payment not available</>}>
          {props.children}
        </Show>
      </Button>

      <Show when={validPaymentRequest() === false}>
        <Modal title={props.paymentModalTitle} open={modalOpen()} onClose={onModalClose} maxWidth={350} light>
          {loading() && (
            <div class={styles.loadingSpinner}>
              <LoadingSpinner size="small" />
            </div>
          )}
          <form ref={paymentFormRef} class={cn(styles.paymentForm, { [styles.loading]: loading() })}>
            <Input
              type="email"
              label="email"
              name="email"
              theme="light"
              required
              onChange={updateEmail}
              onMount={onEmailInputMount}
              value={email()}
            />
            <br />
            <div ref={stripePaymentElementRef}></div>
            <div class={styles.submit}>
              <Button type="submit" icon={props.icon}>
                {props.children}
              </Button>
            </div>
            {/* <small class={styles.disclaimer}>
              * Your bank may charge you a tiny international transaction fee if you are located outside Australia
            </small> */}
          </form>
        </Modal>
      </Show>
    </Show>
  );
};

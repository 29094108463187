import cn from 'classnames';
import { addDays, eachMinuteOfInterval, format, getMinutes, setMinutes, startOfMinute } from 'date-fns';
import { For, ParentComponent, createEffect, createSignal, mergeProps, onMount } from 'solid-js';
import { ScrollContainer } from '../ScrollContainer';
import { themeClass } from '../ThemeProvider';
import styles from './TimePicker.module.scss';

interface Props {
  onChange?: (time: Date | string) => void;
  onMount?: (time: Date | string, timeFormat: string) => void;
  show24hr?: boolean;
  interval?: number;
  value?: Date;
  returnDateObject?: boolean;
  class?: string;
  theme?: 'light' | 'dark';
}

export const TimePicker: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      interval: 15,
      value: new Date(),
    },
    passedProps,
  );

  const getActiveTime = (time: Date) => {
    const roundedMins = Math.floor(getMinutes(time) / props.interval) * props.interval;
    const newTime = setMinutes(startOfMinute(time), roundedMins);
    if (!props.show24hr) {
      return format(newTime, 'HH:mma');
    }
    return format(newTime, 'HH:mm');
  };

  let listRef: HTMLDivElement | undefined;
  let itemsRef: HTMLDivElement[] = [];
  let itemsRefIndex = -1;
  const options: string[] = [];
  const [activeTime, setActiveTime] = createSignal(getActiveTime(props.value));
  const timeFormat = props.show24hr ? 'HH:mm' : 'hh:mm a';
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const minutes = eachMinuteOfInterval({
    start: now,
    end: addDays(now, 1),
  });

  onMount(() => {
    scrollToActiveTime();
    props.onMount && props.onMount(activeTime(), timeFormat);
  });

  createEffect(prevValue => {
    if (prevValue !== props.value && prevValue) {
      setActiveTime(getActiveTime(props.value));
      scrollToActiveTime();
    }
    return props.value;
  });

  const scrollToActiveTime = () => {
    if (listRef) {
      const activeItemRefIndex = options.findIndex(item => item === getActiveTime(props.value));
      const activeItem = itemsRef[activeItemRefIndex];
      if (activeItem) {
        const activePosition = activeItem.offsetTop;
        const listHeight = listRef.clientHeight;
        const activeHeight = activeItem.clientHeight;
        setTimeout(() => {
          if (listRef) {
            listRef.scrollTop = activePosition - listHeight / 2 + activeHeight / 2;
          }
        });
      }
    }
  };

  const selectTime = (time: Date) => {
    setActiveTime(getActiveTime(time));
    props.onChange && props.onChange(props.returnDateObject ? time : format(time, timeFormat));
  };

  return (
    <div
      class={themeClass(props.theme ? styles[props.theme] : styles.dark, styles.container, {
        [props.class || '']: props.class ? true : false,
      })}
    >
      <div class={styles.header}>Time</div>
      <ScrollContainer ref={listRef} class={styles.list}>
        <For each={minutes}>
          {time => {
            const mins = Number(format(time, 'mm'));
            if (mins % props.interval === 0) {
              const formattedTime = format(time, timeFormat);
              itemsRefIndex++;
              options[itemsRefIndex] = formattedTime;
              return (
                <div
                  class={cn(styles.item, {
                    [styles.active]: activeTime() === formattedTime,
                  })}
                  ref={itemsRef[itemsRefIndex]}
                  onClick={selectTime.bind(null, time)}
                >
                  <span>{formattedTime}</span>
                </div>
              );
            }
            return null;
          }}
        </For>
      </ScrollContainer>
    </div>
  );
};

import { ParentComponent } from 'solid-js';
import styles from './CenterContainer.module.scss';
import cn from 'classnames';

interface Props {
  class?: string;
}

export const CenterContainer: ParentComponent<Props> = props => {
  return (
    <div class={cn(styles.container, { [String(props.class)]: props.class ? true : false })}>{props.children}</div>
  );
};

import mapboxgl from 'mapbox-gl';
import {
  Ride,
  RidesItem,
  RideListRide,
  RideOrRouteSegment,
  RideSegment,
  RideOrRouteOrSegment,
  WeatherHour,
  WeatherHourly,
} from '../types';

export const isRide = (ride: RideOrRouteOrSegment): ride is Ride => {
  return String((ride as Ride).type).toLowerCase() === 'ride';
};

export const isRideItem = (ride: RideListRide): ride is RidesItem => {
  return String(ride.type).toLowerCase() === 'ride';
};

export const isRideSegment = (segment: RideOrRouteOrSegment): segment is RideSegment => {
  return (segment as Ride).map === undefined && (segment as RideSegment).starred !== undefined;
};

export const isRideOrRouteSegment = (segment: RideOrRouteOrSegment): segment is RideOrRouteSegment => {
  return (segment as Ride).map === undefined;
};

export const isHourlyWeatherArray = (weather: WeatherHourly['data']): weather is WeatherHour[] => {
  return Array.isArray(weather);
};

export const isGeoJsonSource = (source: any): source is mapboxgl.GeoJSONSource => {
  return source.setData !== undefined;
};

export const isGeoJsonSourceRaw = (source: any): source is mapboxgl.GeoJSONSourceRaw => {
  return source.type === 'geojson' && source.data !== undefined && typeof source.data !== 'string';
};

export const isFeatureGeoJson = (geoJson: any): geoJson is GeoJSON.Feature<GeoJSON.Geometry> => {
  return geoJson.type === 'Feature' && geoJson.geometry !== undefined;
};

export const isFeatureCollectionGeoJson = (geoJson: any): geoJson is GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  return geoJson.type === 'FeatureCollection' && Array.isArray(geoJson.features);
};

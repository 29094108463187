import { ParentComponent, mergeProps } from 'solid-js';
import { useTheme } from '../ThemeProvider';

interface Props {
  direction?: 'vertical' | 'horizontal' | 'diagonal' | number;
  startColor?: string;
  endColor?: string;
}

export const GradientText: ParentComponent<Props> = passedProps => {
  const [theme] = useTheme();
  const { colors } = theme();

  const props = mergeProps(
    {
      direction: 'diagonal',
      startColor: colors.primary,
      endColor: colors.secondary,
    },
    passedProps,
  );

  let gradient = '';
  if (typeof props.direction === 'number') {
    gradient = `linear-gradient(${props.direction}deg, ${props.startColor}, ${props.endColor})`;
  } else {
    switch (props.direction) {
      case 'diagonal':
        gradient = `linear-gradient(135deg, ${props.startColor}, ${props.endColor})`;
        break;
      case 'horizontal':
        gradient = `linear-gradient(90deg, ${props.startColor}, ${props.endColor})`;
        break;
      default:
        gradient = `linear-gradient(${props.startColor}, ${props.endColor})`;
    }
  }

  return (
    <span
      style={{
        background: gradient,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      }}
    >
      {props.children}
    </span>
  );
};

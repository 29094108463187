import { Match, ParentComponent, Switch, mergeProps } from 'solid-js';
import { degToCompass } from '../../utils/shared';
import { CountUp } from '../CountUp';
import { themeClass } from '../ThemeProvider';
import { WindDirIcon } from '../WindDirIcon';
import styles from './StatItem.module.scss';

interface Props {
  label: string;
  value: number | string;
  units?: string;
  type?: 'default' | 'wind';
  warning?: boolean;
  windColor?: string;
  size?: 'small' | 'medium' | 'large';
  animate?: boolean;
}

export const StatItem: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      size: 'medium' as Props['size'],
      animate: true,
    },
    passedProps,
  );

  return (
    <div
      class={themeClass(styles.dark, styles.container, {
        [styles.wind]: props.type === 'wind',
        [styles.warning]: props.warning,
        [styles[String(props.size)]]: props.size ? true : false,
      })}
    >
      <span class={styles.label}>{props.label}</span>
      <div class={styles.value}>
        <Switch fallback={<>{props.value}</>}>
          <Match when={props.type === 'wind'}>
            <WindDirIcon
              windDir={Number(props.value)}
              color={props.windColor}
              size={props.size}
              animate={props.animate}
            />
          </Match>
          <Match when={props.type !== 'wind' && typeof props.value === 'number' && props.animate}>
            <CountUp duration={1} amount={Number(props.value)} decimalPlaces={1} format={false} />
          </Match>
        </Switch>
      </div>
      <span class={styles.units}>
        {props.type === 'wind' && !props.units && <>{degToCompass(Number(props.value))}</>}
        {props.units && <>{props.units}</>}
      </span>
    </div>
  );
};

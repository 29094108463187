import { animate } from 'motion';
import { ParentComponent, createEffect, mergeProps, onCleanup } from 'solid-js';
import { roundToXDecimalPlaces } from '../../utils';
import { InView } from '../InView';

interface Props {
  amount: number;
  decimalPlaces?: number;
  duration?: number;
  format?: boolean;
  prefix?: string;
  suffix?: string;
  class?: string;
}

export const CountUp: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      amount: 0,
      decimalPlaces: 0,
      duration: 2,
      format: true,
      prefix: '',
      suffix: '',
    },
    passedProps,
  );

  let ref: HTMLDivElement | undefined;
  let timeout = 0;

  // Ensure the count value updates when the amount prop changes
  createEffect((prevAmount: number) => {
    if (ref && props.amount !== prevAmount) {
      const amount = props.amount.toFixed(props.decimalPlaces);
      ref.innerHTML = getValue(amount);
    }
    return props.amount;
  }, props.amount);

  onCleanup(() => {
    clearTimeout(timeout);
  });

  const onSeen = () => {
    if (ref && props.amount !== 0) {
      animate(
        progress => {
          const rawAmount = progress * props.amount;
          let amount = String(rawAmount);
          if (props.format) {
            amount = format(Math.round(rawAmount));
          } else {
            amount = rawAmount.toFixed(props.decimalPlaces);
          }
          if (ref) {
            ref.innerHTML = getValue(amount);
          }
        },
        {
          duration: props.duration,
          easing: 'ease-out',
        },
      ).finished.then(() => {
        if (ref && !props.format) {
          ref.innerHTML = roundToXDecimalPlaces(props.amount, props.decimalPlaces).toFixed(props.decimalPlaces);
        }
      });
    }
  };

  const format = (num: number) => {
    return new Intl.NumberFormat('en-US').format(num);
  };

  const getValue = (amount: string | number) => {
    return `${props.prefix}${amount}${props.suffix}`;
  };

  return (
    <InView onSeen={onSeen}>
      <div ref={ref} class={props.class}>
        {getValue(0)}
      </div>
    </InView>
  );
};

import cn from 'classnames';
import { JSX, ParentComponent, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import styleVars from '../../styles/misc.module.scss';
import { HoverBurst } from '../HoverBurst';
import { Icon } from '../Icon';
import { Icons } from '../Icon/Icon.types';
import { Tab, Tabs } from '../Tabs';
import { themeClass } from '../ThemeProvider';
import styles from './BottomDrawer.module.scss';

interface Props {
  visibleContent?: JSX.Element;
  initialActiveTab?: number;
  activeTab?: number;
  onOpen?: (pos: number) => void;
  onAfterOpen?: (pos: number) => void;
  onClose?: (pos: number) => void;
  onAfterClose?: (pos: number) => void;
  onHide?: (pos: number) => void;
  onAfterHide?: (pos: number) => void;
  onTabChange?: (index: number) => void;
  open?: boolean;
  showInfo?: boolean;
  ready?: boolean;
  primaryTabTitle?: string;
  primaryTabIcon?: Icons;
  secondaryTab?: JSX.Element;
  secondaryTabTitle?: string;
  secondaryTabIcon?: Icons;
  hideSecondaryTab?: boolean;
  tertiaryTab?: JSX.Element;
  tertiaryTabTitle?: string;
  tertiaryTabIcon?: Icons;
  hideTertiaryTab?: boolean;
  ignoreDragEventsOn?: string[];
  closable?: boolean;
}

type Direction = 'up' | 'down';

export const BottomDrawer: ParentComponent<Props> = props => {
  const distanceToActivate = 25;
  const scaleWhenDragging = 0.95;
  const amountOfContentVisible = props.children ? 22 : 0;
  const amountOfOverDrag = 50;
  const shouldRenderTabs = props.secondaryTab || props.tertiaryTab;
  const tabHeight = shouldRenderTabs ? parseInt(styleVars.tabHeightSlim) : 0;

  const [dragging, setDragging] = createSignal(false);
  const [ignoreDrag, setIgnoreDrag] = createSignal(false);
  const [tooTallForScreen, setTooTallForScreen] = createSignal(false);
  const [activeTab, setActiveTab] = createSignal(props.initialActiveTab || props.activeTab || 0);
  const [initialActiveTab, setInitialActiveTab] = createSignal(props.initialActiveTab || 0);
  const [open, setOpen] = createSignal(props.open);
  const [position, setPosition] = createSignal(0);
  const [canDrag, setCanDrag] = createSignal(false);
  const [direction, setDirection] = createSignal<Direction>('up');
  const [aboveFoldRefHeight, setAboveFoldRefHeight] = createSignal(0);
  const [aboveFoldHeight, setAboveFoldHeight] = createSignal(0);
  const [dragHeight, setDragHeight] = createSignal(0);
  const [ready, setReady] = createSignal(props.showInfo || false);
  const [secondaryTabHeight, setSecondaryTabHeight] = createSignal(0);
  const [startFingerPosition, setStartFingerPosition] = createSignal(0);
  const [startDragPosition, setStartDragPosition] = createSignal(0);
  const [distanceDragged, setDistanceDragged] = createSignal(0);

  let toggleRef: HTMLDivElement | undefined;
  let dragRef: HTMLDivElement | undefined;
  let drawerRef: HTMLDivElement | undefined;
  let aboveFoldRef: HTMLDivElement | undefined;
  let readyTimeout: number = 0;
  let resizeTimeout: number = 0;
  let posChangeCallbackTimeout: number = 0;

  const init = () => {
    calculateRefHeights();
    if (props.ready === undefined) {
      readyTimeout = window.setTimeout(() => {
        setReady(true);
      }, 1000);
    }
    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', onDragEnd);
    window.addEventListener('touchmove', onDrag);
    window.addEventListener('touchend', onDragEnd);
    window.addEventListener('resize', onResize);
  };

  const onTabsReady = () => {
    init();
    setInitialActiveTab(props.initialActiveTab || 0);
  };

  onMount(() => {
    if (!shouldRenderTabs) {
      init();
    }
  });

  onCleanup(() => {
    clearTimeout(readyTimeout);
    clearTimeout(resizeTimeout);
    clearTimeout(posChangeCallbackTimeout);
    window.removeEventListener('mousemove', onDrag);
    window.removeEventListener('mouseup', onDragEnd);
    window.removeEventListener('touchmove', onDrag);
    window.removeEventListener('touchend', onDragEnd);
    window.removeEventListener('resize', onResize);
  });

  createEffect(prevOpen => {
    if (prevOpen !== props.open) {
      if (props.open) {
        openDrawer();
      } else {
        closeDrawer();
      }
    }
    return props.open;
  }, props.open);

  createEffect(prevReady => {
    if (drawerRef) {
      if (ready() && !prevReady) {
        setDrawerOffset();
        // NOTE: Timeout used here has to match animation time in css file.
        setTimeout(() => {
          onDrawerReady();
        }, 400);
        props.onClose && props.onClose(aboveFoldHeight());
      } else if (prevReady && !ready()) {
        drawerRef.style.transform = `translateY(100%)`;
      }
    }
    return ready();
  }, ready());

  createEffect(() => {
    updateSecondaryTabHeight();
    return open();
  }, open());

  createEffect(prevPosition => {
    if (position() !== prevPosition) {
      positionUpdated();
    }
    return position();
  }, position());

  createEffect(prevInitActiveTab => {
    if (props.initialActiveTab !== prevInitActiveTab) {
      setInitialActiveTab(props.initialActiveTab || 0);
      setActiveTab(props.initialActiveTab || 0);
    }
    return props.initialActiveTab;
  }, props.initialActiveTab);

  createEffect(prevActiveTab => {
    if (prevActiveTab !== props.activeTab && props.activeTab !== undefined) {
      setActiveTab(props.activeTab);
    }
    return props.activeTab;
  }, props.activeTab);

  const onDrawerReady = () => {
    if (dragRef) {
      setDragHeight(dragRef.clientHeight);
      if (open()) {
        openDrawer();
      }
    }
    updateSecondaryTabHeight();
  };

  const calculateRefHeights = () => {
    if (aboveFoldRef && dragRef) {
      setAboveFoldHeight(getAboveFoldHeight());
      setAboveFoldRefHeight(aboveFoldRef.clientHeight);
      setDragHeight(dragRef.clientHeight);
    }
  };

  const setDrawerOffset = () => {
    if (drawerRef) {
      drawerRef.style.transform = `translateY(${drawerRef.clientHeight - aboveFoldHeight()}px)`;
    }
  };

  const positionUpdated = () => {
    const pos = position();
    const openPos = getOpenPosition();
    const closePos = 0;
    const hiddenPos = getHiddenPosition();
    // Callbacks that happen instantly
    if (pos === openPos) {
      props.onOpen && props.onOpen(Math.abs(openPos - aboveFoldHeight()));
    } else if (pos === closePos) {
      props.onClose && props.onClose(Math.abs(closePos - aboveFoldHeight()));
    } else if (pos === hiddenPos) {
      props.onHide && props.onHide(Math.abs(hiddenPos - aboveFoldHeight()));
    }
    // Callbacks that happen after animation finishes
    posChangeCallbackTimeout = window.setTimeout(() => {
      if (pos === openPos) {
        props.onAfterOpen && props.onAfterOpen(Math.abs(openPos - aboveFoldHeight()));
      } else if (pos === closePos) {
        props.onAfterClose && props.onAfterClose(Math.abs(closePos - aboveFoldHeight()));
      } else if (pos === hiddenPos) {
        props.onAfterHide && props.onAfterHide(Math.abs(hiddenPos - aboveFoldHeight()));
      }
    }, 350); // NOTE: Timeout used here has to match animation time in css file plus a bit.
  };

  const updateSecondaryTabHeight = () => {
    let secondaryTabHeight = 0;
    if (!open()) {
      secondaryTabHeight = aboveFoldRefHeight() + amountOfContentVisible;
    } else if (open() && tooTallForScreen()) {
      secondaryTabHeight =
        window.innerHeight - parseInt(styleVars.headerHeight) - tabHeight - (toggleRef?.clientHeight || 0);
    } else if (open()) {
      secondaryTabHeight = dragHeight() - amountOfContentVisible - tabHeight;
    }
    setSecondaryTabHeight(secondaryTabHeight);
  };

  const toggleOpen = () => {
    if (dragRef) {
      if (!open()) {
        openDrawer();
      } else {
        closeDrawer();
      }
    }
  };

  const openDrawer = () => {
    if (dragRef) {
      const moveTo = getOpenPosition();
      setOpen(true);
      setDragging(false);
      setPosition(moveTo);
      setCanDrag(false);
      setDirection('up');
      setDistanceDragged(distanceToActivate);
      dragRef.style.transform = `translateY(${moveTo}px) scale(1)`;
    }
  };

  const closeDrawer = () => {
    if (dragRef) {
      const moveTo = 0;
      setOpen(false);
      setDragging(false);
      setPosition(moveTo);
      setCanDrag(false);
      setDirection('down');
      setDistanceDragged(distanceToActivate);
      dragRef.style.transform = `translateY(${moveTo}px) scale(1)`;
    }
  };

  const getOpenPosition = () => {
    let position = 0;
    const toggleHeight = toggleRef?.clientHeight || 0;
    const aboveFold: number = activeTab() ? aboveFoldRefHeight() : aboveFoldRef?.clientHeight || 0;
    if (dragHeight() + (toggleRef?.clientHeight || 0) + tabHeight >= window.innerHeight) {
      position =
        -(window.innerHeight - aboveFold) +
        amountOfContentVisible +
        parseInt(styleVars.headerHeight) +
        tabHeight +
        toggleHeight;
      setTooTallForScreen(true);
    } else {
      position = -(dragHeight() - aboveFold) + amountOfContentVisible + tabHeight + toggleHeight;
      setTooTallForScreen(false);
    }
    return position;
  };

  const getHiddenPosition = () => {
    const aboveFoldHeight = getAboveFoldHeight();
    return aboveFoldHeight - (toggleRef?.clientHeight || 0) - amountOfContentVisible;
  };

  const getAboveFoldHeight = () => {
    return (aboveFoldRef?.clientHeight || 0) + amountOfContentVisible + (toggleRef?.clientHeight || 0) + tabHeight;
  };

  const getFingerPosition = (e: TouchEvent | MouseEvent) => {
    const finger = e as TouchEvent;
    const mouse = e as MouseEvent;
    return finger.touches ? finger.touches[0].clientY : mouse.clientY;
  };

  const shouldIgnoreDrag = (e: TouchEvent | MouseEvent) => {
    let ignoreDrag = false;
    if (props.ignoreDragEventsOn) {
      props.ignoreDragEventsOn.forEach(ignore => {
        if (e.target instanceof HTMLElement && e.target.closest(`.${ignore}`)) {
          ignoreDrag = true;
        }
      });
    }
    return ignoreDrag;
  };

  const onResize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = window.setTimeout(() => {
      calculateRefHeights();
      setDrawerOffset();
      positionUpdated();
    }, 500);
  };

  const onDragStart = (e: TouchEvent | MouseEvent) => {
    const ignore = shouldIgnoreDrag(e);
    if (ignore) {
      setIgnoreDrag(true);
    }
    if (activeTab() || ignore) {
      return false;
    }
    setStartDragPosition(position());
    setStartFingerPosition(getFingerPosition(e));
    setCanDrag(true);
    if (dragRef) {
      dragRef.style.transform = `translateY(${position()}px) scale(1)`;
    }
  };

  const onDragEnd = (e: TouchEvent | MouseEvent) => {
    setIgnoreDrag(false);
    if (activeTab()) {
      return false;
    }
    let moveTo = startDragPosition();
    let isOpen = open();
    if (dragRef) {
      if (distanceDragged() >= distanceToActivate) {
        const closePoint = getAboveFoldHeight() / 2;
        if (position() < 0 && direction() === 'up') {
          moveTo = getOpenPosition();
          isOpen = true;
        } else if (position() > closePoint && props.closable !== false) {
          moveTo = getHiddenPosition();
        } else {
          moveTo = 0;
          isOpen = false;
        }
      }
      dragRef.style.transform = `translateY(${moveTo}px) scale(1)`;
    }
    setDragging(false);
    setCanDrag(false);
    setPosition(moveTo);
    setOpen(isOpen);
  };

  const onDrag = (e: TouchEvent | MouseEvent) => {
    if (activeTab() || ignoreDrag()) {
      return false;
    }
    e.preventDefault();
    if (canDrag() && dragRef) {
      const lastPosition = position();
      const dragAmount = startFingerPosition() - getFingerPosition(e);
      const distance = Math.abs(dragAmount);
      if (distance >= distanceToActivate) {
        let moveTo = -dragAmount + startDragPosition();
        if (moveTo < getOpenPosition() - amountOfOverDrag) {
          moveTo = getOpenPosition() - amountOfOverDrag;
        }
        let dir = direction();
        if (lastPosition - moveTo > 0) {
          dir = 'up';
        } else if (lastPosition - moveTo < 0) {
          dir = 'down';
        }
        dragRef.style.transform = `translateY(${moveTo}px) scale(${scaleWhenDragging})`;
        setDirection(dir);
        setPosition(moveTo);
        setDistanceDragged(distance);
        setDragging(true);
      }
    }
  };

  const onTabChange = (index: number) => {
    setActiveTab(index);
    updateSecondaryTabHeight();
    props.onTabChange && props.onTabChange(index);
  };

  const renderArrow = () => {
    if (dragging()) {
      if (direction() === 'up') {
        return <Icon type="IconChevronDown" size="medium" />;
      } else {
        return <Icon type="IconChevronUp" size="medium" />;
      }
    } else {
      if (open()) {
        return <Icon type="IconChevronDown" size="medium" />;
      } else {
        return <Icon type="IconChevronUp" size="medium" />;
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <div class={styles.aboveFold} ref={aboveFoldRef}>
          {props.visibleContent}
        </div>
        <div class={styles.belowFold}>{props.children}</div>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs
        stretch
        slim
        swipeable={false}
        navClass={styles.tabNav}
        onChange={onTabChange}
        onReady={onTabsReady}
        activeIndex={activeTab() !== undefined ? activeTab() : initialActiveTab()}
        fullHeight
      >
        <Tab title={props.primaryTabTitle || 'Info'} icon={<Icon type={props.primaryTabIcon || 'IconInfoCircle'} />}>
          {renderContent()}
        </Tab>
        <Tab
          hidden={props.hideSecondaryTab}
          title={props.secondaryTabTitle || 'More'}
          icon={<Icon type={props.secondaryTabIcon || 'IconDotsCircleHorizontal'} />}
        >
          <div class="iosScrollable" style={{ height: `${secondaryTabHeight()}px` }}>
            {props.secondaryTab}
          </div>
        </Tab>
        <Tab
          hidden={props.hideTertiaryTab}
          title={props.tertiaryTabTitle || 'More'}
          icon={<Icon type={props.tertiaryTabIcon || 'IconDotsCircleHorizontal'} />}
        >
          <div class="iosScrollable" style={{ height: `${secondaryTabHeight()}px` }}>
            {props.tertiaryTab}
          </div>
        </Tab>
      </Tabs>
    );
  };

  return (
    <div class={themeClass(styles.dark, styles.container)}>
      <div class={styles.drawer} ref={drawerRef}>
        <div
          class={cn(styles.draggable, {
            [styles.dragging]: dragging(),
            [styles.tooTallForScreen]: tooTallForScreen() && open() && !activeTab(),
          })}
          ref={dragRef}
          onTouchStart={onDragStart}
          onMouseDown={onDragStart}
        >
          <div
            class={cn(styles.toggle, { [styles.toggleHidden]: !props.children })}
            onPointerUp={toggleOpen}
            ref={toggleRef}
          >
            <HoverBurst class={styles.hoverBurst}>{renderArrow()}</HoverBurst>
          </div>
          <div class={styles.content}>
            {shouldRenderTabs && renderTabs()}
            {!shouldRenderTabs && renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

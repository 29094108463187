import { lineDistance, lineString } from '@turf/turf';
import Color from 'color';
import { detect } from 'detect-browser';
import getOperatingSystemName, { isAndroid, isIOS, isMacOS, isWindows } from 'get-os-name';
import { decode, encode } from 'google-polyline';
import { JSXElement } from 'solid-js';
import { SETTINGS } from '../settings';
import { useStore } from '../store';
import colors from '../styles/colors.module.scss';
import { GenericObject, Polyline, RideLike, RideOrRoute, UserSettings, Vec2 } from '../types';
import * as shared from './shared';
import { storage } from './storage';

let locationErrorTimeoutID = 0;
const locationErrorModalTimeout = 2000;
const browser = detect();

export const safeString = (string: string) => {
  return string.toLowerCase().replace(/[^a-zA-Z0-9_]+/g, '-');
};

export const niceString = (string: string) => {
  return string.toLowerCase().replace(/[^a-zA-Z0-9]+/g, ' ');
};

export const sanitizeString = (string: string) => {
  return string.replace(/[^a-zA-Z0-9_-]+/g, '');
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const arrayUnique = (array: any[]) => {
  return [...new Set(array)];
};

export const clamp = (number: number, min: number, max: number) => {
  return Math.max(min, Math.min(number, max));
};

export const roundToNearest = (number: number, nearest: number) => {
  const decimals = String(nearest).split('.')[1]?.length || 0;
  return Number((Math.round(number / nearest) * nearest).toFixed(decimals));
};

export const objectUpdated = (newObject?: GenericObject | any[], oldObject?: GenericObject | any[]) => {
  return JSON.stringify(newObject) !== JSON.stringify(oldObject);
};

export const getCurrentPosition = (): Promise<{ lat: number; lng: number }> => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = position.coords;
          resolve({
            lng: pos.longitude,
            lat: pos.latitude,
          });
        },
        e => {
          const { updateStore } = useStore();
          clearTimeout(locationErrorTimeoutID);
          // Location unavailable is normally an OS level block
          if (e.code === e.POSITION_UNAVAILABLE) {
            locationErrorTimeoutID = window.setTimeout(() => {
              updateStore.osGeolocationError(true);
            }, locationErrorModalTimeout);
            // Permission denied is normally a browser level block
          } else if (e.code === e.PERMISSION_DENIED) {
            const errorNo = storage.getItem('location_error_no');
            // https://support.google.com/chrome/answer/114662?sjid=10773281491121379968-AP
            // new flag for geolocation browser error here
            locationErrorTimeoutID = window.setTimeout(() => {
              updateStore.browserGeolocationError(true);
            }, locationErrorModalTimeout);
            // NOTE: IOS returns error PERMISSION DENIED for all errors so we need to track the number of errors
            // here and assume that maybe ist an os level block if we get 3 in a row
            if (isIosDevice()) {
              const newErrorNo = errorNo ? parseInt(errorNo) + 1 : 1;
              storage.setItem('location_error_no', String(newErrorNo));
              if (newErrorNo >= 3) {
                updateStore.browserGeolocationError(false);
                storage.removeItem('location_error_no');
                locationErrorTimeoutID = window.setTimeout(() => {
                  updateStore.osGeolocationError(true);
                }, locationErrorModalTimeout);
              }
            }
          }
          const error = `Unable to fetch current position, ${e.message || 'Geolocation blocked'}.`;
          reject(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
      );
    } else {
      const error = 'Sorry, Geolocation is not available on this device.';
      reject(error);
    }
  });
};

export const rgbToHex = (color: [number, number, number]) => {
  const [r, g, b] = color;
  const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  };
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
};

export const randomBetween = (min: number, max: number, decimalPlaces = 0) => {
  if (decimalPlaces === 0) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  } else {
    return parseFloat(Math.min(min + Math.random() * (max - min), max).toFixed(decimalPlaces));
  }
};

export const roundToXDecimalPlaces = (number: number, decimalPlaces = 1) => {
  return parseFloat(number.toFixed(decimalPlaces));
};

export const getDistanceBetween = (point1: Vec2, point2: Vec2) => {
  return Math.hypot(point2[0] - point1[0], point2[1] - point1[1]);
};

export const decodePolylineForMapbox = (encodedLine: string): Polyline => {
  const polyline = decode(encodedLine);
  return flipPolylineForMapbox(polyline);
};

const flipPolylineForMapbox = (polyline: Polyline): Polyline => {
  return polyline.map(point => {
    return [point[1], point[0]];
  });
};

export const reversePolyline = (line: string) => {
  const polyline = decode(line);
  const reversed = polyline.reverse();
  return encode(reversed);
};

export const secondsToNiceTime = (seconds: number, includeSeconds = false) => {
  const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;
  if (includeSeconds) {
    return [hours, minutes, seconds % 60].map(format).join(':');
  } else {
    return [hours, minutes].map(format).join(':');
  }
};

export const metersPerSecondToKph = (mps: number) => {
  return mps * 3.6;
};

export const kphToMetersPerSecond = (kph: number) => {
  return kph / 3.6;
};

export const kjToCal = (kj: number) => {
  return Math.round(kj / 4.184);
};

// TODO: type any
export const isDescendant = (parent: any, child: any) => {
  if (!child || !parent || !child.parentNode) {
    return false;
  }
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const isInstalled = () => {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any).standalone ||
    window.location.search.includes('installed=1')
  );
};

export const solidComponentToHTMLElement = (JSX: JSXElement) => {
  if (typeof JSX === 'function') {
    const renderer = JSX as any as () => HTMLElement;
    return JSX ? renderer() : undefined;
  }
  return JSX as HTMLElement;
};

export const getWindHardnessColor = (windSpeed: number) => {
  const easy = Color(colors.tertiary);
  const hard = Color(colors.primary);
  const range = SETTINGS.HARDNESS_WIND_GETS_HARD_AT - SETTINGS.HARDNESS_WIND_GETS_EASY_AT;
  const speed =
    Math.min(Math.max(SETTINGS.HARDNESS_WIND_GETS_EASY_AT, windSpeed), SETTINGS.HARDNESS_WIND_GETS_HARD_AT) -
    SETTINGS.HARDNESS_WIND_GETS_EASY_AT;
  const hardness = (Math.min(100, (speed / range) * 100) / 100).toPrecision(2);
  const color = easy.mix(hard, parseFloat(hardness));
  return color.hex();
};

export const getUserPrefUnit = (type: keyof UserSettings['unitPrefs']) => {
  const { store } = useStore();
  let settings = store().settings.unitPrefs;
  if (isSharedOrEmbeddedRide()) {
    settings = store().sharedUnitPrefs;
  }
  return shared.getUserPrefUnit(type, settings);
};

export const convertToUserPref = (number: number, type: keyof UserSettings['unitPrefs']) => {
  const { store } = useStore();
  let settings = store().settings.unitPrefs;
  if (isSharedOrEmbeddedRide()) {
    settings = store().sharedUnitPrefs;
  }
  return shared.convertToUserPref(number, type, settings);
};

export const getNiceDistance = (distance: number) => {
  return convertToUserPref(shared.metersToKm(distance), 'distance');
};

export const calculateCalories = (sex: 'M' | 'F', speed: number, windSpeed: number, hours: number) => {
  const riderWeight =
    sex === 'M' ? SETTINGS.CALORIE_CALCULATION_AVERAGE_MALE_WEIGHT : SETTINGS.CALORIE_CALCULATION_AVERAGE_FEMALE_WEIGHT;
  const weight =
    riderWeight + SETTINGS.CALORIE_CALCULATION_AVERAGE_BIKE_WEIGHT - SETTINGS.CALORIE_CALCULATION_REALITY_ADJUSTMENT;
  const calories =
    ((9.8 * weight * speed * (0.0053 + 1) + 0.185 * Math.pow(windSpeed, 2) * speed) * 1.16 * hours) / 100;
  return Math.round(calories);
};

export const calculateWindCalories = (calories: number, hardness = 2.5) => {
  if (isNaN(hardness)) {
    hardness = 2.5;
  }
  let percentage = (hardness * 100) / 5 / 400;
  const modifier = percentage * calories;
  return Math.round(calories + modifier);
};

export const getPolylineFromRide = (ride: RideLike | RideOrRoute) => {
  return ride.map.polyline || ride.map.summary_polyline || null;
};

export const getPolylineDistance = (polyline: Polyline) => {
  if (polyline.length > 1) {
    const flipped = flipPolylineForMapbox(polyline);
    const totalDistance = lineDistance(lineString(flipped));
    return shared.kmToMeters(totalDistance);
  }
  return 0;
};

export const isSharedOrEmbeddedRide = () => {
  return window.location.pathname.includes('/share/') || window.location.pathname.includes('/embed/');
};

export const isEmbeddedRide = () => {
  return window.location.pathname.includes('/embed/');
};

export const isSharedRide = () => {
  return window.location.pathname.includes('/share/');
};

export const isDemo = () => {
  const { store } = useStore();
  return store().stravaToken?.access_token === SETTINGS.DEMO_STRAVA_TOKEN;
};

export const isSubscribed = () => {
  const { store } = useStore();
  return store().settings.donated ? true : false;
};

export const isSafari = () => {
  if (isIosDevice()) {
    return browser?.name === 'ios';
  }
  // TODO: test on mac
  return browser?.name === 'safari';
};

export const isChrome = () => {
  if (isIosDevice()) {
    return browser?.name === 'crios';
  }
  return browser?.name === 'chrome';
};

export const getBrowser = () => {
  return browser?.name || 'chrome';
};

export const isIosDevice = () => {
  return isIOS();
};

export const isMacDevice = () => {
  return isMacOS();
};

export const isWindowsDevice = () => {
  return isWindows();
};

export const isAndroidDevice = () => {
  return isAndroid();
};

export const isMobileDevice = () => {
  return isAndroidDevice() || isIosDevice();
};

export const getOS = () => {
  return getOperatingSystemName();
};

export const isAdmin = () => {
  const { store } = useStore();
  if (SETTINGS.ADMIN_STRAVA_ID === store().stravaUserID) {
    return true;
  }
  return false;
};

export const isDevMode = () => {
  return import.meta.env.DEV;
};

export const isLiveEnvironment = () => {
  return window.location.hostname === 'headwind.app';
};

export const isDevelopEnvironment = () => {
  return window.location.hostname === 'develop.headwind.app';
};

export const isLoggedIn = () => {
  const { store } = useStore();
  return store().stravaUserID && store().stravaToken ? true : false;
};

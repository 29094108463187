import { ParentComponent } from 'solid-js';
import cn from 'classnames';
import styles from './Hamburger.module.scss';

interface Props {
  open: boolean;
}

export const Hamburger: ParentComponent<Props> = props => {
  return (
    <div
      class={cn(styles.hamburger, {
        [styles.open]: props.open,
      })}
    >
      <span class={styles.line}></span>
      <span class={styles.line}></span>
      <span class={styles.line}></span>
    </div>
  );
};

import { Accessor, createSignal } from 'solid-js';
import { SETTINGS } from '../settings';
import { HeadwindUserSettings, StravaAccessToken } from '../types';
import { storage } from '../utils';

export enum Tabs {
  Rides,
  Starred,
  Routes,
}

interface Store {
  title: string;
  showBackButton: boolean;
  activeTab: Tabs;
  scrollContainer: HTMLDivElement | undefined;
  loading: boolean;
  stravaUserID: string | null;
  stravaUserName: string | null;
  stravaUserAvatar: string | null;
  requestingStravaToken: boolean;
  stravaToken: StravaAccessToken | null;
  settings: HeadwindUserSettings;
  sharedUnitPrefs: HeadwindUserSettings['unitPrefs'];
  rideRadarControlsVisible: boolean;
  osGeolocationError: boolean;
  browserGeolocationError: boolean;
}

const stravaToken = storage.getItem('strava_token');
const stravaUserID = storage.getItem('strava_user_id');
const stravaUserName = storage.getItem('strava_user_name');
const stravaUserAvatar = storage.getItem('strava_user_avatar');
const activeTab = storage.getItem('active_tab');

const defaultUnitPrefs = {
  distance: SETTINGS.DEFAULT_SETTINGS_DISTANCE,
  rainfall: SETTINGS.DEFAULT_SETTINGS_RAINFALL,
  temperature: SETTINGS.DEFAULT_SETTINGS_TEMPERATURE,
  speed: SETTINGS.DEFAULT_SETTINGS_SPEED,
};

const defaultStore: Store = {
  title: 'HEADWIND',
  showBackButton: false,
  activeTab: activeTab !== null ? Number(activeTab) : Tabs.Rides,
  scrollContainer: undefined,
  loading: false,
  stravaUserID: stravaUserID,
  stravaUserName: stravaUserName,
  stravaUserAvatar: stravaUserAvatar,
  requestingStravaToken: false,
  stravaToken: stravaToken ? JSON.parse(stravaToken) : null,
  settings: {
    commute: {
      morning: SETTINGS.DEFAULT_SETTINGS_COMMUTE_MORNING,
      afternoon: SETTINGS.DEFAULT_SETTINGS_COMMUTE_AFTERNOON,
    },
    unitPrefs: defaultUnitPrefs,
    email: '',
    donated: 0,
    notifications: false,
  },
  sharedUnitPrefs: defaultUnitPrefs,
  rideRadarControlsVisible: false,
  osGeolocationError: false,
  browserGeolocationError: false,
} as const;

type UpdateStore = (key: keyof Store, value: any) => void;

const [store, setStore] = createSignal<Store>(defaultStore);

const update: UpdateStore = (key, value) => {
  setStore({
    ...store(),
    [key]: value,
  });
};

// TODO: Maybe make function to minimise repetition when syncing items with local storage
const updateStore = {
  title: (title: Store['title']) => update('title', title),
  showBackButton: (show: Store['showBackButton']) => update('showBackButton', show),
  activeTab: (tab: Store['activeTab']) => {
    storage.setItem('active_tab', JSON.stringify(tab));
    update('activeTab', tab);
  },
  scrollContainer: (scrollContainer: Store['scrollContainer']) => update('scrollContainer', scrollContainer),
  stravaUserID: (stravaUserID: Store['stravaUserID']) => {
    if (stravaUserID) {
      storage.setItem('strava_user_id', stravaUserID);
    } else {
      storage.removeItem('strava_user_id');
    }
    update('stravaUserID', stravaUserID);
  },
  stravaUserName: (stravaUserName: Store['stravaUserName']) => {
    if (stravaUserName) {
      storage.setItem('strava_user_name', stravaUserName);
    } else {
      storage.removeItem('strava_user_name');
    }
    update('stravaUserName', stravaUserName);
  },
  stravaUserAvatar: (stravaUserAvatar: Store['stravaUserAvatar']) => {
    if (stravaUserAvatar) {
      storage.setItem('strava_user_avatar', stravaUserAvatar);
    } else {
      storage.removeItem('strava_user_avatar');
    }
    update('stravaUserAvatar', stravaUserAvatar);
  },
  loading: (loading: Store['loading']) => update('loading', loading),
  settings: (settings: Store['settings']) => update('settings', settings),
  sharedUnitPrefs: (sharedUnitPrefs: Store['sharedUnitPrefs']) => update('sharedUnitPrefs', sharedUnitPrefs),
  requestingStravaToken: (requestingStravaToken: Store['requestingStravaToken']) =>
    update('requestingStravaToken', requestingStravaToken),
  stravaToken: (stravaToken: Store['stravaToken']) => {
    if (stravaToken) {
      storage.setItem('strava_token', JSON.stringify(stravaToken));
    } else {
      storage.removeItem('strava_token');
    }
    update('stravaToken', stravaToken);
  },
  rideRadarControlsVisible: (visible: Store['rideRadarControlsVisible']) => update('rideRadarControlsVisible', visible),
  osGeolocationError: (isError: Store['osGeolocationError']) => update('osGeolocationError', isError),
  browserGeolocationError: (isError: Store['browserGeolocationError']) => update('browserGeolocationError', isError),
  updateAll: (newStore: Partial<Store>) => setStore({ ...store(), ...newStore }),
} as const;

const resetStore = async () => {
  updateStore.updateAll({
    ...defaultStore,
    stravaToken: null,
    stravaUserID: null,
    stravaUserName: null,
    stravaUserAvatar: null,
  });
  return true;
};

export const useStore = (): {
  store: Accessor<Store>;
  updateStore: typeof updateStore;
  resetStore: () => Promise<boolean>;
} => {
  return { store, updateStore, resetStore };
};

import { Component, For } from 'solid-js';
import styles from './List.module.scss';
import { themeClass } from '../ThemeProvider';
import { Icon, Icons } from '../Icon';
import { GenericObject } from '../../types';
import cn from 'classnames';

export interface ListItem {
  icon: Icons;
  text: string;
  action: (item: ListItem) => void;
  data?: GenericObject;
  active?: boolean;
}

export interface ListProps {
  items: ListItem[];
}

export const List: Component<ListProps> = props => {
  return (
    <ul class={themeClass(styles.dark, styles.list)}>
      <For each={props.items}>
        {item => (
          <li
            class={cn({
              [styles.active]: item.active,
            })}
            onClick={item.action.bind(null, item)}
          >
            <Icon type={item.icon} size="small" />
            {item.text}
          </li>
        )}
      </For>
    </ul>
  );
};

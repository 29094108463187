/* @refresh reload */
import { Router } from '@solidjs/router';
import { render } from 'solid-js/web';
import { cleanCache } from './api/api';
import App from './App';
import { AssetsProvider, Theme, ThemeProvider, UpdateAppToast, defaultTheme } from './components';
import { SETTINGS } from './settings';
import './styles/global.scss';
import { configureAmplify, initAnalytics, initSentry, isLiveEnvironment, storage } from './utils';

// Set up sentry
initSentry();

// Set up amplify
// NOTE: currently not using the amplify SDK to query the api
configureAmplify();

// Make sure we have deleted any old cached api responses
cleanCache();

// Set up analytics
initAnalytics();

// Set up our theme
const theme: Theme = {
  ...defaultTheme,
  mode: storage.getItem('theme') === 'light' ? 'light' : 'dark',
};

render(
  () => (
    <ThemeProvider theme={theme}>
      <AssetsProvider stripeKey={isLiveEnvironment() ? SETTINGS.STRIPE_KEY_LIVE : SETTINGS.STRIPE_KEY_TEST}>
        <UpdateAppToast />
        <Router>
          <App />
        </Router>
      </AssetsProvider>
    </ThemeProvider>
  ),
  document.getElementById('root') as HTMLElement,
);

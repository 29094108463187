// import { get } from 'aws-amplify/api';
import { differenceInHours } from 'date-fns';
import { showToast } from '../components';
import { SETTINGS } from '../settings';
import { useStore } from '../store';
import { GenericObject } from '../types';
import { getApiBasePath } from '../utils';
import { logout } from './strava';

export interface ApiResponse extends GenericObject {
  success: boolean;
  data?: any;
  error?: string;
  served_from?: string;
}

export type ApiCallback = (resp: ApiResponse) => void;

export const apiRequest = async (endpoint: string, callback: ApiCallback, skipCache = false) => {
  // TODO: possibly fix amplify query credentials
  // try {
  //   const respOperation = get({ apiName: SETTINGS.DYNAMODB_API_NAME, path });
  //   const resp = await respOperation.response;
  //   console.log(resp);
  //   return {
  //     success: true,
  //   };
  // } catch (e) {
  //   console.error(e);
  //   return {
  //     success: false,
  //   };
  // }
  const { updateStore } = useStore();
  updateStore.loading(true);
  const apiBasePath = getApiBasePath();
  const path = `${apiBasePath}${endpoint}`;
  let cache: Cache | undefined;
  try {
    if (!skipCache) {
      cache = await caches.open(SETTINGS.API_CACHE_NAME);
      const cachedResp = await cache.match(path);
      if (cachedResp && cachedResp.ok) {
        const json = await cachedResp.json();
        callback({
          data: json,
          success: true,
          served_from: 'Browser Cache',
        });
      }
    }
    request(path, callback, false, cache);
  } catch (e: any) {
    onError(String(e.message || e), callback);
  }
};

export const request = async (path: string, callback: ApiCallback, data?: boolean | GenericObject, cache?: Cache) => {
  const { updateStore } = useStore();
  updateStore.loading(true);
  try {
    const resp = await fetch(path, {
      method: data ? 'POST' : 'GET',
      body: typeof data === 'object' ? JSON.stringify(data) : undefined,
    });
    if (resp.ok) {
      // If caching then clone the resp ready for caching if there are no errors
      // Need to clone here as cant clone after calling resp.json().
      let clonedResp = undefined;
      if (cache) {
        clonedResp = resp.clone();
      }
      const json = await resp.json();
      updateStore.loading(false);
      // Strava and Visual crossing api will store errors in the .error key of the resp json object
      if (json.error) {
        let error = 'Unknown error';
        if (typeof json.error === 'string') {
          // Strava error
          error = json.error;
          // Visual crossing error
        } else if (json.error.type && json.error.message && json.error.message.includes('weather')) {
          error = `Weather API error. ${json.error.type}`;
        }
        onError(`Sorry there was a problem fetching the requested data. Error: ${error}.`, callback);
      } else {
        // If we have a cloned resp and cache then cache
        clonedResp && cache && cacheResponse(cache, path, clonedResp);
        callback({
          data: json,
          success: true,
          served_from: json.served_from || 'API',
        });
      }
    } else {
      onError(`Sorry there was a problem fetching the requested data. Error code: ${resp.status}.`, callback);
    }
  } catch (e: any) {
    onError(`Sorry there was a problem fetching the requested data. Error: ${e.message || String(e)}.`, callback);
  }
};

const onError = (error: string, callback: ApiCallback) => {
  const { updateStore } = useStore();
  updateStore.loading(false);
  showToast(error, 'error');
  callback({
    error,
    success: false,
  });
  // Log the user out if any auth errors occur.
  if (error.toLowerCase().includes('authorization')) {
    logout();
  }
};

const cacheResponse = async (cache: Cache, path: string, response: GenericObject) => {
  if (response.ok) {
    const resp = response.clone();
    const headers = new Headers(resp.headers);
    headers.append('cached_time', String(Date.now()));
    const data = new Response(resp.body, {
      status: resp.status,
      statusText: resp.statusText,
      headers,
    });
    await cache.put(path, data);
  }
};

export const cleanCache = async () => {
  const cache = await caches.open(SETTINGS.API_CACHE_NAME);
  const keys = await cache.keys();
  keys.forEach(async key => {
    const resp = await cache.match(key.url);
    if (resp) {
      const cachedTime = resp.headers.get('cached_time');
      const hoursBeenInCache = differenceInHours(Date.now(), Number(cachedTime));
      if (hoursBeenInCache > SETTINGS.API_CACHE_EXPIRES_AFTER_HOURS) {
        await cache.delete(key.url);
      }
    }
  });
};

export const deleteCache = async () => {
  const success = await caches.delete(SETTINGS.API_CACHE_NAME);
  return success;
};

// NOTE: uncomment this to create the static tabler icon type array
// import * as icons from '@tabler/icons-solidjs';
// const TablerIconTypes = Object.keys(icons).filter(key => key !== 'createReactComponent');
// console.log(TablerIconTypes);

export const WeatherIconTypes = [
  'clear-day',
  'clear-night',
  'cloud-1-day',
  'cloud-1-night',
  'cloud-2-day',
  'cloud-2-night',
  'cloud-3-day',
  'cloud-3-night',
  'cloud-4-day',
  'cloud-4-night',
  'rain-1-day',
  'rain-1-night',
  'rain-2-day',
  'rain-2-night',
  'rain-3-day',
  'rain-3-night',
  'rain-4-day',
  'rain-4-night',
  'snow-1-day',
  'snow-1-night',
  'snow-2-day',
  'snow-2-night',
  'snow-3-day',
  'snow-3-night',
  'snow-4-day',
  'snow-4-night',
  'storm',
] as const;

export const StringIconTypes = ['iosAddToHome'] as const;

export const TextIconTypes = ['now', 'then'] as const;

const TablerIconTypes = [
  'Icon123',
  'Icon12Hours',
  'Icon24Hours',
  'Icon2fa',
  'Icon360',
  'Icon360View',
  'Icon3dCubeSphere',
  'Icon3dCubeSphereOff',
  'Icon3dRotate',
  'IconAB',
  'IconAB2',
  'IconABOff',
  'IconAbacus',
  'IconAbacusOff',
  'IconAbc',
  'IconAccessPoint',
  'IconAccessPointOff',
  'IconAccessible',
  'IconAccessibleOff',
  'IconAccessibleOffFilled',
  'IconActivity',
  'IconActivityHeartbeat',
  'IconAd',
  'IconAd2',
  'IconAdCircle',
  'IconAdCircleFilled',
  'IconAdCircleOff',
  'IconAdFilled',
  'IconAdOff',
  'IconAddressBook',
  'IconAddressBookOff',
  'IconAdjustments',
  'IconAdjustmentsAlt',
  'IconAdjustmentsBolt',
  'IconAdjustmentsCancel',
  'IconAdjustmentsCheck',
  'IconAdjustmentsCode',
  'IconAdjustmentsCog',
  'IconAdjustmentsDollar',
  'IconAdjustmentsDown',
  'IconAdjustmentsExclamation',
  'IconAdjustmentsFilled',
  'IconAdjustmentsHeart',
  'IconAdjustmentsHorizontal',
  'IconAdjustmentsMinus',
  'IconAdjustmentsOff',
  'IconAdjustmentsPause',
  'IconAdjustmentsPin',
  'IconAdjustmentsPlus',
  'IconAdjustmentsQuestion',
  'IconAdjustmentsSearch',
  'IconAdjustmentsShare',
  'IconAdjustmentsStar',
  'IconAdjustmentsUp',
  'IconAdjustmentsX',
  'IconAerialLift',
  'IconAffiliate',
  'IconAffiliateFilled',
  'IconAirBalloon',
  'IconAirConditioning',
  'IconAirConditioningDisabled',
  'IconAirTrafficControl',
  'IconAlarm',
  'IconAlarmFilled',
  'IconAlarmMinus',
  'IconAlarmMinusFilled',
  'IconAlarmOff',
  'IconAlarmPlus',
  'IconAlarmPlusFilled',
  'IconAlarmSnooze',
  'IconAlarmSnoozeFilled',
  'IconAlbum',
  'IconAlbumOff',
  'IconAlertCircle',
  'IconAlertCircleFilled',
  'IconAlertHexagon',
  'IconAlertHexagonFilled',
  'IconAlertOctagon',
  'IconAlertOctagonFilled',
  'IconAlertSmall',
  'IconAlertSquare',
  'IconAlertSquareFilled',
  'IconAlertSquareRounded',
  'IconAlertSquareRoundedFilled',
  'IconAlertTriangle',
  'IconAlertTriangleFilled',
  'IconAlien',
  'IconAlienFilled',
  'IconAlignBoxBottomCenter',
  'IconAlignBoxBottomCenterFilled',
  'IconAlignBoxBottomLeft',
  'IconAlignBoxBottomLeftFilled',
  'IconAlignBoxBottomRight',
  'IconAlignBoxBottomRightFilled',
  'IconAlignBoxCenterBottom',
  'IconAlignBoxCenterMiddle',
  'IconAlignBoxCenterMiddleFilled',
  'IconAlignBoxCenterStretch',
  'IconAlignBoxCenterTop',
  'IconAlignBoxLeftBottom',
  'IconAlignBoxLeftBottomFilled',
  'IconAlignBoxLeftMiddle',
  'IconAlignBoxLeftMiddleFilled',
  'IconAlignBoxLeftStretch',
  'IconAlignBoxLeftTop',
  'IconAlignBoxLeftTopFilled',
  'IconAlignBoxRightBottom',
  'IconAlignBoxRightBottomFilled',
  'IconAlignBoxRightMiddle',
  'IconAlignBoxRightMiddleFilled',
  'IconAlignBoxRightStretch',
  'IconAlignBoxRightTop',
  'IconAlignBoxRightTopFilled',
  'IconAlignBoxTopCenter',
  'IconAlignBoxTopCenterFilled',
  'IconAlignBoxTopLeft',
  'IconAlignBoxTopLeftFilled',
  'IconAlignBoxTopRight',
  'IconAlignBoxTopRightFilled',
  'IconAlignCenter',
  'IconAlignJustified',
  'IconAlignLeft',
  'IconAlignRight',
  'IconAlpha',
  'IconAlphabetCyrillic',
  'IconAlphabetGreek',
  'IconAlphabetLatin',
  'IconAlt',
  'IconAmbulance',
  'IconAmpersand',
  'IconAnalyze',
  'IconAnalyzeFilled',
  'IconAnalyzeOff',
  'IconAnchor',
  'IconAnchorOff',
  'IconAngle',
  'IconAnkh',
  'IconAntenna',
  'IconAntennaBars1',
  'IconAntennaBars2',
  'IconAntennaBars3',
  'IconAntennaBars4',
  'IconAntennaBars5',
  'IconAntennaBarsOff',
  'IconAntennaOff',
  'IconAperture',
  'IconApertureOff',
  'IconApi',
  'IconApiApp',
  'IconApiAppOff',
  'IconApiOff',
  'IconAppWindow',
  'IconAppWindowFilled',
  'IconApple',
  'IconApps',
  'IconAppsFilled',
  'IconAppsOff',
  'IconArcheryArrow',
  'IconArchive',
  'IconArchiveFilled',
  'IconArchiveOff',
  'IconArmchair',
  'IconArmchair2',
  'IconArmchair2Off',
  'IconArmchairOff',
  'IconArrowAutofitContent',
  'IconArrowAutofitContentFilled',
  'IconArrowAutofitDown',
  'IconArrowAutofitHeight',
  'IconArrowAutofitLeft',
  'IconArrowAutofitRight',
  'IconArrowAutofitUp',
  'IconArrowAutofitWidth',
  'IconArrowBack',
  'IconArrowBackUp',
  'IconArrowBackUpDouble',
  'IconArrowBadgeDown',
  'IconArrowBadgeDownFilled',
  'IconArrowBadgeLeft',
  'IconArrowBadgeLeftFilled',
  'IconArrowBadgeRight',
  'IconArrowBadgeRightFilled',
  'IconArrowBadgeUp',
  'IconArrowBadgeUpFilled',
  'IconArrowBarBoth',
  'IconArrowBarDown',
  'IconArrowBarLeft',
  'IconArrowBarRight',
  'IconArrowBarToDown',
  'IconArrowBarToLeft',
  'IconArrowBarToRight',
  'IconArrowBarToUp',
  'IconArrowBarUp',
  'IconArrowBearLeft',
  'IconArrowBearLeft2',
  'IconArrowBearRight',
  'IconArrowBearRight2',
  'IconArrowBigDown',
  'IconArrowBigDownFilled',
  'IconArrowBigDownLine',
  'IconArrowBigDownLineFilled',
  'IconArrowBigDownLines',
  'IconArrowBigDownLinesFilled',
  'IconArrowBigLeft',
  'IconArrowBigLeftFilled',
  'IconArrowBigLeftLine',
  'IconArrowBigLeftLineFilled',
  'IconArrowBigLeftLines',
  'IconArrowBigLeftLinesFilled',
  'IconArrowBigRight',
  'IconArrowBigRightFilled',
  'IconArrowBigRightLine',
  'IconArrowBigRightLineFilled',
  'IconArrowBigRightLines',
  'IconArrowBigRightLinesFilled',
  'IconArrowBigUp',
  'IconArrowBigUpFilled',
  'IconArrowBigUpLine',
  'IconArrowBigUpLineFilled',
  'IconArrowBigUpLines',
  'IconArrowBigUpLinesFilled',
  'IconArrowBounce',
  'IconArrowCapsule',
  'IconArrowCurveLeft',
  'IconArrowCurveRight',
  'IconArrowDown',
  'IconArrowDownBar',
  'IconArrowDownCircle',
  'IconArrowDownLeft',
  'IconArrowDownLeftCircle',
  'IconArrowDownRhombus',
  'IconArrowDownRight',
  'IconArrowDownRightCircle',
  'IconArrowDownSquare',
  'IconArrowDownTail',
  'IconArrowElbowLeft',
  'IconArrowElbowRight',
  'IconArrowFork',
  'IconArrowForward',
  'IconArrowForwardUp',
  'IconArrowForwardUpDouble',
  'IconArrowGuide',
  'IconArrowIteration',
  'IconArrowLeft',
  'IconArrowLeftBar',
  'IconArrowLeftCircle',
  'IconArrowLeftRhombus',
  'IconArrowLeftRight',
  'IconArrowLeftSquare',
  'IconArrowLeftTail',
  'IconArrowLoopLeft',
  'IconArrowLoopLeft2',
  'IconArrowLoopRight',
  'IconArrowLoopRight2',
  'IconArrowMerge',
  'IconArrowMergeBoth',
  'IconArrowMergeLeft',
  'IconArrowMergeRight',
  'IconArrowMoveDown',
  'IconArrowMoveLeft',
  'IconArrowMoveRight',
  'IconArrowMoveUp',
  'IconArrowNarrowDown',
  'IconArrowNarrowLeft',
  'IconArrowNarrowRight',
  'IconArrowNarrowUp',
  'IconArrowRampLeft',
  'IconArrowRampLeft2',
  'IconArrowRampLeft3',
  'IconArrowRampRight',
  'IconArrowRampRight2',
  'IconArrowRampRight3',
  'IconArrowRight',
  'IconArrowRightBar',
  'IconArrowRightCircle',
  'IconArrowRightRhombus',
  'IconArrowRightSquare',
  'IconArrowRightTail',
  'IconArrowRotaryFirstLeft',
  'IconArrowRotaryFirstRight',
  'IconArrowRotaryLastLeft',
  'IconArrowRotaryLastRight',
  'IconArrowRotaryLeft',
  'IconArrowRotaryRight',
  'IconArrowRotaryStraight',
  'IconArrowRoundaboutLeft',
  'IconArrowRoundaboutRight',
  'IconArrowSharpTurnLeft',
  'IconArrowSharpTurnRight',
  'IconArrowUp',
  'IconArrowUpBar',
  'IconArrowUpCircle',
  'IconArrowUpLeft',
  'IconArrowUpLeftCircle',
  'IconArrowUpRhombus',
  'IconArrowUpRight',
  'IconArrowUpRightCircle',
  'IconArrowUpSquare',
  'IconArrowUpTail',
  'IconArrowWaveLeftDown',
  'IconArrowWaveLeftUp',
  'IconArrowWaveRightDown',
  'IconArrowWaveRightUp',
  'IconArrowZigZag',
  'IconArrowsCross',
  'IconArrowsDiagonal',
  'IconArrowsDiagonal2',
  'IconArrowsDiagonalMinimize',
  'IconArrowsDiagonalMinimize2',
  'IconArrowsDiff',
  'IconArrowsDoubleNeSw',
  'IconArrowsDoubleNwSe',
  'IconArrowsDoubleSeNw',
  'IconArrowsDoubleSwNe',
  'IconArrowsDown',
  'IconArrowsDownUp',
  'IconArrowsExchange',
  'IconArrowsExchange2',
  'IconArrowsHorizontal',
  'IconArrowsJoin',
  'IconArrowsJoin2',
  'IconArrowsLeft',
  'IconArrowsLeftDown',
  'IconArrowsLeftRight',
  'IconArrowsMaximize',
  'IconArrowsMinimize',
  'IconArrowsMove',
  'IconArrowsMoveHorizontal',
  'IconArrowsMoveVertical',
  'IconArrowsRandom',
  'IconArrowsRight',
  'IconArrowsRightDown',
  'IconArrowsRightLeft',
  'IconArrowsShuffle',
  'IconArrowsShuffle2',
  'IconArrowsSort',
  'IconArrowsSplit',
  'IconArrowsSplit2',
  'IconArrowsTransferDown',
  'IconArrowsTransferUp',
  'IconArrowsUp',
  'IconArrowsUpDown',
  'IconArrowsUpLeft',
  'IconArrowsUpRight',
  'IconArrowsVertical',
  'IconArtboard',
  'IconArtboardFilled',
  'IconArtboardOff',
  'IconArticle',
  'IconArticleFilledFilled',
  'IconArticleOff',
  'IconAspectRatio',
  'IconAspectRatioFilled',
  'IconAspectRatioOff',
  'IconAssembly',
  'IconAssemblyOff',
  'IconAsset',
  'IconAsterisk',
  'IconAsteriskSimple',
  'IconAt',
  'IconAtOff',
  'IconAtom',
  'IconAtom2',
  'IconAtom2Filled',
  'IconAtomOff',
  'IconAugmentedReality',
  'IconAugmentedReality2',
  'IconAugmentedRealityOff',
  'IconAward',
  'IconAwardFilled',
  'IconAwardOff',
  'IconAxe',
  'IconAxisX',
  'IconAxisY',
  'IconBabyBottle',
  'IconBabyCarriage',
  'IconBackhoe',
  'IconBackpack',
  'IconBackpackOff',
  'IconBackslash',
  'IconBackspace',
  'IconBackspaceFilled',
  'IconBadge',
  'IconBadge3d',
  'IconBadge4k',
  'IconBadge8k',
  'IconBadgeAd',
  'IconBadgeAr',
  'IconBadgeCc',
  'IconBadgeFilled',
  'IconBadgeHd',
  'IconBadgeOff',
  'IconBadgeSd',
  'IconBadgeTm',
  'IconBadgeVo',
  'IconBadgeVr',
  'IconBadgeWc',
  'IconBadges',
  'IconBadgesFilled',
  'IconBadgesOff',
  'IconBaguette',
  'IconBallAmericanFootball',
  'IconBallAmericanFootballOff',
  'IconBallBaseball',
  'IconBallBasketball',
  'IconBallBowling',
  'IconBallFootball',
  'IconBallFootballOff',
  'IconBallTennis',
  'IconBallVolleyball',
  'IconBalloon',
  'IconBalloonFilled',
  'IconBalloonOff',
  'IconBallpen',
  'IconBallpenFilled',
  'IconBallpenOff',
  'IconBan',
  'IconBandage',
  'IconBandageFilled',
  'IconBandageOff',
  'IconBarbell',
  'IconBarbellOff',
  'IconBarcode',
  'IconBarcodeOff',
  'IconBarrel',
  'IconBarrelOff',
  'IconBarrierBlock',
  'IconBarrierBlockOff',
  'IconBaseline',
  'IconBaselineDensityLarge',
  'IconBaselineDensityMedium',
  'IconBaselineDensitySmall',
  'IconBasket',
  'IconBasketBolt',
  'IconBasketCancel',
  'IconBasketCheck',
  'IconBasketCode',
  'IconBasketCog',
  'IconBasketDiscount',
  'IconBasketDollar',
  'IconBasketDown',
  'IconBasketExclamation',
  'IconBasketFilled',
  'IconBasketHeart',
  'IconBasketMinus',
  'IconBasketOff',
  'IconBasketPause',
  'IconBasketPin',
  'IconBasketPlus',
  'IconBasketQuestion',
  'IconBasketSearch',
  'IconBasketShare',
  'IconBasketStar',
  'IconBasketUp',
  'IconBasketX',
  'IconBat',
  'IconBath',
  'IconBathFilled',
  'IconBathOff',
  'IconBattery',
  'IconBattery1',
  'IconBattery1Filled',
  'IconBattery2',
  'IconBattery2Filled',
  'IconBattery3',
  'IconBattery3Filled',
  'IconBattery4',
  'IconBattery4Filled',
  'IconBatteryAutomotive',
  'IconBatteryCharging',
  'IconBatteryCharging2',
  'IconBatteryEco',
  'IconBatteryFilled',
  'IconBatteryOff',
  'IconBeach',
  'IconBeachOff',
  'IconBed',
  'IconBedFilled',
  'IconBedOff',
  'IconBeer',
  'IconBeerFilled',
  'IconBeerOff',
  'IconBell',
  'IconBellBolt',
  'IconBellCancel',
  'IconBellCheck',
  'IconBellCode',
  'IconBellCog',
  'IconBellDollar',
  'IconBellDown',
  'IconBellExclamation',
  'IconBellFilled',
  'IconBellHeart',
  'IconBellMinus',
  'IconBellMinusFilled',
  'IconBellOff',
  'IconBellPause',
  'IconBellPin',
  'IconBellPlus',
  'IconBellPlusFilled',
  'IconBellQuestion',
  'IconBellRinging',
  'IconBellRinging2',
  'IconBellRinging2Filled',
  'IconBellRingingFilled',
  'IconBellSchool',
  'IconBellSearch',
  'IconBellShare',
  'IconBellStar',
  'IconBellUp',
  'IconBellX',
  'IconBellXFilled',
  'IconBellZ',
  'IconBellZFilled',
  'IconBeta',
  'IconBible',
  'IconBike',
  'IconBikeOff',
  'IconBinary',
  'IconBinaryOff',
  'IconBinaryTree',
  'IconBinaryTree2',
  'IconBiohazard',
  'IconBiohazardOff',
  'IconBlade',
  'IconBladeFilled',
  'IconBleach',
  'IconBleachChlorine',
  'IconBleachNoChlorine',
  'IconBleachOff',
  'IconBlockquote',
  'IconBluetooth',
  'IconBluetoothConnected',
  'IconBluetoothOff',
  'IconBluetoothX',
  'IconBlur',
  'IconBlurOff',
  'IconBmp',
  'IconBold',
  'IconBoldOff',
  'IconBolt',
  'IconBoltOff',
  'IconBomb',
  'IconBombFilled',
  'IconBone',
  'IconBoneOff',
  'IconBong',
  'IconBongOff',
  'IconBook',
  'IconBook2',
  'IconBookDownload',
  'IconBookFilled',
  'IconBookOff',
  'IconBookUpload',
  'IconBookmark',
  'IconBookmarkEdit',
  'IconBookmarkFilled',
  'IconBookmarkMinus',
  'IconBookmarkOff',
  'IconBookmarkPlus',
  'IconBookmarkQuestion',
  'IconBookmarks',
  'IconBookmarksFilled',
  'IconBookmarksOff',
  'IconBooks',
  'IconBooksOff',
  'IconBorderAll',
  'IconBorderBottom',
  'IconBorderCorners',
  'IconBorderHorizontal',
  'IconBorderInner',
  'IconBorderLeft',
  'IconBorderNone',
  'IconBorderOuter',
  'IconBorderRadius',
  'IconBorderRight',
  'IconBorderSides',
  'IconBorderStyle',
  'IconBorderStyle2',
  'IconBorderTop',
  'IconBorderVertical',
  'IconBottle',
  'IconBottleFilled',
  'IconBottleOff',
  'IconBounceLeft',
  'IconBounceLeftFilled',
  'IconBounceRight',
  'IconBounceRightFilled',
  'IconBow',
  'IconBowl',
  'IconBowlFilled',
  'IconBox',
  'IconBoxAlignBottom',
  'IconBoxAlignBottomFilled',
  'IconBoxAlignBottomLeft',
  'IconBoxAlignBottomLeftFilled',
  'IconBoxAlignBottomRight',
  'IconBoxAlignBottomRightFilled',
  'IconBoxAlignLeft',
  'IconBoxAlignLeftFilled',
  'IconBoxAlignRight',
  'IconBoxAlignRightFilled',
  'IconBoxAlignTop',
  'IconBoxAlignTopFilled',
  'IconBoxAlignTopLeft',
  'IconBoxAlignTopLeftFilled',
  'IconBoxAlignTopRight',
  'IconBoxAlignTopRightFilled',
  'IconBoxMargin',
  'IconBoxModel',
  'IconBoxModel2',
  'IconBoxModel2Off',
  'IconBoxModelOff',
  'IconBoxMultiple',
  'IconBoxMultiple0',
  'IconBoxMultiple1',
  'IconBoxMultiple2',
  'IconBoxMultiple3',
  'IconBoxMultiple4',
  'IconBoxMultiple5',
  'IconBoxMultiple6',
  'IconBoxMultiple7',
  'IconBoxMultiple8',
  'IconBoxMultiple9',
  'IconBoxOff',
  'IconBoxPadding',
  'IconBoxSeam',
  'IconBraces',
  'IconBracesOff',
  'IconBrackets',
  'IconBracketsContain',
  'IconBracketsContainEnd',
  'IconBracketsContainStart',
  'IconBracketsOff',
  'IconBraille',
  'IconBrain',
  'IconBrand4chan',
  'IconBrandAbstract',
  'IconBrandAdobe',
  'IconBrandAdonisJs',
  'IconBrandAirbnb',
  'IconBrandAirtable',
  'IconBrandAlgolia',
  'IconBrandAlipay',
  'IconBrandAlpineJs',
  'IconBrandAmazon',
  'IconBrandAmd',
  'IconBrandAmigo',
  'IconBrandAmongUs',
  'IconBrandAndroid',
  'IconBrandAngular',
  'IconBrandAnsible',
  'IconBrandAo3',
  'IconBrandAppgallery',
  'IconBrandApple',
  'IconBrandAppleArcade',
  'IconBrandApplePodcast',
  'IconBrandAppstore',
  'IconBrandAsana',
  'IconBrandAws',
  'IconBrandAzure',
  'IconBrandBackbone',
  'IconBrandBadoo',
  'IconBrandBaidu',
  'IconBrandBandcamp',
  'IconBrandBandlab',
  'IconBrandBeats',
  'IconBrandBehance',
  'IconBrandBilibili',
  'IconBrandBinance',
  'IconBrandBing',
  'IconBrandBitbucket',
  'IconBrandBlackberry',
  'IconBrandBlender',
  'IconBrandBlogger',
  'IconBrandBooking',
  'IconBrandBootstrap',
  'IconBrandBulma',
  'IconBrandBumble',
  'IconBrandBunpo',
  'IconBrandCSharp',
  'IconBrandCake',
  'IconBrandCakephp',
  'IconBrandCampaignmonitor',
  'IconBrandCarbon',
  'IconBrandCashapp',
  'IconBrandChrome',
  'IconBrandCinema4d',
  'IconBrandCitymapper',
  'IconBrandCloudflare',
  'IconBrandCodecov',
  'IconBrandCodepen',
  'IconBrandCodesandbox',
  'IconBrandCohost',
  'IconBrandCoinbase',
  'IconBrandComedyCentral',
  'IconBrandCoreos',
  'IconBrandCouchdb',
  'IconBrandCouchsurfing',
  'IconBrandCpp',
  'IconBrandCraft',
  'IconBrandCrunchbase',
  'IconBrandCss3',
  'IconBrandCtemplar',
  'IconBrandCucumber',
  'IconBrandCupra',
  'IconBrandCypress',
  'IconBrandD3',
  'IconBrandDatabricks',
  'IconBrandDaysCounter',
  'IconBrandDcos',
  'IconBrandDebian',
  'IconBrandDeezer',
  'IconBrandDeliveroo',
  'IconBrandDeno',
  'IconBrandDenodo',
  'IconBrandDeviantart',
  'IconBrandDigg',
  'IconBrandDingtalk',
  'IconBrandDiscord',
  'IconBrandDiscordFilled',
  'IconBrandDisney',
  'IconBrandDisqus',
  'IconBrandDjango',
  'IconBrandDocker',
  'IconBrandDoctrine',
  'IconBrandDolbyDigital',
  'IconBrandDouban',
  'IconBrandDribbble',
  'IconBrandDribbbleFilled',
  'IconBrandDrops',
  'IconBrandDrupal',
  'IconBrandEdge',
  'IconBrandElastic',
  'IconBrandElectronicArts',
  'IconBrandEmber',
  'IconBrandEnvato',
  'IconBrandEtsy',
  'IconBrandEvernote',
  'IconBrandFacebook',
  'IconBrandFacebookFilled',
  'IconBrandFeedly',
  'IconBrandFigma',
  'IconBrandFilezilla',
  'IconBrandFinder',
  'IconBrandFirebase',
  'IconBrandFirefox',
  'IconBrandFiverr',
  'IconBrandFlickr',
  'IconBrandFlightradar24',
  'IconBrandFlipboard',
  'IconBrandFlutter',
  'IconBrandFortnite',
  'IconBrandFoursquare',
  'IconBrandFramer',
  'IconBrandFramerMotion',
  'IconBrandFunimation',
  'IconBrandGatsby',
  'IconBrandGit',
  'IconBrandGithub',
  'IconBrandGithubCopilot',
  'IconBrandGithubFilled',
  'IconBrandGitlab',
  'IconBrandGmail',
  'IconBrandGolang',
  'IconBrandGoogle',
  'IconBrandGoogleAnalytics',
  'IconBrandGoogleBigQuery',
  'IconBrandGoogleDrive',
  'IconBrandGoogleFit',
  'IconBrandGoogleHome',
  'IconBrandGoogleMaps',
  'IconBrandGoogleOne',
  'IconBrandGooglePhotos',
  'IconBrandGooglePlay',
  'IconBrandGooglePodcasts',
  'IconBrandGrammarly',
  'IconBrandGraphql',
  'IconBrandGravatar',
  'IconBrandGrindr',
  'IconBrandGuardian',
  'IconBrandGumroad',
  'IconBrandHbo',
  'IconBrandHeadlessui',
  'IconBrandHexo',
  'IconBrandHipchat',
  'IconBrandHtml5',
  'IconBrandInertia',
  'IconBrandInstagram',
  'IconBrandIntercom',
  'IconBrandItch',
  'IconBrandJavascript',
  'IconBrandJuejin',
  'IconBrandKbin',
  'IconBrandKick',
  'IconBrandKickstarter',
  'IconBrandKotlin',
  'IconBrandLaravel',
  'IconBrandLastfm',
  'IconBrandLeetcode',
  'IconBrandLetterboxd',
  'IconBrandLine',
  'IconBrandLinkedin',
  'IconBrandLinktree',
  'IconBrandLinqpad',
  'IconBrandLoom',
  'IconBrandMailgun',
  'IconBrandMantine',
  'IconBrandMastercard',
  'IconBrandMastodon',
  'IconBrandMatrix',
  'IconBrandMcdonalds',
  'IconBrandMedium',
  'IconBrandMercedes',
  'IconBrandMessenger',
  'IconBrandMeta',
  'IconBrandMinecraft',
  'IconBrandMiniprogram',
  'IconBrandMixpanel',
  'IconBrandMonday',
  'IconBrandMongodb',
  'IconBrandMyOppo',
  'IconBrandMysql',
  'IconBrandNationalGeographic',
  'IconBrandNem',
  'IconBrandNetbeans',
  'IconBrandNeteaseMusic',
  'IconBrandNetflix',
  'IconBrandNexo',
  'IconBrandNextcloud',
  'IconBrandNextjs',
  'IconBrandNodejs',
  'IconBrandNordVpn',
  'IconBrandNotion',
  'IconBrandNpm',
  'IconBrandNuxt',
  'IconBrandNytimes',
  'IconBrandOauth',
  'IconBrandOffice',
  'IconBrandOkRu',
  'IconBrandOnedrive',
  'IconBrandOnlyfans',
  'IconBrandOpenSource',
  'IconBrandOpenai',
  'IconBrandOpenvpn',
  'IconBrandOpera',
  'IconBrandPagekit',
  'IconBrandParsinta',
  'IconBrandPatreon',
  'IconBrandPaypal',
  'IconBrandPaypalFilled',
  'IconBrandPaypay',
  'IconBrandPeanut',
  'IconBrandPepsi',
  'IconBrandPhp',
  'IconBrandPicsart',
  'IconBrandPinterest',
  'IconBrandPlanetscale',
  'IconBrandPocket',
  'IconBrandPolymer',
  'IconBrandPowershell',
  'IconBrandPrisma',
  'IconBrandProducthunt',
  'IconBrandPushbullet',
  'IconBrandPushover',
  'IconBrandPython',
  'IconBrandQq',
  'IconBrandRadixUi',
  'IconBrandReact',
  'IconBrandReactNative',
  'IconBrandReason',
  'IconBrandReddit',
  'IconBrandRedhat',
  'IconBrandRedux',
  'IconBrandRevolut',
  'IconBrandRumble',
  'IconBrandRust',
  'IconBrandSafari',
  'IconBrandSamsungpass',
  'IconBrandSass',
  'IconBrandSentry',
  'IconBrandSharik',
  'IconBrandShazam',
  'IconBrandShopee',
  'IconBrandSketch',
  'IconBrandSkype',
  'IconBrandSlack',
  'IconBrandSnapchat',
  'IconBrandSnapseed',
  'IconBrandSnowflake',
  'IconBrandSocketIo',
  'IconBrandSolidjs',
  'IconBrandSoundcloud',
  'IconBrandSpacehey',
  'IconBrandSpeedtest',
  'IconBrandSpotify',
  'IconBrandStackoverflow',
  'IconBrandStackshare',
  'IconBrandSteam',
  'IconBrandStorj',
  'IconBrandStorybook',
  'IconBrandStorytel',
  'IconBrandStrava',
  'IconBrandStripe',
  'IconBrandSublimeText',
  'IconBrandSugarizer',
  'IconBrandSupabase',
  'IconBrandSuperhuman',
  'IconBrandSupernova',
  'IconBrandSurfshark',
  'IconBrandSvelte',
  'IconBrandSwift',
  'IconBrandSymfony',
  'IconBrandTabler',
  'IconBrandTailwind',
  'IconBrandTaobao',
  'IconBrandTeams',
  'IconBrandTed',
  'IconBrandTelegram',
  'IconBrandTerraform',
  'IconBrandTether',
  'IconBrandThreads',
  'IconBrandThreejs',
  'IconBrandTidal',
  'IconBrandTiktok',
  'IconBrandTiktokFilled',
  'IconBrandTinder',
  'IconBrandTopbuzz',
  'IconBrandTorchain',
  'IconBrandToyota',
  'IconBrandTrello',
  'IconBrandTripadvisor',
  'IconBrandTumblr',
  'IconBrandTwilio',
  'IconBrandTwitch',
  'IconBrandTwitter',
  'IconBrandTwitterFilled',
  'IconBrandTypescript',
  'IconBrandUber',
  'IconBrandUbuntu',
  'IconBrandUnity',
  'IconBrandUnsplash',
  'IconBrandUpwork',
  'IconBrandValorant',
  'IconBrandVercel',
  'IconBrandVimeo',
  'IconBrandVinted',
  'IconBrandVisa',
  'IconBrandVisualStudio',
  'IconBrandVite',
  'IconBrandVivaldi',
  'IconBrandVk',
  'IconBrandVlc',
  'IconBrandVolkswagen',
  'IconBrandVsco',
  'IconBrandVscode',
  'IconBrandVue',
  'IconBrandWalmart',
  'IconBrandWaze',
  'IconBrandWebflow',
  'IconBrandWechat',
  'IconBrandWeibo',
  'IconBrandWhatsapp',
  'IconBrandWikipedia',
  'IconBrandWindows',
  'IconBrandWindy',
  'IconBrandWish',
  'IconBrandWix',
  'IconBrandWordpress',
  'IconBrandX',
  'IconBrandXFilled',
  'IconBrandXamarin',
  'IconBrandXbox',
  'IconBrandXdeep',
  'IconBrandXing',
  'IconBrandYahoo',
  'IconBrandYandex',
  'IconBrandYatse',
  'IconBrandYcombinator',
  'IconBrandYoutube',
  'IconBrandYoutubeFilled',
  'IconBrandYoutubeKids',
  'IconBrandZalando',
  'IconBrandZapier',
  'IconBrandZeit',
  'IconBrandZhihu',
  'IconBrandZoom',
  'IconBrandZulip',
  'IconBrandZwift',
  'IconBread',
  'IconBreadOff',
  'IconBriefcase',
  'IconBriefcase2',
  'IconBriefcaseOff',
  'IconBrightness',
  'IconBrightness2',
  'IconBrightnessDown',
  'IconBrightnessDownFilled',
  'IconBrightnessHalf',
  'IconBrightnessOff',
  'IconBrightnessUp',
  'IconBrightnessUpFilled',
  'IconBroadcast',
  'IconBroadcastOff',
  'IconBrowser',
  'IconBrowserCheck',
  'IconBrowserOff',
  'IconBrowserPlus',
  'IconBrowserX',
  'IconBrush',
  'IconBrushOff',
  'IconBucket',
  'IconBucketDroplet',
  'IconBucketOff',
  'IconBug',
  'IconBugOff',
  'IconBuilding',
  'IconBuildingArch',
  'IconBuildingBank',
  'IconBuildingBridge',
  'IconBuildingBridge2',
  'IconBuildingBroadcastTower',
  'IconBuildingCarousel',
  'IconBuildingCastle',
  'IconBuildingChurch',
  'IconBuildingCircus',
  'IconBuildingCommunity',
  'IconBuildingCottage',
  'IconBuildingEstate',
  'IconBuildingFactory',
  'IconBuildingFactory2',
  'IconBuildingFortress',
  'IconBuildingHospital',
  'IconBuildingLighthouse',
  'IconBuildingMonument',
  'IconBuildingMosque',
  'IconBuildingPavilion',
  'IconBuildingSkyscraper',
  'IconBuildingStadium',
  'IconBuildingStore',
  'IconBuildingTunnel',
  'IconBuildingWarehouse',
  'IconBuildingWindTurbine',
  'IconBulb',
  'IconBulbFilled',
  'IconBulbOff',
  'IconBulldozer',
  'IconBus',
  'IconBusOff',
  'IconBusStop',
  'IconBusinessplan',
  'IconButterfly',
  'IconCactus',
  'IconCactusFilled',
  'IconCactusOff',
  'IconCake',
  'IconCakeOff',
  'IconCalculator',
  'IconCalculatorFilled',
  'IconCalculatorOff',
  'IconCalendar',
  'IconCalendarBolt',
  'IconCalendarCancel',
  'IconCalendarCheck',
  'IconCalendarCode',
  'IconCalendarCog',
  'IconCalendarDollar',
  'IconCalendarDown',
  'IconCalendarDue',
  'IconCalendarEvent',
  'IconCalendarExclamation',
  'IconCalendarFilled',
  'IconCalendarHeart',
  'IconCalendarMinus',
  'IconCalendarOff',
  'IconCalendarPause',
  'IconCalendarPin',
  'IconCalendarPlus',
  'IconCalendarQuestion',
  'IconCalendarRepeat',
  'IconCalendarSearch',
  'IconCalendarShare',
  'IconCalendarStar',
  'IconCalendarStats',
  'IconCalendarTime',
  'IconCalendarUp',
  'IconCalendarX',
  'IconCamera',
  'IconCameraBolt',
  'IconCameraCancel',
  'IconCameraCheck',
  'IconCameraCode',
  'IconCameraCog',
  'IconCameraDollar',
  'IconCameraDown',
  'IconCameraExclamation',
  'IconCameraFilled',
  'IconCameraHeart',
  'IconCameraMinus',
  'IconCameraOff',
  'IconCameraPause',
  'IconCameraPin',
  'IconCameraPlus',
  'IconCameraQuestion',
  'IconCameraRotate',
  'IconCameraSearch',
  'IconCameraSelfie',
  'IconCameraShare',
  'IconCameraStar',
  'IconCameraUp',
  'IconCameraX',
  'IconCamper',
  'IconCampfire',
  'IconCampfireFilled',
  'IconCandle',
  'IconCandleFilled',
  'IconCandy',
  'IconCandyOff',
  'IconCane',
  'IconCannabis',
  'IconCapsule',
  'IconCapsuleFilled',
  'IconCapsuleHorizontal',
  'IconCapsuleHorizontalFilled',
  'IconCapture',
  'IconCaptureFilled',
  'IconCaptureOff',
  'IconCar',
  'IconCarCrane',
  'IconCarCrash',
  'IconCarOff',
  'IconCarTurbine',
  'IconCaravan',
  'IconCardboards',
  'IconCardboardsOff',
  'IconCards',
  'IconCardsFilled',
  'IconCaretDown',
  'IconCaretDownFilled',
  'IconCaretLeft',
  'IconCaretLeftFilled',
  'IconCaretLeftRight',
  'IconCaretRight',
  'IconCaretRightFilled',
  'IconCaretUp',
  'IconCaretUpDown',
  'IconCaretUpFilled',
  'IconCarouselHorizontal',
  'IconCarouselHorizontalFilled',
  'IconCarouselVertical',
  'IconCarouselVerticalFilled',
  'IconCarrot',
  'IconCarrotOff',
  'IconCash',
  'IconCashBanknote',
  'IconCashBanknoteOff',
  'IconCashOff',
  'IconCast',
  'IconCastOff',
  'IconCat',
  'IconCategory',
  'IconCategory2',
  'IconCategoryFilled',
  'IconCe',
  'IconCeOff',
  'IconCell',
  'IconCellSignal1',
  'IconCellSignal2',
  'IconCellSignal3',
  'IconCellSignal4',
  'IconCellSignal5',
  'IconCellSignalOff',
  'IconCertificate',
  'IconCertificate2',
  'IconCertificate2Off',
  'IconCertificateOff',
  'IconChairDirector',
  'IconChalkboard',
  'IconChalkboardOff',
  'IconChargingPile',
  'IconChartArcs',
  'IconChartArcs3',
  'IconChartArea',
  'IconChartAreaFilled',
  'IconChartAreaLine',
  'IconChartAreaLineFilled',
  'IconChartArrows',
  'IconChartArrowsVertical',
  'IconChartBar',
  'IconChartBarOff',
  'IconChartBubble',
  'IconChartBubbleFilled',
  'IconChartCandle',
  'IconChartCandleFilled',
  'IconChartCircles',
  'IconChartDonut',
  'IconChartDonut2',
  'IconChartDonut3',
  'IconChartDonut4',
  'IconChartDonutFilled',
  'IconChartDots',
  'IconChartDots2',
  'IconChartDots3',
  'IconChartGridDots',
  'IconChartHistogram',
  'IconChartInfographic',
  'IconChartLine',
  'IconChartPie',
  'IconChartPie2',
  'IconChartPie3',
  'IconChartPie4',
  'IconChartPieFilled',
  'IconChartPieOff',
  'IconChartPpf',
  'IconChartRadar',
  'IconChartSankey',
  'IconChartTreemap',
  'IconCheck',
  'IconCheckbox',
  'IconChecklist',
  'IconChecks',
  'IconCheckupList',
  'IconCheese',
  'IconChefHat',
  'IconChefHatOff',
  'IconCherry',
  'IconCherryFilled',
  'IconChess',
  'IconChessBishop',
  'IconChessBishopFilled',
  'IconChessFilled',
  'IconChessKing',
  'IconChessKingFilled',
  'IconChessKnight',
  'IconChessKnightFilled',
  'IconChessQueen',
  'IconChessQueenFilled',
  'IconChessRook',
  'IconChessRookFilled',
  'IconChevronCompactDown',
  'IconChevronCompactLeft',
  'IconChevronCompactRight',
  'IconChevronCompactUp',
  'IconChevronDown',
  'IconChevronDownLeft',
  'IconChevronDownRight',
  'IconChevronLeft',
  'IconChevronLeftPipe',
  'IconChevronRight',
  'IconChevronRightPipe',
  'IconChevronUp',
  'IconChevronUpLeft',
  'IconChevronUpRight',
  'IconChevronsDown',
  'IconChevronsDownLeft',
  'IconChevronsDownRight',
  'IconChevronsLeft',
  'IconChevronsRight',
  'IconChevronsUp',
  'IconChevronsUpLeft',
  'IconChevronsUpRight',
  'IconChisel',
  'IconChristmasTree',
  'IconChristmasTreeOff',
  'IconCircle',
  'IconCircle0Filled',
  'IconCircle1Filled',
  'IconCircle2Filled',
  'IconCircle3Filled',
  'IconCircle4Filled',
  'IconCircle5Filled',
  'IconCircle6Filled',
  'IconCircle7Filled',
  'IconCircle8Filled',
  'IconCircle9Filled',
  'IconCircleArrowDown',
  'IconCircleArrowDownFilled',
  'IconCircleArrowDownLeft',
  'IconCircleArrowDownLeftFilled',
  'IconCircleArrowDownRight',
  'IconCircleArrowDownRightFilled',
  'IconCircleArrowLeft',
  'IconCircleArrowLeftFilled',
  'IconCircleArrowRight',
  'IconCircleArrowRightFilled',
  'IconCircleArrowUp',
  'IconCircleArrowUpFilled',
  'IconCircleArrowUpLeft',
  'IconCircleArrowUpLeftFilled',
  'IconCircleArrowUpRight',
  'IconCircleArrowUpRightFilled',
  'IconCircleCaretDown',
  'IconCircleCaretLeft',
  'IconCircleCaretRight',
  'IconCircleCaretUp',
  'IconCircleCheck',
  'IconCircleCheckFilled',
  'IconCircleChevronDown',
  'IconCircleChevronLeft',
  'IconCircleChevronRight',
  'IconCircleChevronUp',
  'IconCircleChevronsDown',
  'IconCircleChevronsLeft',
  'IconCircleChevronsRight',
  'IconCircleChevronsUp',
  'IconCircleDashed',
  'IconCircleDot',
  'IconCircleDotFilled',
  'IconCircleDotted',
  'IconCircleFilled',
  'IconCircleHalf',
  'IconCircleHalf2',
  'IconCircleHalfVertical',
  'IconCircleKey',
  'IconCircleKeyFilled',
  'IconCircleLetterA',
  'IconCircleLetterB',
  'IconCircleLetterC',
  'IconCircleLetterD',
  'IconCircleLetterE',
  'IconCircleLetterF',
  'IconCircleLetterG',
  'IconCircleLetterH',
  'IconCircleLetterI',
  'IconCircleLetterJ',
  'IconCircleLetterK',
  'IconCircleLetterL',
  'IconCircleLetterM',
  'IconCircleLetterN',
  'IconCircleLetterO',
  'IconCircleLetterP',
  'IconCircleLetterQ',
  'IconCircleLetterR',
  'IconCircleLetterS',
  'IconCircleLetterT',
  'IconCircleLetterU',
  'IconCircleLetterV',
  'IconCircleLetterW',
  'IconCircleLetterX',
  'IconCircleLetterY',
  'IconCircleLetterZ',
  'IconCircleMinus',
  'IconCircleNumber0',
  'IconCircleNumber1',
  'IconCircleNumber2',
  'IconCircleNumber3',
  'IconCircleNumber4',
  'IconCircleNumber5',
  'IconCircleNumber6',
  'IconCircleNumber7',
  'IconCircleNumber8',
  'IconCircleNumber9',
  'IconCircleOff',
  'IconCirclePlus',
  'IconCircleRectangle',
  'IconCircleRectangleOff',
  'IconCircleSquare',
  'IconCircleTriangle',
  'IconCircleX',
  'IconCircleXFilled',
  'IconCircles',
  'IconCirclesFilled',
  'IconCirclesRelation',
  'IconCircuitAmmeter',
  'IconCircuitBattery',
  'IconCircuitBulb',
  'IconCircuitCapacitor',
  'IconCircuitCapacitorPolarized',
  'IconCircuitCell',
  'IconCircuitCellPlus',
  'IconCircuitChangeover',
  'IconCircuitDiode',
  'IconCircuitDiodeZener',
  'IconCircuitGround',
  'IconCircuitGroundDigital',
  'IconCircuitInductor',
  'IconCircuitMotor',
  'IconCircuitPushbutton',
  'IconCircuitResistor',
  'IconCircuitSwitchClosed',
  'IconCircuitSwitchOpen',
  'IconCircuitVoltmeter',
  'IconClearAll',
  'IconClearFormatting',
  'IconClick',
  'IconClipboard',
  'IconClipboardCheck',
  'IconClipboardCopy',
  'IconClipboardData',
  'IconClipboardHeart',
  'IconClipboardList',
  'IconClipboardOff',
  'IconClipboardPlus',
  'IconClipboardText',
  'IconClipboardTypography',
  'IconClipboardX',
  'IconClock',
  'IconClock12',
  'IconClock2',
  'IconClock24',
  'IconClockBolt',
  'IconClockCancel',
  'IconClockCheck',
  'IconClockCode',
  'IconClockCog',
  'IconClockDollar',
  'IconClockDown',
  'IconClockEdit',
  'IconClockExclamation',
  'IconClockFilled',
  'IconClockHeart',
  'IconClockHour1',
  'IconClockHour10',
  'IconClockHour11',
  'IconClockHour12',
  'IconClockHour2',
  'IconClockHour3',
  'IconClockHour4',
  'IconClockHour5',
  'IconClockHour6',
  'IconClockHour7',
  'IconClockHour8',
  'IconClockHour9',
  'IconClockMinus',
  'IconClockOff',
  'IconClockPause',
  'IconClockPin',
  'IconClockPlay',
  'IconClockPlus',
  'IconClockQuestion',
  'IconClockRecord',
  'IconClockSearch',
  'IconClockShare',
  'IconClockShield',
  'IconClockStar',
  'IconClockStop',
  'IconClockUp',
  'IconClockX',
  'IconClothesRack',
  'IconClothesRackOff',
  'IconCloud',
  'IconCloudBolt',
  'IconCloudCancel',
  'IconCloudCheck',
  'IconCloudCode',
  'IconCloudCog',
  'IconCloudComputing',
  'IconCloudDataConnection',
  'IconCloudDollar',
  'IconCloudDown',
  'IconCloudDownload',
  'IconCloudExclamation',
  'IconCloudFilled',
  'IconCloudFog',
  'IconCloudHeart',
  'IconCloudLock',
  'IconCloudLockOpen',
  'IconCloudMinus',
  'IconCloudOff',
  'IconCloudPause',
  'IconCloudPin',
  'IconCloudPlus',
  'IconCloudQuestion',
  'IconCloudRain',
  'IconCloudSearch',
  'IconCloudShare',
  'IconCloudSnow',
  'IconCloudStar',
  'IconCloudStorm',
  'IconCloudUp',
  'IconCloudUpload',
  'IconCloudX',
  'IconClover',
  'IconClover2',
  'IconClubs',
  'IconClubsFilled',
  'IconCode',
  'IconCodeAsterix',
  'IconCodeCircle',
  'IconCodeCircle2',
  'IconCodeDots',
  'IconCodeMinus',
  'IconCodeOff',
  'IconCodePlus',
  'IconCoffee',
  'IconCoffeeOff',
  'IconCoffin',
  'IconCoin',
  'IconCoinBitcoin',
  'IconCoinEuro',
  'IconCoinMonero',
  'IconCoinOff',
  'IconCoinPound',
  'IconCoinRupee',
  'IconCoinYen',
  'IconCoinYuan',
  'IconCoins',
  'IconColorFilter',
  'IconColorPicker',
  'IconColorPickerOff',
  'IconColorSwatch',
  'IconColorSwatchOff',
  'IconColumnInsertLeft',
  'IconColumnInsertRight',
  'IconColumnRemove',
  'IconColumns',
  'IconColumns1',
  'IconColumns2',
  'IconColumns3',
  'IconColumnsOff',
  'IconComet',
  'IconCommand',
  'IconCommandOff',
  'IconCompass',
  'IconCompassOff',
  'IconComponents',
  'IconComponentsOff',
  'IconCone',
  'IconCone2',
  'IconConeOff',
  'IconConePlus',
  'IconConfetti',
  'IconConfettiOff',
  'IconConfucius',
  'IconContainer',
  'IconContainerOff',
  'IconContrast',
  'IconContrast2',
  'IconContrast2Off',
  'IconContrastOff',
  'IconCooker',
  'IconCookie',
  'IconCookieMan',
  'IconCookieOff',
  'IconCopy',
  'IconCopyOff',
  'IconCopyleft',
  'IconCopyleftFilled',
  'IconCopyleftOff',
  'IconCopyright',
  'IconCopyrightFilled',
  'IconCopyrightOff',
  'IconCornerDownLeft',
  'IconCornerDownLeftDouble',
  'IconCornerDownRight',
  'IconCornerDownRightDouble',
  'IconCornerLeftDown',
  'IconCornerLeftDownDouble',
  'IconCornerLeftUp',
  'IconCornerLeftUpDouble',
  'IconCornerRightDown',
  'IconCornerRightDownDouble',
  'IconCornerRightUp',
  'IconCornerRightUpDouble',
  'IconCornerUpLeft',
  'IconCornerUpLeftDouble',
  'IconCornerUpRight',
  'IconCornerUpRightDouble',
  'IconCpu',
  'IconCpu2',
  'IconCpuOff',
  'IconCrane',
  'IconCraneOff',
  'IconCreativeCommons',
  'IconCreativeCommonsBy',
  'IconCreativeCommonsNc',
  'IconCreativeCommonsNd',
  'IconCreativeCommonsOff',
  'IconCreativeCommonsSa',
  'IconCreativeCommonsZero',
  'IconCreditCard',
  'IconCreditCardOff',
  'IconCricket',
  'IconCrop',
  'IconCross',
  'IconCrossFilled',
  'IconCrossOff',
  'IconCrosshair',
  'IconCrown',
  'IconCrownOff',
  'IconCrutches',
  'IconCrutchesOff',
  'IconCrystalBall',
  'IconCsv',
  'IconCube',
  'IconCubeOff',
  'IconCubePlus',
  'IconCubeSend',
  'IconCubeUnfolded',
  'IconCup',
  'IconCupOff',
  'IconCurling',
  'IconCurlyLoop',
  'IconCurrency',
  'IconCurrencyAfghani',
  'IconCurrencyBahraini',
  'IconCurrencyBaht',
  'IconCurrencyBitcoin',
  'IconCurrencyCent',
  'IconCurrencyDinar',
  'IconCurrencyDirham',
  'IconCurrencyDogecoin',
  'IconCurrencyDollar',
  'IconCurrencyDollarAustralian',
  'IconCurrencyDollarBrunei',
  'IconCurrencyDollarCanadian',
  'IconCurrencyDollarGuyanese',
  'IconCurrencyDollarOff',
  'IconCurrencyDollarSingapore',
  'IconCurrencyDollarZimbabwean',
  'IconCurrencyDong',
  'IconCurrencyDram',
  'IconCurrencyEthereum',
  'IconCurrencyEuro',
  'IconCurrencyEuroOff',
  'IconCurrencyFlorin',
  'IconCurrencyForint',
  'IconCurrencyFrank',
  'IconCurrencyGuarani',
  'IconCurrencyHryvnia',
  'IconCurrencyIranianRial',
  'IconCurrencyKip',
  'IconCurrencyKroneCzech',
  'IconCurrencyKroneDanish',
  'IconCurrencyKroneSwedish',
  'IconCurrencyLari',
  'IconCurrencyLeu',
  'IconCurrencyLira',
  'IconCurrencyLitecoin',
  'IconCurrencyLyd',
  'IconCurrencyManat',
  'IconCurrencyMonero',
  'IconCurrencyNaira',
  'IconCurrencyNano',
  'IconCurrencyOff',
  'IconCurrencyPaanga',
  'IconCurrencyPeso',
  'IconCurrencyPound',
  'IconCurrencyPoundOff',
  'IconCurrencyQuetzal',
  'IconCurrencyReal',
  'IconCurrencyRenminbi',
  'IconCurrencyRipple',
  'IconCurrencyRiyal',
  'IconCurrencyRubel',
  'IconCurrencyRufiyaa',
  'IconCurrencyRupee',
  'IconCurrencyRupeeNepalese',
  'IconCurrencyShekel',
  'IconCurrencySolana',
  'IconCurrencySom',
  'IconCurrencyTaka',
  'IconCurrencyTenge',
  'IconCurrencyTugrik',
  'IconCurrencyWon',
  'IconCurrencyYen',
  'IconCurrencyYenOff',
  'IconCurrencyYuan',
  'IconCurrencyZloty',
  'IconCurrentLocation',
  'IconCurrentLocationOff',
  'IconCursorOff',
  'IconCursorText',
  'IconCut',
  'IconCylinder',
  'IconCylinderOff',
  'IconCylinderPlus',
  'IconDashboard',
  'IconDashboardOff',
  'IconDatabase',
  'IconDatabaseCog',
  'IconDatabaseDollar',
  'IconDatabaseEdit',
  'IconDatabaseExclamation',
  'IconDatabaseExport',
  'IconDatabaseHeart',
  'IconDatabaseImport',
  'IconDatabaseLeak',
  'IconDatabaseMinus',
  'IconDatabaseOff',
  'IconDatabasePlus',
  'IconDatabaseSearch',
  'IconDatabaseShare',
  'IconDatabaseStar',
  'IconDatabaseX',
  'IconDecimal',
  'IconDeer',
  'IconDelta',
  'IconDental',
  'IconDentalBroken',
  'IconDentalOff',
  'IconDeselect',
  'IconDetails',
  'IconDetailsOff',
  'IconDeviceAirpods',
  'IconDeviceAirpodsCase',
  'IconDeviceAirtag',
  'IconDeviceAnalytics',
  'IconDeviceAudioTape',
  'IconDeviceCameraPhone',
  'IconDeviceCctv',
  'IconDeviceCctvOff',
  'IconDeviceComputerCamera',
  'IconDeviceComputerCameraOff',
  'IconDeviceDesktop',
  'IconDeviceDesktopAnalytics',
  'IconDeviceDesktopBolt',
  'IconDeviceDesktopCancel',
  'IconDeviceDesktopCheck',
  'IconDeviceDesktopCode',
  'IconDeviceDesktopCog',
  'IconDeviceDesktopDollar',
  'IconDeviceDesktopDown',
  'IconDeviceDesktopExclamation',
  'IconDeviceDesktopHeart',
  'IconDeviceDesktopMinus',
  'IconDeviceDesktopOff',
  'IconDeviceDesktopPause',
  'IconDeviceDesktopPin',
  'IconDeviceDesktopPlus',
  'IconDeviceDesktopQuestion',
  'IconDeviceDesktopSearch',
  'IconDeviceDesktopShare',
  'IconDeviceDesktopStar',
  'IconDeviceDesktopUp',
  'IconDeviceDesktopX',
  'IconDeviceFloppy',
  'IconDeviceGamePad',
  'IconDeviceGamepad',
  'IconDeviceGamepad2',
  'IconDeviceHeartMonitor',
  'IconDeviceHeartMonitorFilled',
  'IconDeviceImac',
  'IconDeviceImacBolt',
  'IconDeviceImacCancel',
  'IconDeviceImacCheck',
  'IconDeviceImacCode',
  'IconDeviceImacCog',
  'IconDeviceImacDollar',
  'IconDeviceImacDown',
  'IconDeviceImacExclamation',
  'IconDeviceImacHeart',
  'IconDeviceImacMinus',
  'IconDeviceImacOff',
  'IconDeviceImacPause',
  'IconDeviceImacPin',
  'IconDeviceImacPlus',
  'IconDeviceImacQuestion',
  'IconDeviceImacSearch',
  'IconDeviceImacShare',
  'IconDeviceImacStar',
  'IconDeviceImacUp',
  'IconDeviceImacX',
  'IconDeviceIpad',
  'IconDeviceIpadBolt',
  'IconDeviceIpadCancel',
  'IconDeviceIpadCheck',
  'IconDeviceIpadCode',
  'IconDeviceIpadCog',
  'IconDeviceIpadDollar',
  'IconDeviceIpadDown',
  'IconDeviceIpadExclamation',
  'IconDeviceIpadHeart',
  'IconDeviceIpadHorizontal',
  'IconDeviceIpadHorizontalBolt',
  'IconDeviceIpadHorizontalCancel',
  'IconDeviceIpadHorizontalCheck',
  'IconDeviceIpadHorizontalCode',
  'IconDeviceIpadHorizontalCog',
  'IconDeviceIpadHorizontalDollar',
  'IconDeviceIpadHorizontalDown',
  'IconDeviceIpadHorizontalExclamation',
  'IconDeviceIpadHorizontalHeart',
  'IconDeviceIpadHorizontalMinus',
  'IconDeviceIpadHorizontalOff',
  'IconDeviceIpadHorizontalPause',
  'IconDeviceIpadHorizontalPin',
  'IconDeviceIpadHorizontalPlus',
  'IconDeviceIpadHorizontalQuestion',
  'IconDeviceIpadHorizontalSearch',
  'IconDeviceIpadHorizontalShare',
  'IconDeviceIpadHorizontalStar',
  'IconDeviceIpadHorizontalUp',
  'IconDeviceIpadHorizontalX',
  'IconDeviceIpadMinus',
  'IconDeviceIpadOff',
  'IconDeviceIpadPause',
  'IconDeviceIpadPin',
  'IconDeviceIpadPlus',
  'IconDeviceIpadQuestion',
  'IconDeviceIpadSearch',
  'IconDeviceIpadShare',
  'IconDeviceIpadStar',
  'IconDeviceIpadUp',
  'IconDeviceIpadX',
  'IconDeviceLandlinePhone',
  'IconDeviceLaptop',
  'IconDeviceLaptopOff',
  'IconDeviceMobile',
  'IconDeviceMobileBolt',
  'IconDeviceMobileCancel',
  'IconDeviceMobileCharging',
  'IconDeviceMobileCheck',
  'IconDeviceMobileCode',
  'IconDeviceMobileCog',
  'IconDeviceMobileDollar',
  'IconDeviceMobileDown',
  'IconDeviceMobileExclamation',
  'IconDeviceMobileFilled',
  'IconDeviceMobileHeart',
  'IconDeviceMobileMessage',
  'IconDeviceMobileMinus',
  'IconDeviceMobileOff',
  'IconDeviceMobilePause',
  'IconDeviceMobilePin',
  'IconDeviceMobilePlus',
  'IconDeviceMobileQuestion',
  'IconDeviceMobileRotated',
  'IconDeviceMobileSearch',
  'IconDeviceMobileShare',
  'IconDeviceMobileStar',
  'IconDeviceMobileUp',
  'IconDeviceMobileVibration',
  'IconDeviceMobileX',
  'IconDeviceNintendo',
  'IconDeviceNintendoOff',
  'IconDeviceProjector',
  'IconDeviceRemote',
  'IconDeviceSdCard',
  'IconDeviceSim',
  'IconDeviceSim1',
  'IconDeviceSim2',
  'IconDeviceSim3',
  'IconDeviceSpeaker',
  'IconDeviceSpeakerOff',
  'IconDeviceTablet',
  'IconDeviceTabletBolt',
  'IconDeviceTabletCancel',
  'IconDeviceTabletCheck',
  'IconDeviceTabletCode',
  'IconDeviceTabletCog',
  'IconDeviceTabletDollar',
  'IconDeviceTabletDown',
  'IconDeviceTabletExclamation',
  'IconDeviceTabletFilled',
  'IconDeviceTabletHeart',
  'IconDeviceTabletMinus',
  'IconDeviceTabletOff',
  'IconDeviceTabletPause',
  'IconDeviceTabletPin',
  'IconDeviceTabletPlus',
  'IconDeviceTabletQuestion',
  'IconDeviceTabletSearch',
  'IconDeviceTabletShare',
  'IconDeviceTabletStar',
  'IconDeviceTabletUp',
  'IconDeviceTabletX',
  'IconDeviceTv',
  'IconDeviceTvOff',
  'IconDeviceTvOld',
  'IconDeviceUsb',
  'IconDeviceVisionPro',
  'IconDeviceWatch',
  'IconDeviceWatchBolt',
  'IconDeviceWatchCancel',
  'IconDeviceWatchCheck',
  'IconDeviceWatchCode',
  'IconDeviceWatchCog',
  'IconDeviceWatchDollar',
  'IconDeviceWatchDown',
  'IconDeviceWatchExclamation',
  'IconDeviceWatchHeart',
  'IconDeviceWatchMinus',
  'IconDeviceWatchOff',
  'IconDeviceWatchPause',
  'IconDeviceWatchPin',
  'IconDeviceWatchPlus',
  'IconDeviceWatchQuestion',
  'IconDeviceWatchSearch',
  'IconDeviceWatchShare',
  'IconDeviceWatchStar',
  'IconDeviceWatchStats',
  'IconDeviceWatchStats2',
  'IconDeviceWatchUp',
  'IconDeviceWatchX',
  'IconDevices',
  'IconDevices2',
  'IconDevicesBolt',
  'IconDevicesCancel',
  'IconDevicesCheck',
  'IconDevicesCode',
  'IconDevicesCog',
  'IconDevicesDollar',
  'IconDevicesDown',
  'IconDevicesExclamation',
  'IconDevicesHeart',
  'IconDevicesMinus',
  'IconDevicesOff',
  'IconDevicesPause',
  'IconDevicesPc',
  'IconDevicesPcOff',
  'IconDevicesPin',
  'IconDevicesPlus',
  'IconDevicesQuestion',
  'IconDevicesSearch',
  'IconDevicesShare',
  'IconDevicesStar',
  'IconDevicesUp',
  'IconDevicesX',
  'IconDiabolo',
  'IconDiaboloOff',
  'IconDiaboloPlus',
  'IconDialpad',
  'IconDialpadFilled',
  'IconDialpadOff',
  'IconDiamond',
  'IconDiamondFilled',
  'IconDiamondOff',
  'IconDiamonds',
  'IconDiamondsFilled',
  'IconDice',
  'IconDice1',
  'IconDice1Filled',
  'IconDice2',
  'IconDice2Filled',
  'IconDice3',
  'IconDice3Filled',
  'IconDice4',
  'IconDice4Filled',
  'IconDice5',
  'IconDice5Filled',
  'IconDice6',
  'IconDice6Filled',
  'IconDiceFilled',
  'IconDimensions',
  'IconDirection',
  'IconDirectionHorizontal',
  'IconDirectionSign',
  'IconDirectionSignFilled',
  'IconDirectionSignOff',
  'IconDirections',
  'IconDirectionsOff',
  'IconDisabled',
  'IconDisabled2',
  'IconDisabledOff',
  'IconDisc',
  'IconDiscGolf',
  'IconDiscOff',
  'IconDiscount',
  'IconDiscount2',
  'IconDiscount2Off',
  'IconDiscountCheck',
  'IconDiscountCheckFilled',
  'IconDiscountOff',
  'IconDivide',
  'IconDna',
  'IconDna2',
  'IconDna2Off',
  'IconDnaOff',
  'IconDog',
  'IconDogBowl',
  'IconDoor',
  'IconDoorEnter',
  'IconDoorExit',
  'IconDoorOff',
  'IconDots',
  'IconDotsCircleHorizontal',
  'IconDotsDiagonal',
  'IconDotsDiagonal2',
  'IconDotsVertical',
  'IconDownload',
  'IconDownloadOff',
  'IconDragDrop',
  'IconDragDrop2',
  'IconDrone',
  'IconDroneOff',
  'IconDropCircle',
  'IconDroplet',
  'IconDropletBolt',
  'IconDropletCancel',
  'IconDropletCheck',
  'IconDropletCode',
  'IconDropletCog',
  'IconDropletDollar',
  'IconDropletDown',
  'IconDropletExclamation',
  'IconDropletFilled',
  'IconDropletHalf',
  'IconDropletHalf2',
  'IconDropletHalf2Filled',
  'IconDropletHalfFilled',
  'IconDropletHeart',
  'IconDropletMinus',
  'IconDropletOff',
  'IconDropletPause',
  'IconDropletPin',
  'IconDropletPlus',
  'IconDropletQuestion',
  'IconDropletSearch',
  'IconDropletShare',
  'IconDropletStar',
  'IconDropletUp',
  'IconDropletX',
  'IconDroplets',
  'IconDualScreen',
  'IconEPassport',
  'IconEar',
  'IconEarOff',
  'IconEaseIn',
  'IconEaseInControlPoint',
  'IconEaseInOut',
  'IconEaseInOutControlPoints',
  'IconEaseOut',
  'IconEaseOutControlPoint',
  'IconEdit',
  'IconEditCircle',
  'IconEditCircleOff',
  'IconEditOff',
  'IconEgg',
  'IconEggCracked',
  'IconEggFilled',
  'IconEggFried',
  'IconEggOff',
  'IconEggs',
  'IconElevator',
  'IconElevatorOff',
  'IconEmergencyBed',
  'IconEmpathize',
  'IconEmpathizeOff',
  'IconEmphasis',
  'IconEngine',
  'IconEngineOff',
  'IconEqual',
  'IconEqualDouble',
  'IconEqualNot',
  'IconEraser',
  'IconEraserOff',
  'IconError404',
  'IconError404Off',
  'IconEscalator',
  'IconEscalatorDown',
  'IconEscalatorUp',
  'IconExchange',
  'IconExchangeOff',
  'IconExclamationCircle',
  'IconExclamationMark',
  'IconExclamationMarkOff',
  'IconExplicit',
  'IconExplicitOff',
  'IconExposure',
  'IconExposure0',
  'IconExposureMinus1',
  'IconExposureMinus2',
  'IconExposureOff',
  'IconExposurePlus1',
  'IconExposurePlus2',
  'IconExternalLink',
  'IconExternalLinkOff',
  'IconEye',
  'IconEyeBolt',
  'IconEyeCancel',
  'IconEyeCheck',
  'IconEyeClosed',
  'IconEyeCode',
  'IconEyeCog',
  'IconEyeDiscount',
  'IconEyeDollar',
  'IconEyeDown',
  'IconEyeEdit',
  'IconEyeExclamation',
  'IconEyeFilled',
  'IconEyeHeart',
  'IconEyeMinus',
  'IconEyeOff',
  'IconEyePause',
  'IconEyePin',
  'IconEyePlus',
  'IconEyeQuestion',
  'IconEyeSearch',
  'IconEyeShare',
  'IconEyeStar',
  'IconEyeTable',
  'IconEyeUp',
  'IconEyeX',
  'IconEyeglass',
  'IconEyeglass2',
  'IconEyeglassOff',
  'IconFaceId',
  'IconFaceIdError',
  'IconFaceMask',
  'IconFaceMaskOff',
  'IconFall',
  'IconFeather',
  'IconFeatherOff',
  'IconFence',
  'IconFenceOff',
  'IconFidgetSpinner',
  'IconFile',
  'IconFile3d',
  'IconFileAlert',
  'IconFileAnalytics',
  'IconFileArrowLeft',
  'IconFileArrowRight',
  'IconFileBarcode',
  'IconFileBroken',
  'IconFileCertificate',
  'IconFileChart',
  'IconFileCheck',
  'IconFileCode',
  'IconFileCode2',
  'IconFileCv',
  'IconFileDatabase',
  'IconFileDelta',
  'IconFileDescription',
  'IconFileDiff',
  'IconFileDigit',
  'IconFileDislike',
  'IconFileDollar',
  'IconFileDots',
  'IconFileDownload',
  'IconFileEuro',
  'IconFileExport',
  'IconFileFilled',
  'IconFileFunction',
  'IconFileHorizontal',
  'IconFileImport',
  'IconFileInfinity',
  'IconFileInfo',
  'IconFileInvoice',
  'IconFileLambda',
  'IconFileLike',
  'IconFileMinus',
  'IconFileMusic',
  'IconFileOff',
  'IconFileOrientation',
  'IconFilePencil',
  'IconFilePercent',
  'IconFilePhone',
  'IconFilePlus',
  'IconFilePower',
  'IconFileReport',
  'IconFileRss',
  'IconFileScissors',
  'IconFileSearch',
  'IconFileSettings',
  'IconFileShredder',
  'IconFileSignal',
  'IconFileSpreadsheet',
  'IconFileStack',
  'IconFileStar',
  'IconFileSymlink',
  'IconFileText',
  'IconFileTextAi',
  'IconFileTime',
  'IconFileTypeBmp',
  'IconFileTypeCss',
  'IconFileTypeCsv',
  'IconFileTypeDoc',
  'IconFileTypeDocx',
  'IconFileTypeHtml',
  'IconFileTypeJpg',
  'IconFileTypeJs',
  'IconFileTypeJsx',
  'IconFileTypePdf',
  'IconFileTypePhp',
  'IconFileTypePng',
  'IconFileTypePpt',
  'IconFileTypeRs',
  'IconFileTypeSql',
  'IconFileTypeSvg',
  'IconFileTypeTs',
  'IconFileTypeTsx',
  'IconFileTypeTxt',
  'IconFileTypeVue',
  'IconFileTypeXls',
  'IconFileTypeXml',
  'IconFileTypeZip',
  'IconFileTypography',
  'IconFileUnknown',
  'IconFileUpload',
  'IconFileVector',
  'IconFileX',
  'IconFileXFilled',
  'IconFileZip',
  'IconFiles',
  'IconFilesOff',
  'IconFilter',
  'IconFilterBolt',
  'IconFilterCancel',
  'IconFilterCheck',
  'IconFilterCode',
  'IconFilterCog',
  'IconFilterDiscount',
  'IconFilterDollar',
  'IconFilterDown',
  'IconFilterEdit',
  'IconFilterExclamation',
  'IconFilterFilled',
  'IconFilterHeart',
  'IconFilterMinus',
  'IconFilterOff',
  'IconFilterPause',
  'IconFilterPin',
  'IconFilterPlus',
  'IconFilterQuestion',
  'IconFilterSearch',
  'IconFilterShare',
  'IconFilterStar',
  'IconFilterUp',
  'IconFilterX',
  'IconFilters',
  'IconFingerprint',
  'IconFingerprintOff',
  'IconFireExtinguisher',
  'IconFireHydrant',
  'IconFireHydrantOff',
  'IconFiretruck',
  'IconFirstAidKit',
  'IconFirstAidKitOff',
  'IconFish',
  'IconFishBone',
  'IconFishChristianity',
  'IconFishHook',
  'IconFishHookOff',
  'IconFishOff',
  'IconFlag',
  'IconFlag2',
  'IconFlag2Filled',
  'IconFlag2Off',
  'IconFlag3',
  'IconFlag3Filled',
  'IconFlagBolt',
  'IconFlagCancel',
  'IconFlagCheck',
  'IconFlagCode',
  'IconFlagCog',
  'IconFlagDiscount',
  'IconFlagDollar',
  'IconFlagDown',
  'IconFlagExclamation',
  'IconFlagFilled',
  'IconFlagHeart',
  'IconFlagMinus',
  'IconFlagOff',
  'IconFlagPause',
  'IconFlagPin',
  'IconFlagPlus',
  'IconFlagQuestion',
  'IconFlagSearch',
  'IconFlagShare',
  'IconFlagStar',
  'IconFlagUp',
  'IconFlagX',
  'IconFlame',
  'IconFlameOff',
  'IconFlare',
  'IconFlask',
  'IconFlask2',
  'IconFlask2Off',
  'IconFlaskOff',
  'IconFlipFlops',
  'IconFlipHorizontal',
  'IconFlipVertical',
  'IconFloatCenter',
  'IconFloatLeft',
  'IconFloatNone',
  'IconFloatRight',
  'IconFlower',
  'IconFlowerOff',
  'IconFocus',
  'IconFocus2',
  'IconFocusAuto',
  'IconFocusCentered',
  'IconFold',
  'IconFoldDown',
  'IconFoldUp',
  'IconFolder',
  'IconFolderBolt',
  'IconFolderCancel',
  'IconFolderCheck',
  'IconFolderCode',
  'IconFolderCog',
  'IconFolderDollar',
  'IconFolderDown',
  'IconFolderExclamation',
  'IconFolderFilled',
  'IconFolderHeart',
  'IconFolderMinus',
  'IconFolderOff',
  'IconFolderOpen',
  'IconFolderPause',
  'IconFolderPin',
  'IconFolderPlus',
  'IconFolderQuestion',
  'IconFolderSearch',
  'IconFolderShare',
  'IconFolderStar',
  'IconFolderSymlink',
  'IconFolderUp',
  'IconFolderX',
  'IconFolders',
  'IconFoldersOff',
  'IconForbid',
  'IconForbid2',
  'IconForbid2Filled',
  'IconForbidFilled',
  'IconForklift',
  'IconForms',
  'IconFountain',
  'IconFountainFilled',
  'IconFountainOff',
  'IconFrame',
  'IconFrameOff',
  'IconFreeRights',
  'IconFreezeColumn',
  'IconFreezeRow',
  'IconFreezeRowColumn',
  'IconFridge',
  'IconFridgeOff',
  'IconFriends',
  'IconFriendsOff',
  'IconFrustum',
  'IconFrustumOff',
  'IconFrustumPlus',
  'IconFunction',
  'IconFunctionFilled',
  'IconFunctionOff',
  'IconGardenCart',
  'IconGardenCartOff',
  'IconGasStation',
  'IconGasStationOff',
  'IconGauge',
  'IconGaugeFilled',
  'IconGaugeOff',
  'IconGavel',
  'IconGenderAgender',
  'IconGenderAndrogyne',
  'IconGenderBigender',
  'IconGenderDemiboy',
  'IconGenderDemigirl',
  'IconGenderEpicene',
  'IconGenderFemale',
  'IconGenderFemme',
  'IconGenderGenderfluid',
  'IconGenderGenderless',
  'IconGenderGenderqueer',
  'IconGenderHermaphrodite',
  'IconGenderIntergender',
  'IconGenderMale',
  'IconGenderNeutrois',
  'IconGenderThird',
  'IconGenderTransgender',
  'IconGenderTrasvesti',
  'IconGeometry',
  'IconGhost',
  'IconGhost2',
  'IconGhost2Filled',
  'IconGhost3',
  'IconGhostFilled',
  'IconGhostOff',
  'IconGif',
  'IconGift',
  'IconGiftCard',
  'IconGiftCardFilled',
  'IconGiftOff',
  'IconGitBranch',
  'IconGitBranchDeleted',
  'IconGitCherryPick',
  'IconGitCommit',
  'IconGitCompare',
  'IconGitFork',
  'IconGitMerge',
  'IconGitPullRequest',
  'IconGitPullRequestClosed',
  'IconGitPullRequestDraft',
  'IconGizmo',
  'IconGlass',
  'IconGlassFull',
  'IconGlassFullFilled',
  'IconGlassOff',
  'IconGlobe',
  'IconGlobeFilled',
  'IconGlobeOff',
  'IconGoGame',
  'IconGolf',
  'IconGolfOff',
  'IconGps',
  'IconGradienter',
  'IconGrain',
  'IconGraph',
  'IconGraphOff',
  'IconGrave',
  'IconGrave2',
  'IconGridDots',
  'IconGridPattern',
  'IconGrill',
  'IconGrillFork',
  'IconGrillOff',
  'IconGrillSpatula',
  'IconGripHorizontal',
  'IconGripVertical',
  'IconGrowth',
  'IconGuitarPick',
  'IconGuitarPickFilled',
  'IconH1',
  'IconH2',
  'IconH3',
  'IconH4',
  'IconH5',
  'IconH6',
  'IconHammer',
  'IconHammerOff',
  'IconHandClick',
  'IconHandFinger',
  'IconHandFingerOff',
  'IconHandGrab',
  'IconHandLittleFinger',
  'IconHandMiddleFinger',
  'IconHandMove',
  'IconHandOff',
  'IconHandRingFinger',
  'IconHandRock',
  'IconHandSanitizer',
  'IconHandStop',
  'IconHandThreeFingers',
  'IconHandTwoFingers',
  'IconHanger',
  'IconHanger2',
  'IconHangerOff',
  'IconHash',
  'IconHaze',
  'IconHazeMoon',
  'IconHdr',
  'IconHeading',
  'IconHeadingOff',
  'IconHeadphones',
  'IconHeadphonesFilled',
  'IconHeadphonesOff',
  'IconHeadset',
  'IconHeadsetOff',
  'IconHealthRecognition',
  'IconHeart',
  'IconHeartBolt',
  'IconHeartBroken',
  'IconHeartCancel',
  'IconHeartCheck',
  'IconHeartCode',
  'IconHeartCog',
  'IconHeartDiscount',
  'IconHeartDollar',
  'IconHeartDown',
  'IconHeartExclamation',
  'IconHeartFilled',
  'IconHeartHandshake',
  'IconHeartMinus',
  'IconHeartOff',
  'IconHeartPause',
  'IconHeartPin',
  'IconHeartPlus',
  'IconHeartQuestion',
  'IconHeartRateMonitor',
  'IconHeartSearch',
  'IconHeartShare',
  'IconHeartStar',
  'IconHeartUp',
  'IconHeartX',
  'IconHeartbeat',
  'IconHearts',
  'IconHeartsOff',
  'IconHelicopter',
  'IconHelicopterLanding',
  'IconHelmet',
  'IconHelmetOff',
  'IconHelp',
  'IconHelpCircle',
  'IconHelpCircleFilled',
  'IconHelpHexagon',
  'IconHelpHexagonFilled',
  'IconHelpOctagon',
  'IconHelpOctagonFilled',
  'IconHelpOff',
  'IconHelpSmall',
  'IconHelpSquare',
  'IconHelpSquareFilled',
  'IconHelpSquareRounded',
  'IconHelpSquareRoundedFilled',
  'IconHelpTriangle',
  'IconHelpTriangleFilled',
  'IconHemisphere',
  'IconHemisphereOff',
  'IconHemispherePlus',
  'IconHexagon',
  'IconHexagon0Filled',
  'IconHexagon1Filled',
  'IconHexagon2Filled',
  'IconHexagon3Filled',
  'IconHexagon3d',
  'IconHexagon4Filled',
  'IconHexagon5Filled',
  'IconHexagon6Filled',
  'IconHexagon7Filled',
  'IconHexagon8Filled',
  'IconHexagon9Filled',
  'IconHexagonFilled',
  'IconHexagonLetterA',
  'IconHexagonLetterB',
  'IconHexagonLetterC',
  'IconHexagonLetterD',
  'IconHexagonLetterE',
  'IconHexagonLetterF',
  'IconHexagonLetterG',
  'IconHexagonLetterH',
  'IconHexagonLetterI',
  'IconHexagonLetterJ',
  'IconHexagonLetterK',
  'IconHexagonLetterL',
  'IconHexagonLetterM',
  'IconHexagonLetterN',
  'IconHexagonLetterO',
  'IconHexagonLetterP',
  'IconHexagonLetterQ',
  'IconHexagonLetterR',
  'IconHexagonLetterS',
  'IconHexagonLetterT',
  'IconHexagonLetterU',
  'IconHexagonLetterV',
  'IconHexagonLetterW',
  'IconHexagonLetterX',
  'IconHexagonLetterY',
  'IconHexagonLetterZ',
  'IconHexagonNumber0',
  'IconHexagonNumber1',
  'IconHexagonNumber2',
  'IconHexagonNumber3',
  'IconHexagonNumber4',
  'IconHexagonNumber5',
  'IconHexagonNumber6',
  'IconHexagonNumber7',
  'IconHexagonNumber8',
  'IconHexagonNumber9',
  'IconHexagonOff',
  'IconHexagonPlus',
  'IconHexagonalPrism',
  'IconHexagonalPrismOff',
  'IconHexagonalPrismPlus',
  'IconHexagonalPyramid',
  'IconHexagonalPyramidOff',
  'IconHexagonalPyramidPlus',
  'IconHexagons',
  'IconHexagonsOff',
  'IconHierarchy',
  'IconHierarchy2',
  'IconHierarchy3',
  'IconHierarchyOff',
  'IconHighlight',
  'IconHighlightOff',
  'IconHistory',
  'IconHistoryOff',
  'IconHistoryToggle',
  'IconHome',
  'IconHome2',
  'IconHomeBolt',
  'IconHomeCancel',
  'IconHomeCheck',
  'IconHomeCog',
  'IconHomeDollar',
  'IconHomeDot',
  'IconHomeDown',
  'IconHomeEco',
  'IconHomeEdit',
  'IconHomeExclamation',
  'IconHomeHand',
  'IconHomeHeart',
  'IconHomeInfinity',
  'IconHomeLink',
  'IconHomeMinus',
  'IconHomeMove',
  'IconHomeOff',
  'IconHomePlus',
  'IconHomeQuestion',
  'IconHomeRibbon',
  'IconHomeSearch',
  'IconHomeShare',
  'IconHomeShield',
  'IconHomeSignal',
  'IconHomeStar',
  'IconHomeStats',
  'IconHomeUp',
  'IconHomeX',
  'IconHorse',
  'IconHorseToy',
  'IconHotelService',
  'IconHourglass',
  'IconHourglassEmpty',
  'IconHourglassFilled',
  'IconHourglassHigh',
  'IconHourglassLow',
  'IconHourglassOff',
  'IconHtml',
  'IconHttpConnect',
  'IconHttpDelete',
  'IconHttpGet',
  'IconHttpHead',
  'IconHttpOptions',
  'IconHttpPatch',
  'IconHttpPost',
  'IconHttpPut',
  'IconHttpQue',
  'IconHttpTrace',
  'IconIceCream',
  'IconIceCream2',
  'IconIceCreamOff',
  'IconIceSkating',
  'IconIcons',
  'IconIconsOff',
  'IconId',
  'IconIdBadge',
  'IconIdBadge2',
  'IconIdBadgeOff',
  'IconIdOff',
  'IconInbox',
  'IconInboxOff',
  'IconIndentDecrease',
  'IconIndentIncrease',
  'IconInfinity',
  'IconInfinityOff',
  'IconInfoCircle',
  'IconInfoCircleFilled',
  'IconInfoHexagon',
  'IconInfoHexagonFilled',
  'IconInfoOctagon',
  'IconInfoOctagonFilled',
  'IconInfoSmall',
  'IconInfoSquare',
  'IconInfoSquareFilled',
  'IconInfoSquareRounded',
  'IconInfoSquareRoundedFilled',
  'IconInfoTriangle',
  'IconInfoTriangleFilled',
  'IconInnerShadowBottom',
  'IconInnerShadowBottomFilled',
  'IconInnerShadowBottomLeft',
  'IconInnerShadowBottomLeftFilled',
  'IconInnerShadowBottomRight',
  'IconInnerShadowBottomRightFilled',
  'IconInnerShadowLeft',
  'IconInnerShadowLeftFilled',
  'IconInnerShadowRight',
  'IconInnerShadowRightFilled',
  'IconInnerShadowTop',
  'IconInnerShadowTopFilled',
  'IconInnerShadowTopLeft',
  'IconInnerShadowTopLeftFilled',
  'IconInnerShadowTopRight',
  'IconInnerShadowTopRightFilled',
  'IconInputAi',
  'IconInputCheck',
  'IconInputSearch',
  'IconInputX',
  'IconIroning',
  'IconIroning1',
  'IconIroning2',
  'IconIroning3',
  'IconIroningOff',
  'IconIroningSteam',
  'IconIroningSteamOff',
  'IconIrregularPolyhedron',
  'IconIrregularPolyhedronOff',
  'IconIrregularPolyhedronPlus',
  'IconItalic',
  'IconJacket',
  'IconJetpack',
  'IconJewishStar',
  'IconJewishStarFilled',
  'IconJpg',
  'IconJson',
  'IconJumpRope',
  'IconKarate',
  'IconKayak',
  'IconKering',
  'IconKey',
  'IconKeyOff',
  'IconKeyboard',
  'IconKeyboardHide',
  'IconKeyboardOff',
  'IconKeyboardShow',
  'IconKeyframe',
  'IconKeyframeAlignCenter',
  'IconKeyframeAlignCenterFilled',
  'IconKeyframeAlignHorizontal',
  'IconKeyframeAlignHorizontalFilled',
  'IconKeyframeAlignVertical',
  'IconKeyframeAlignVerticalFilled',
  'IconKeyframeFilled',
  'IconKeyframes',
  'IconKeyframesFilled',
  'IconLadder',
  'IconLadderOff',
  'IconLadle',
  'IconLambda',
  'IconLamp',
  'IconLamp2',
  'IconLampOff',
  'IconLane',
  'IconLanguage',
  'IconLanguageHiragana',
  'IconLanguageKatakana',
  'IconLanguageOff',
  'IconLasso',
  'IconLassoOff',
  'IconLassoPolygon',
  'IconLayersDifference',
  'IconLayersIntersect',
  'IconLayersIntersect2',
  'IconLayersLinked',
  'IconLayersOff',
  'IconLayersSubtract',
  'IconLayersUnion',
  'IconLayout',
  'IconLayout2',
  'IconLayoutAlignBottom',
  'IconLayoutAlignCenter',
  'IconLayoutAlignLeft',
  'IconLayoutAlignMiddle',
  'IconLayoutAlignRight',
  'IconLayoutAlignTop',
  'IconLayoutBoard',
  'IconLayoutBoardSplit',
  'IconLayoutBottombar',
  'IconLayoutBottombarCollapse',
  'IconLayoutBottombarCollapseFilled',
  'IconLayoutBottombarExpand',
  'IconLayoutBottombarExpandFilled',
  'IconLayoutBottombarFilled',
  'IconLayoutCards',
  'IconLayoutCollage',
  'IconLayoutColumns',
  'IconLayoutDashboard',
  'IconLayoutDistributeHorizontal',
  'IconLayoutDistributeVertical',
  'IconLayoutGrid',
  'IconLayoutGridAdd',
  'IconLayoutGridRemove',
  'IconLayoutKanban',
  'IconLayoutList',
  'IconLayoutNavbar',
  'IconLayoutNavbarCollapse',
  'IconLayoutNavbarCollapseFilled',
  'IconLayoutNavbarExpand',
  'IconLayoutNavbarExpandFilled',
  'IconLayoutNavbarFilled',
  'IconLayoutOff',
  'IconLayoutRows',
  'IconLayoutSidebar',
  'IconLayoutSidebarLeftCollapse',
  'IconLayoutSidebarLeftCollapseFilled',
  'IconLayoutSidebarLeftExpand',
  'IconLayoutSidebarLeftExpandFilled',
  'IconLayoutSidebarRight',
  'IconLayoutSidebarRightCollapse',
  'IconLayoutSidebarRightCollapseFilled',
  'IconLayoutSidebarRightExpand',
  'IconLayoutSidebarRightExpandFilled',
  'IconLeaf',
  'IconLeafOff',
  'IconLego',
  'IconLegoOff',
  'IconLemon',
  'IconLemon2',
  'IconLetterA',
  'IconLetterB',
  'IconLetterC',
  'IconLetterCase',
  'IconLetterCaseLower',
  'IconLetterCaseToggle',
  'IconLetterCaseUpper',
  'IconLetterD',
  'IconLetterE',
  'IconLetterF',
  'IconLetterG',
  'IconLetterH',
  'IconLetterI',
  'IconLetterJ',
  'IconLetterK',
  'IconLetterL',
  'IconLetterM',
  'IconLetterN',
  'IconLetterO',
  'IconLetterP',
  'IconLetterQ',
  'IconLetterR',
  'IconLetterS',
  'IconLetterSpacing',
  'IconLetterT',
  'IconLetterU',
  'IconLetterV',
  'IconLetterW',
  'IconLetterX',
  'IconLetterY',
  'IconLetterZ',
  'IconLicense',
  'IconLicenseOff',
  'IconLifebuoy',
  'IconLifebuoyOff',
  'IconLighter',
  'IconLine',
  'IconLineDashed',
  'IconLineDotted',
  'IconLineHeight',
  'IconLink',
  'IconLinkOff',
  'IconList',
  'IconListCheck',
  'IconListDetails',
  'IconListLetters',
  'IconListNumbers',
  'IconListSearch',
  'IconListTree',
  'IconLivePhoto',
  'IconLivePhotoOff',
  'IconLiveView',
  'IconLoadBalancer',
  'IconLoader',
  'IconLoader2',
  'IconLoader3',
  'IconLoaderQuarter',
  'IconLocation',
  'IconLocationBolt',
  'IconLocationBroken',
  'IconLocationCancel',
  'IconLocationCheck',
  'IconLocationCode',
  'IconLocationCog',
  'IconLocationDiscount',
  'IconLocationDollar',
  'IconLocationDown',
  'IconLocationExclamation',
  'IconLocationFilled',
  'IconLocationHeart',
  'IconLocationMinus',
  'IconLocationOff',
  'IconLocationPause',
  'IconLocationPin',
  'IconLocationPlus',
  'IconLocationQuestion',
  'IconLocationSearch',
  'IconLocationShare',
  'IconLocationStar',
  'IconLocationUp',
  'IconLocationX',
  'IconLock',
  'IconLockAccess',
  'IconLockAccessOff',
  'IconLockBolt',
  'IconLockCancel',
  'IconLockCheck',
  'IconLockCode',
  'IconLockCog',
  'IconLockDollar',
  'IconLockDown',
  'IconLockExclamation',
  'IconLockHeart',
  'IconLockMinus',
  'IconLockOff',
  'IconLockOpen',
  'IconLockOpenOff',
  'IconLockPause',
  'IconLockPin',
  'IconLockPlus',
  'IconLockQuestion',
  'IconLockSearch',
  'IconLockShare',
  'IconLockSquare',
  'IconLockSquareRounded',
  'IconLockSquareRoundedFilled',
  'IconLockStar',
  'IconLockUp',
  'IconLockX',
  'IconLogicAnd',
  'IconLogicBuffer',
  'IconLogicNand',
  'IconLogicNor',
  'IconLogicNot',
  'IconLogicOr',
  'IconLogicXnor',
  'IconLogicXor',
  'IconLogin',
  'IconLogout',
  'IconLogout2',
  'IconLollipop',
  'IconLollipopOff',
  'IconLuggage',
  'IconLuggageOff',
  'IconLungs',
  'IconLungsOff',
  'IconMacro',
  'IconMacroOff',
  'IconMagnet',
  'IconMagnetOff',
  'IconMail',
  'IconMailAi',
  'IconMailBolt',
  'IconMailCancel',
  'IconMailCheck',
  'IconMailCode',
  'IconMailCog',
  'IconMailDollar',
  'IconMailDown',
  'IconMailExclamation',
  'IconMailFast',
  'IconMailFilled',
  'IconMailForward',
  'IconMailHeart',
  'IconMailMinus',
  'IconMailOff',
  'IconMailOpened',
  'IconMailOpenedFilled',
  'IconMailPause',
  'IconMailPin',
  'IconMailPlus',
  'IconMailQuestion',
  'IconMailSearch',
  'IconMailShare',
  'IconMailStar',
  'IconMailUp',
  'IconMailX',
  'IconMailbox',
  'IconMailboxOff',
  'IconMan',
  'IconManualGearbox',
  'IconMap',
  'IconMap2',
  'IconMapBolt',
  'IconMapCancel',
  'IconMapCheck',
  'IconMapCode',
  'IconMapCog',
  'IconMapDiscount',
  'IconMapDollar',
  'IconMapDown',
  'IconMapEast',
  'IconMapExclamation',
  'IconMapHeart',
  'IconMapMinus',
  'IconMapNorth',
  'IconMapOff',
  'IconMapPause',
  'IconMapPin',
  'IconMapPin2',
  'IconMapPinBolt',
  'IconMapPinCancel',
  'IconMapPinCheck',
  'IconMapPinCode',
  'IconMapPinCog',
  'IconMapPinDollar',
  'IconMapPinDown',
  'IconMapPinExclamation',
  'IconMapPinFilled',
  'IconMapPinHeart',
  'IconMapPinMinus',
  'IconMapPinOff',
  'IconMapPinPause',
  'IconMapPinPin',
  'IconMapPinPlus',
  'IconMapPinQuestion',
  'IconMapPinSearch',
  'IconMapPinShare',
  'IconMapPinStar',
  'IconMapPinUp',
  'IconMapPinX',
  'IconMapPins',
  'IconMapPlus',
  'IconMapQuestion',
  'IconMapSearch',
  'IconMapShare',
  'IconMapSouth',
  'IconMapStar',
  'IconMapUp',
  'IconMapWest',
  'IconMapX',
  'IconMarkdown',
  'IconMarkdownOff',
  'IconMarquee',
  'IconMarquee2',
  'IconMarqueeOff',
  'IconMars',
  'IconMask',
  'IconMaskOff',
  'IconMasksTheater',
  'IconMasksTheaterOff',
  'IconMassage',
  'IconMatchstick',
  'IconMath',
  'IconMath1Divide2',
  'IconMath1Divide3',
  'IconMathAvg',
  'IconMathEqualGreater',
  'IconMathEqualLower',
  'IconMathFunction',
  'IconMathFunctionOff',
  'IconMathFunctionY',
  'IconMathGreater',
  'IconMathIntegral',
  'IconMathIntegralX',
  'IconMathIntegrals',
  'IconMathLower',
  'IconMathMax',
  'IconMathMin',
  'IconMathNot',
  'IconMathOff',
  'IconMathPi',
  'IconMathPiDivide2',
  'IconMathSymbols',
  'IconMathXDivide2',
  'IconMathXDivideY',
  'IconMathXDivideY2',
  'IconMathXMinusX',
  'IconMathXMinusY',
  'IconMathXPlusX',
  'IconMathXPlusY',
  'IconMathXy',
  'IconMathYMinusY',
  'IconMathYPlusY',
  'IconMaximize',
  'IconMaximizeOff',
  'IconMeat',
  'IconMeatOff',
  'IconMedal',
  'IconMedal2',
  'IconMedicalCross',
  'IconMedicalCrossCircle',
  'IconMedicalCrossFilled',
  'IconMedicalCrossOff',
  'IconMedicineSyrup',
  'IconMeeple',
  'IconMenorah',
  'IconMenu',
  'IconMenu2',
  'IconMenuDeep',
  'IconMenuOrder',
  'IconMessage',
  'IconMessage2',
  'IconMessage2Bolt',
  'IconMessage2Cancel',
  'IconMessage2Check',
  'IconMessage2Code',
  'IconMessage2Cog',
  'IconMessage2Dollar',
  'IconMessage2Down',
  'IconMessage2Exclamation',
  'IconMessage2Heart',
  'IconMessage2Minus',
  'IconMessage2Off',
  'IconMessage2Pause',
  'IconMessage2Pin',
  'IconMessage2Plus',
  'IconMessage2Question',
  'IconMessage2Search',
  'IconMessage2Share',
  'IconMessage2Star',
  'IconMessage2Up',
  'IconMessage2X',
  'IconMessageBolt',
  'IconMessageCancel',
  'IconMessageChatbot',
  'IconMessageCheck',
  'IconMessageCircle',
  'IconMessageCircle2',
  'IconMessageCircle2Filled',
  'IconMessageCircleBolt',
  'IconMessageCircleCancel',
  'IconMessageCircleCheck',
  'IconMessageCircleCode',
  'IconMessageCircleCog',
  'IconMessageCircleDollar',
  'IconMessageCircleDown',
  'IconMessageCircleExclamation',
  'IconMessageCircleHeart',
  'IconMessageCircleMinus',
  'IconMessageCircleOff',
  'IconMessageCirclePause',
  'IconMessageCirclePin',
  'IconMessageCirclePlus',
  'IconMessageCircleQuestion',
  'IconMessageCircleSearch',
  'IconMessageCircleShare',
  'IconMessageCircleStar',
  'IconMessageCircleUp',
  'IconMessageCircleX',
  'IconMessageCode',
  'IconMessageCog',
  'IconMessageDollar',
  'IconMessageDots',
  'IconMessageDown',
  'IconMessageExclamation',
  'IconMessageForward',
  'IconMessageHeart',
  'IconMessageLanguage',
  'IconMessageMinus',
  'IconMessageOff',
  'IconMessagePause',
  'IconMessagePin',
  'IconMessagePlus',
  'IconMessageQuestion',
  'IconMessageReport',
  'IconMessageSearch',
  'IconMessageShare',
  'IconMessageStar',
  'IconMessageUp',
  'IconMessageX',
  'IconMessages',
  'IconMessagesOff',
  'IconMeteor',
  'IconMeteorOff',
  'IconMichelinBibGourmand',
  'IconMichelinStar',
  'IconMichelinStarGreen',
  'IconMickey',
  'IconMickeyFilled',
  'IconMicrophone',
  'IconMicrophone2',
  'IconMicrophone2Off',
  'IconMicrophoneOff',
  'IconMicroscope',
  'IconMicroscopeOff',
  'IconMicrowave',
  'IconMicrowaveOff',
  'IconMilitaryAward',
  'IconMilitaryRank',
  'IconMilk',
  'IconMilkOff',
  'IconMilkshake',
  'IconMinimize',
  'IconMinus',
  'IconMinusVertical',
  'IconMist',
  'IconMistOff',
  'IconMobiledata',
  'IconMobiledataOff',
  'IconMoneybag',
  'IconMoodAngry',
  'IconMoodAnnoyed',
  'IconMoodAnnoyed2',
  'IconMoodBoy',
  'IconMoodCheck',
  'IconMoodCog',
  'IconMoodConfuzed',
  'IconMoodConfuzedFilled',
  'IconMoodCrazyHappy',
  'IconMoodCry',
  'IconMoodDollar',
  'IconMoodEdit',
  'IconMoodEmpty',
  'IconMoodEmptyFilled',
  'IconMoodHappy',
  'IconMoodHappyFilled',
  'IconMoodHeart',
  'IconMoodKid',
  'IconMoodKidFilled',
  'IconMoodLookLeft',
  'IconMoodLookRight',
  'IconMoodMinus',
  'IconMoodNerd',
  'IconMoodNervous',
  'IconMoodNeutral',
  'IconMoodNeutralFilled',
  'IconMoodOff',
  'IconMoodPin',
  'IconMoodPlus',
  'IconMoodSad',
  'IconMoodSad2',
  'IconMoodSadDizzy',
  'IconMoodSadFilled',
  'IconMoodSadSquint',
  'IconMoodSearch',
  'IconMoodShare',
  'IconMoodSick',
  'IconMoodSilence',
  'IconMoodSing',
  'IconMoodSmile',
  'IconMoodSmileBeam',
  'IconMoodSmileDizzy',
  'IconMoodSmileFilled',
  'IconMoodSuprised',
  'IconMoodTongue',
  'IconMoodTongueWink',
  'IconMoodTongueWink2',
  'IconMoodUnamused',
  'IconMoodUp',
  'IconMoodWink',
  'IconMoodWink2',
  'IconMoodWrrr',
  'IconMoodX',
  'IconMoodXd',
  'IconMoon',
  'IconMoon2',
  'IconMoonFilled',
  'IconMoonOff',
  'IconMoonStars',
  'IconMoped',
  'IconMotorbike',
  'IconMountain',
  'IconMountainOff',
  'IconMouse',
  'IconMouse2',
  'IconMouseFilled',
  'IconMouseOff',
  'IconMoustache',
  'IconMovie',
  'IconMovieOff',
  'IconMug',
  'IconMugOff',
  'IconMultiplier05x',
  'IconMultiplier15x',
  'IconMultiplier1x',
  'IconMultiplier2x',
  'IconMushroom',
  'IconMushroomFilled',
  'IconMushroomOff',
  'IconMusic',
  'IconMusicBolt',
  'IconMusicCancel',
  'IconMusicCheck',
  'IconMusicCode',
  'IconMusicCog',
  'IconMusicDiscount',
  'IconMusicDollar',
  'IconMusicDown',
  'IconMusicExclamation',
  'IconMusicHeart',
  'IconMusicMinus',
  'IconMusicOff',
  'IconMusicPause',
  'IconMusicPin',
  'IconMusicPlus',
  'IconMusicQuestion',
  'IconMusicSearch',
  'IconMusicShare',
  'IconMusicStar',
  'IconMusicUp',
  'IconMusicX',
  'IconNavigation',
  'IconNavigationBolt',
  'IconNavigationCancel',
  'IconNavigationCheck',
  'IconNavigationCode',
  'IconNavigationCog',
  'IconNavigationDiscount',
  'IconNavigationDollar',
  'IconNavigationDown',
  'IconNavigationExclamation',
  'IconNavigationFilled',
  'IconNavigationHeart',
  'IconNavigationMinus',
  'IconNavigationNorth',
  'IconNavigationOff',
  'IconNavigationPause',
  'IconNavigationPin',
  'IconNavigationPlus',
  'IconNavigationQuestion',
  'IconNavigationSearch',
  'IconNavigationShare',
  'IconNavigationStar',
  'IconNavigationUp',
  'IconNavigationX',
  'IconNeedle',
  'IconNeedleThread',
  'IconNetwork',
  'IconNetworkOff',
  'IconNewSection',
  'IconNews',
  'IconNewsOff',
  'IconNfc',
  'IconNfcOff',
  'IconNoCopyright',
  'IconNoCreativeCommons',
  'IconNoDerivatives',
  'IconNorthStar',
  'IconNote',
  'IconNoteOff',
  'IconNotebook',
  'IconNotebookOff',
  'IconNotes',
  'IconNotesOff',
  'IconNotification',
  'IconNotificationOff',
  'IconNumber',
  'IconNumber0',
  'IconNumber1',
  'IconNumber2',
  'IconNumber3',
  'IconNumber4',
  'IconNumber5',
  'IconNumber6',
  'IconNumber7',
  'IconNumber8',
  'IconNumber9',
  'IconNumbers',
  'IconNurse',
  'IconNut',
  'IconOctagon',
  'IconOctagonFilled',
  'IconOctagonOff',
  'IconOctahedron',
  'IconOctahedronOff',
  'IconOctahedronPlus',
  'IconOld',
  'IconOlympics',
  'IconOlympicsOff',
  'IconOm',
  'IconOmega',
  'IconOutbound',
  'IconOutlet',
  'IconOval',
  'IconOvalFilled',
  'IconOvalVertical',
  'IconOvalVerticalFilled',
  'IconOverline',
  'IconPackage',
  'IconPackageExport',
  'IconPackageImport',
  'IconPackageOff',
  'IconPackages',
  'IconPacman',
  'IconPageBreak',
  'IconPaint',
  'IconPaintFilled',
  'IconPaintOff',
  'IconPalette',
  'IconPaletteOff',
  'IconPanoramaHorizontal',
  'IconPanoramaHorizontalOff',
  'IconPanoramaVertical',
  'IconPanoramaVerticalOff',
  'IconPaperBag',
  'IconPaperBagOff',
  'IconPaperclip',
  'IconParachute',
  'IconParachuteOff',
  'IconParentheses',
  'IconParenthesesOff',
  'IconParking',
  'IconParkingOff',
  'IconPassword',
  'IconPaw',
  'IconPawFilled',
  'IconPawOff',
  'IconPdf',
  'IconPeace',
  'IconPencil',
  'IconPencilBolt',
  'IconPencilCancel',
  'IconPencilCheck',
  'IconPencilCode',
  'IconPencilCog',
  'IconPencilDiscount',
  'IconPencilDollar',
  'IconPencilDown',
  'IconPencilExclamation',
  'IconPencilHeart',
  'IconPencilMinus',
  'IconPencilOff',
  'IconPencilPause',
  'IconPencilPin',
  'IconPencilPlus',
  'IconPencilQuestion',
  'IconPencilSearch',
  'IconPencilShare',
  'IconPencilStar',
  'IconPencilUp',
  'IconPencilX',
  'IconPennant',
  'IconPennant2',
  'IconPennant2Filled',
  'IconPennantFilled',
  'IconPennantOff',
  'IconPentagon',
  'IconPentagonFilled',
  'IconPentagonOff',
  'IconPentagonPlus',
  'IconPentagram',
  'IconPepper',
  'IconPepperOff',
  'IconPercentage',
  'IconPerfume',
  'IconPerspective',
  'IconPerspectiveOff',
  'IconPhone',
  'IconPhoneCall',
  'IconPhoneCalling',
  'IconPhoneCheck',
  'IconPhoneFilled',
  'IconPhoneIncoming',
  'IconPhoneOff',
  'IconPhoneOutgoing',
  'IconPhonePause',
  'IconPhonePlus',
  'IconPhoneX',
  'IconPhoto',
  'IconPhotoAi',
  'IconPhotoBolt',
  'IconPhotoCancel',
  'IconPhotoCheck',
  'IconPhotoCircle',
  'IconPhotoCircleMinus',
  'IconPhotoCirclePlus',
  'IconPhotoCode',
  'IconPhotoCog',
  'IconPhotoDollar',
  'IconPhotoDown',
  'IconPhotoEdit',
  'IconPhotoExclamation',
  'IconPhotoFilled',
  'IconPhotoHeart',
  'IconPhotoHexagon',
  'IconPhotoMinus',
  'IconPhotoOff',
  'IconPhotoPause',
  'IconPhotoPentagon',
  'IconPhotoPin',
  'IconPhotoPlus',
  'IconPhotoQuestion',
  'IconPhotoSearch',
  'IconPhotoSensor',
  'IconPhotoSensor2',
  'IconPhotoSensor3',
  'IconPhotoShare',
  'IconPhotoShield',
  'IconPhotoSquareRounded',
  'IconPhotoStar',
  'IconPhotoUp',
  'IconPhotoX',
  'IconPhysotherapist',
  'IconPiano',
  'IconPick',
  'IconPictureInPicture',
  'IconPictureInPictureOff',
  'IconPictureInPictureOn',
  'IconPictureInPictureTop',
  'IconPig',
  'IconPigMoney',
  'IconPigOff',
  'IconPilcrow',
  'IconPill',
  'IconPillOff',
  'IconPills',
  'IconPin',
  'IconPinFilled',
  'IconPingPong',
  'IconPinned',
  'IconPinnedFilled',
  'IconPinnedOff',
  'IconPizza',
  'IconPizzaOff',
  'IconPlaceholder',
  'IconPlane',
  'IconPlaneArrival',
  'IconPlaneDeparture',
  'IconPlaneInflight',
  'IconPlaneOff',
  'IconPlaneTilt',
  'IconPlanet',
  'IconPlanetOff',
  'IconPlant',
  'IconPlant2',
  'IconPlant2Off',
  'IconPlantOff',
  'IconPlayBasketball',
  'IconPlayCard',
  'IconPlayCardOff',
  'IconPlayFootball',
  'IconPlayHandball',
  'IconPlayVolleyball',
  'IconPlayerEject',
  'IconPlayerEjectFilled',
  'IconPlayerPause',
  'IconPlayerPauseFilled',
  'IconPlayerPlay',
  'IconPlayerPlayFilled',
  'IconPlayerRecord',
  'IconPlayerRecordFilled',
  'IconPlayerSkipBack',
  'IconPlayerSkipBackFilled',
  'IconPlayerSkipForward',
  'IconPlayerSkipForwardFilled',
  'IconPlayerStop',
  'IconPlayerStopFilled',
  'IconPlayerTrackNext',
  'IconPlayerTrackNextFilled',
  'IconPlayerTrackPrev',
  'IconPlayerTrackPrevFilled',
  'IconPlaylist',
  'IconPlaylistAdd',
  'IconPlaylistOff',
  'IconPlaylistX',
  'IconPlaystationCircle',
  'IconPlaystationSquare',
  'IconPlaystationTriangle',
  'IconPlaystationX',
  'IconPlug',
  'IconPlugConnected',
  'IconPlugConnectedX',
  'IconPlugOff',
  'IconPlugX',
  'IconPlus',
  'IconPlusEqual',
  'IconPlusMinus',
  'IconPng',
  'IconPodium',
  'IconPodiumOff',
  'IconPoint',
  'IconPointFilled',
  'IconPointOff',
  'IconPointer',
  'IconPointerBolt',
  'IconPointerCancel',
  'IconPointerCheck',
  'IconPointerCode',
  'IconPointerCog',
  'IconPointerDollar',
  'IconPointerDown',
  'IconPointerExclamation',
  'IconPointerFilled',
  'IconPointerHeart',
  'IconPointerMinus',
  'IconPointerOff',
  'IconPointerPause',
  'IconPointerPin',
  'IconPointerPlus',
  'IconPointerQuestion',
  'IconPointerSearch',
  'IconPointerShare',
  'IconPointerStar',
  'IconPointerUp',
  'IconPointerX',
  'IconPokeball',
  'IconPokeballOff',
  'IconPokerChip',
  'IconPolaroid',
  'IconPolaroidFilled',
  'IconPolygon',
  'IconPolygonOff',
  'IconPoo',
  'IconPool',
  'IconPoolOff',
  'IconPower',
  'IconPray',
  'IconPremiumRights',
  'IconPrescription',
  'IconPresentation',
  'IconPresentationAnalytics',
  'IconPresentationOff',
  'IconPrinter',
  'IconPrinterOff',
  'IconPrism',
  'IconPrismOff',
  'IconPrismPlus',
  'IconPrison',
  'IconProgress',
  'IconProgressAlert',
  'IconProgressBolt',
  'IconProgressCheck',
  'IconProgressDown',
  'IconProgressHelp',
  'IconProgressX',
  'IconPrompt',
  'IconPropeller',
  'IconPropellerOff',
  'IconPumpkinScary',
  'IconPuzzle',
  'IconPuzzle2',
  'IconPuzzleFilled',
  'IconPuzzleOff',
  'IconPyramid',
  'IconPyramidOff',
  'IconPyramidPlus',
  'IconQrcode',
  'IconQrcodeOff',
  'IconQuestionMark',
  'IconQuote',
  'IconQuoteOff',
  'IconQuotes',
  'IconRadar',
  'IconRadar2',
  'IconRadarOff',
  'IconRadio',
  'IconRadioOff',
  'IconRadioactive',
  'IconRadioactiveFilled',
  'IconRadioactiveOff',
  'IconRadiusBottomLeft',
  'IconRadiusBottomRight',
  'IconRadiusTopLeft',
  'IconRadiusTopRight',
  'IconRainbow',
  'IconRainbowOff',
  'IconRating12Plus',
  'IconRating14Plus',
  'IconRating16Plus',
  'IconRating18Plus',
  'IconRating21Plus',
  'IconRazor',
  'IconRazorElectric',
  'IconReceipt',
  'IconReceipt2',
  'IconReceiptOff',
  'IconReceiptRefund',
  'IconReceiptTax',
  'IconRecharging',
  'IconRecordMail',
  'IconRecordMailOff',
  'IconRectangle',
  'IconRectangleFilled',
  'IconRectangleRoundedBottom',
  'IconRectangleRoundedTop',
  'IconRectangleVertical',
  'IconRectangleVerticalFilled',
  'IconRectangularPrism',
  'IconRectangularPrismOff',
  'IconRectangularPrismPlus',
  'IconRecycle',
  'IconRecycleOff',
  'IconRefresh',
  'IconRefreshAlert',
  'IconRefreshDot',
  'IconRefreshOff',
  'IconRegex',
  'IconRegexOff',
  'IconRegistered',
  'IconRelationManyToMany',
  'IconRelationOneToMany',
  'IconRelationOneToOne',
  'IconReload',
  'IconReorder',
  'IconRepeat',
  'IconRepeatOff',
  'IconRepeatOnce',
  'IconReplace',
  'IconReplaceFilled',
  'IconReplaceOff',
  'IconReport',
  'IconReportAnalytics',
  'IconReportMedical',
  'IconReportMoney',
  'IconReportOff',
  'IconReportSearch',
  'IconReservedLine',
  'IconResize',
  'IconRestore',
  'IconRewindBackward10',
  'IconRewindBackward15',
  'IconRewindBackward20',
  'IconRewindBackward30',
  'IconRewindBackward40',
  'IconRewindBackward5',
  'IconRewindBackward50',
  'IconRewindBackward60',
  'IconRewindForward10',
  'IconRewindForward15',
  'IconRewindForward20',
  'IconRewindForward30',
  'IconRewindForward40',
  'IconRewindForward5',
  'IconRewindForward50',
  'IconRewindForward60',
  'IconRibbonHealth',
  'IconRings',
  'IconRipple',
  'IconRippleOff',
  'IconRoad',
  'IconRoadOff',
  'IconRoadSign',
  'IconRobot',
  'IconRobotOff',
  'IconRocket',
  'IconRocketOff',
  'IconRollerSkating',
  'IconRollercoaster',
  'IconRollercoasterOff',
  'IconRosette',
  'IconRosetteFilled',
  'IconRosetteNumber0',
  'IconRosetteNumber1',
  'IconRosetteNumber2',
  'IconRosetteNumber3',
  'IconRosetteNumber4',
  'IconRosetteNumber5',
  'IconRosetteNumber6',
  'IconRosetteNumber7',
  'IconRosetteNumber8',
  'IconRosetteNumber9',
  'IconRotate',
  'IconRotate2',
  'IconRotate360',
  'IconRotateClockwise',
  'IconRotateClockwise2',
  'IconRotateDot',
  'IconRotateRectangle',
  'IconRoute',
  'IconRoute2',
  'IconRouteOff',
  'IconRouter',
  'IconRouterOff',
  'IconRowInsertBottom',
  'IconRowInsertTop',
  'IconRowRemove',
  'IconRss',
  'IconRubberStamp',
  'IconRubberStampOff',
  'IconRuler',
  'IconRuler2',
  'IconRuler2Off',
  'IconRuler3',
  'IconRulerMeasure',
  'IconRulerOff',
  'IconRun',
  'IconSTurnDown',
  'IconSTurnLeft',
  'IconSTurnRight',
  'IconSTurnUp',
  'IconSailboat',
  'IconSailboat2',
  'IconSailboatOff',
  'IconSalad',
  'IconSalt',
  'IconSatellite',
  'IconSatelliteOff',
  'IconSausage',
  'IconScale',
  'IconScaleOff',
  'IconScaleOutline',
  'IconScaleOutlineOff',
  'IconScan',
  'IconScanEye',
  'IconSchema',
  'IconSchemaOff',
  'IconSchool',
  'IconSchoolBell',
  'IconSchoolOff',
  'IconScissors',
  'IconScissorsOff',
  'IconScooter',
  'IconScooterElectric',
  'IconScoreboard',
  'IconScreenShare',
  'IconScreenShareOff',
  'IconScreenshot',
  'IconScribble',
  'IconScribbleOff',
  'IconScript',
  'IconScriptMinus',
  'IconScriptPlus',
  'IconScriptX',
  'IconScubaMask',
  'IconScubaMaskOff',
  'IconSdk',
  'IconSearch',
  'IconSearchOff',
  'IconSection',
  'IconSectionSign',
  'IconSeeding',
  'IconSeedingOff',
  'IconSelect',
  'IconSelectAll',
  'IconSelector',
  'IconSend',
  'IconSendOff',
  'IconSeo',
  'IconSeparator',
  'IconSeparatorHorizontal',
  'IconSeparatorVertical',
  'IconServer',
  'IconServer2',
  'IconServerBolt',
  'IconServerCog',
  'IconServerOff',
  'IconServicemark',
  'IconSettings',
  'IconSettings2',
  'IconSettingsAutomation',
  'IconSettingsBolt',
  'IconSettingsCancel',
  'IconSettingsCheck',
  'IconSettingsCode',
  'IconSettingsCog',
  'IconSettingsDollar',
  'IconSettingsDown',
  'IconSettingsExclamation',
  'IconSettingsFilled',
  'IconSettingsHeart',
  'IconSettingsMinus',
  'IconSettingsOff',
  'IconSettingsPause',
  'IconSettingsPin',
  'IconSettingsPlus',
  'IconSettingsQuestion',
  'IconSettingsSearch',
  'IconSettingsShare',
  'IconSettingsStar',
  'IconSettingsUp',
  'IconSettingsX',
  'IconShadow',
  'IconShadowOff',
  'IconShape',
  'IconShape2',
  'IconShape3',
  'IconShapeOff',
  'IconShare',
  'IconShare2',
  'IconShare3',
  'IconShareOff',
  'IconShiJumping',
  'IconShield',
  'IconShieldBolt',
  'IconShieldCancel',
  'IconShieldCheck',
  'IconShieldCheckFilled',
  'IconShieldCheckered',
  'IconShieldCheckeredFilled',
  'IconShieldChevron',
  'IconShieldCode',
  'IconShieldCog',
  'IconShieldDollar',
  'IconShieldDown',
  'IconShieldExclamation',
  'IconShieldFilled',
  'IconShieldHalf',
  'IconShieldHalfFilled',
  'IconShieldHeart',
  'IconShieldLock',
  'IconShieldLockFilled',
  'IconShieldMinus',
  'IconShieldOff',
  'IconShieldPause',
  'IconShieldPin',
  'IconShieldPlus',
  'IconShieldQuestion',
  'IconShieldSearch',
  'IconShieldShare',
  'IconShieldStar',
  'IconShieldUp',
  'IconShieldX',
  'IconShip',
  'IconShipOff',
  'IconShirt',
  'IconShirtFilled',
  'IconShirtOff',
  'IconShirtSport',
  'IconShoe',
  'IconShoeOff',
  'IconShoppingBag',
  'IconShoppingBagCheck',
  'IconShoppingBagDiscount',
  'IconShoppingBagEdit',
  'IconShoppingBagExclamation',
  'IconShoppingBagMinus',
  'IconShoppingBagPlus',
  'IconShoppingBagSearch',
  'IconShoppingBagX',
  'IconShoppingCart',
  'IconShoppingCartBolt',
  'IconShoppingCartCancel',
  'IconShoppingCartCheck',
  'IconShoppingCartCode',
  'IconShoppingCartCog',
  'IconShoppingCartCopy',
  'IconShoppingCartDiscount',
  'IconShoppingCartDollar',
  'IconShoppingCartDown',
  'IconShoppingCartExclamation',
  'IconShoppingCartFilled',
  'IconShoppingCartHeart',
  'IconShoppingCartMinus',
  'IconShoppingCartOff',
  'IconShoppingCartPause',
  'IconShoppingCartPin',
  'IconShoppingCartPlus',
  'IconShoppingCartQuestion',
  'IconShoppingCartSearch',
  'IconShoppingCartShare',
  'IconShoppingCartStar',
  'IconShoppingCartUp',
  'IconShoppingCartX',
  'IconShovel',
  'IconShredder',
  'IconSignLeft',
  'IconSignLeftFilled',
  'IconSignRight',
  'IconSignRightFilled',
  'IconSignal2g',
  'IconSignal3g',
  'IconSignal4g',
  'IconSignal4gPlus',
  'IconSignal5g',
  'IconSignal6g',
  'IconSignalE',
  'IconSignalG',
  'IconSignalH',
  'IconSignalHPlus',
  'IconSignalLte',
  'IconSignature',
  'IconSignatureOff',
  'IconSitemap',
  'IconSitemapOff',
  'IconSkateboard',
  'IconSkateboardOff',
  'IconSkateboarding',
  'IconSkull',
  'IconSlash',
  'IconSlashes',
  'IconSleigh',
  'IconSlice',
  'IconSlideshow',
  'IconSmartHome',
  'IconSmartHomeOff',
  'IconSmoking',
  'IconSmokingNo',
  'IconSnowflake',
  'IconSnowflakeOff',
  'IconSnowman',
  'IconSoccerField',
  'IconSocial',
  'IconSocialOff',
  'IconSock',
  'IconSofa',
  'IconSofaOff',
  'IconSolarPanel',
  'IconSolarPanel2',
  'IconSort09',
  'IconSort90',
  'IconSortAZ',
  'IconSortAscending',
  'IconSortAscending2',
  'IconSortAscendingLetters',
  'IconSortAscendingNumbers',
  'IconSortDescending',
  'IconSortDescending2',
  'IconSortDescendingLetters',
  'IconSortDescendingNumbers',
  'IconSortZA',
  'IconSos',
  'IconSoup',
  'IconSoupOff',
  'IconSourceCode',
  'IconSpace',
  'IconSpaceOff',
  'IconSpacingHorizontal',
  'IconSpacingVertical',
  'IconSpade',
  'IconSpadeFilled',
  'IconSparkles',
  'IconSpeakerphone',
  'IconSpeedboat',
  'IconSphere',
  'IconSphereOff',
  'IconSpherePlus',
  'IconSpider',
  'IconSpiral',
  'IconSpiralOff',
  'IconSportBillard',
  'IconSpray',
  'IconSpy',
  'IconSpyOff',
  'IconSql',
  'IconSquare',
  'IconSquare0Filled',
  'IconSquare1Filled',
  'IconSquare2Filled',
  'IconSquare3Filled',
  'IconSquare4Filled',
  'IconSquare5Filled',
  'IconSquare6Filled',
  'IconSquare7Filled',
  'IconSquare8Filled',
  'IconSquare9Filled',
  'IconSquareArrowDown',
  'IconSquareArrowDownFilled',
  'IconSquareArrowLeft',
  'IconSquareArrowLeftFilled',
  'IconSquareArrowRight',
  'IconSquareArrowRightFilled',
  'IconSquareArrowUp',
  'IconSquareArrowUpFilled',
  'IconSquareAsterisk',
  'IconSquareAsteriskFilled',
  'IconSquareCheck',
  'IconSquareCheckFilled',
  'IconSquareChevronDown',
  'IconSquareChevronDownFilled',
  'IconSquareChevronLeft',
  'IconSquareChevronLeftFilled',
  'IconSquareChevronRight',
  'IconSquareChevronRightFilled',
  'IconSquareChevronUp',
  'IconSquareChevronUpFilled',
  'IconSquareChevronsDown',
  'IconSquareChevronsDownFilled',
  'IconSquareChevronsLeft',
  'IconSquareChevronsLeftFilled',
  'IconSquareChevronsRight',
  'IconSquareChevronsRightFilled',
  'IconSquareChevronsUp',
  'IconSquareChevronsUpFilled',
  'IconSquareDot',
  'IconSquareDotFilled',
  'IconSquareF0',
  'IconSquareF0Filled',
  'IconSquareF1',
  'IconSquareF1Filled',
  'IconSquareF2',
  'IconSquareF2Filled',
  'IconSquareF3',
  'IconSquareF3Filled',
  'IconSquareF4',
  'IconSquareF4Filled',
  'IconSquareF5',
  'IconSquareF5Filled',
  'IconSquareF6',
  'IconSquareF6Filled',
  'IconSquareF7',
  'IconSquareF7Filled',
  'IconSquareF8',
  'IconSquareF8Filled',
  'IconSquareF9',
  'IconSquareF9Filled',
  'IconSquareFilled',
  'IconSquareForbid',
  'IconSquareForbid2',
  'IconSquareHalf',
  'IconSquareKey',
  'IconSquareLetterA',
  'IconSquareLetterB',
  'IconSquareLetterC',
  'IconSquareLetterD',
  'IconSquareLetterE',
  'IconSquareLetterF',
  'IconSquareLetterG',
  'IconSquareLetterH',
  'IconSquareLetterI',
  'IconSquareLetterJ',
  'IconSquareLetterK',
  'IconSquareLetterL',
  'IconSquareLetterM',
  'IconSquareLetterN',
  'IconSquareLetterO',
  'IconSquareLetterP',
  'IconSquareLetterQ',
  'IconSquareLetterR',
  'IconSquareLetterS',
  'IconSquareLetterT',
  'IconSquareLetterU',
  'IconSquareLetterV',
  'IconSquareLetterW',
  'IconSquareLetterX',
  'IconSquareLetterY',
  'IconSquareLetterZ',
  'IconSquareMinus',
  'IconSquareMinusFilled',
  'IconSquareNumber0',
  'IconSquareNumber1',
  'IconSquareNumber2',
  'IconSquareNumber3',
  'IconSquareNumber4',
  'IconSquareNumber5',
  'IconSquareNumber6',
  'IconSquareNumber7',
  'IconSquareNumber8',
  'IconSquareNumber9',
  'IconSquareOff',
  'IconSquarePlus',
  'IconSquareRoot',
  'IconSquareRoot2',
  'IconSquareRotated',
  'IconSquareRotatedFilled',
  'IconSquareRotatedForbid',
  'IconSquareRotatedForbid2',
  'IconSquareRotatedOff',
  'IconSquareRounded',
  'IconSquareRoundedArrowDown',
  'IconSquareRoundedArrowDownFilled',
  'IconSquareRoundedArrowLeft',
  'IconSquareRoundedArrowLeftFilled',
  'IconSquareRoundedArrowRight',
  'IconSquareRoundedArrowRightFilled',
  'IconSquareRoundedArrowUp',
  'IconSquareRoundedArrowUpFilled',
  'IconSquareRoundedCheck',
  'IconSquareRoundedCheckFilled',
  'IconSquareRoundedChevronDown',
  'IconSquareRoundedChevronDownFilled',
  'IconSquareRoundedChevronLeft',
  'IconSquareRoundedChevronLeftFilled',
  'IconSquareRoundedChevronRight',
  'IconSquareRoundedChevronRightFilled',
  'IconSquareRoundedChevronUp',
  'IconSquareRoundedChevronUpFilled',
  'IconSquareRoundedChevronsDown',
  'IconSquareRoundedChevronsDownFilled',
  'IconSquareRoundedChevronsLeft',
  'IconSquareRoundedChevronsLeftFilled',
  'IconSquareRoundedChevronsRight',
  'IconSquareRoundedChevronsRightFilled',
  'IconSquareRoundedChevronsUp',
  'IconSquareRoundedChevronsUpFilled',
  'IconSquareRoundedFilled',
  'IconSquareRoundedLetterA',
  'IconSquareRoundedLetterB',
  'IconSquareRoundedLetterC',
  'IconSquareRoundedLetterD',
  'IconSquareRoundedLetterE',
  'IconSquareRoundedLetterF',
  'IconSquareRoundedLetterG',
  'IconSquareRoundedLetterH',
  'IconSquareRoundedLetterI',
  'IconSquareRoundedLetterJ',
  'IconSquareRoundedLetterK',
  'IconSquareRoundedLetterL',
  'IconSquareRoundedLetterM',
  'IconSquareRoundedLetterN',
  'IconSquareRoundedLetterO',
  'IconSquareRoundedLetterP',
  'IconSquareRoundedLetterQ',
  'IconSquareRoundedLetterR',
  'IconSquareRoundedLetterS',
  'IconSquareRoundedLetterT',
  'IconSquareRoundedLetterU',
  'IconSquareRoundedLetterV',
  'IconSquareRoundedLetterW',
  'IconSquareRoundedLetterX',
  'IconSquareRoundedLetterY',
  'IconSquareRoundedLetterZ',
  'IconSquareRoundedMinus',
  'IconSquareRoundedMinusFilled',
  'IconSquareRoundedNumber0',
  'IconSquareRoundedNumber0Filled',
  'IconSquareRoundedNumber1',
  'IconSquareRoundedNumber1Filled',
  'IconSquareRoundedNumber2',
  'IconSquareRoundedNumber2Filled',
  'IconSquareRoundedNumber3',
  'IconSquareRoundedNumber3Filled',
  'IconSquareRoundedNumber4',
  'IconSquareRoundedNumber4Filled',
  'IconSquareRoundedNumber5',
  'IconSquareRoundedNumber5Filled',
  'IconSquareRoundedNumber6',
  'IconSquareRoundedNumber6Filled',
  'IconSquareRoundedNumber7',
  'IconSquareRoundedNumber7Filled',
  'IconSquareRoundedNumber8',
  'IconSquareRoundedNumber8Filled',
  'IconSquareRoundedNumber9',
  'IconSquareRoundedNumber9Filled',
  'IconSquareRoundedPlus',
  'IconSquareRoundedPlusFilled',
  'IconSquareRoundedX',
  'IconSquareRoundedXFilled',
  'IconSquareToggle',
  'IconSquareToggleHorizontal',
  'IconSquareX',
  'IconSquareXFilled',
  'IconSquaresDiagonal',
  'IconSquaresFilled',
  'IconStack',
  'IconStack2',
  'IconStack3',
  'IconStackPop',
  'IconStackPush',
  'IconStairs',
  'IconStairsDown',
  'IconStairsUp',
  'IconStar',
  'IconStarFilled',
  'IconStarHalf',
  'IconStarHalfFilled',
  'IconStarOff',
  'IconStars',
  'IconStarsFilled',
  'IconStarsOff',
  'IconStatusChange',
  'IconSteam',
  'IconSteeringWheel',
  'IconSteeringWheelOff',
  'IconStepInto',
  'IconStepOut',
  'IconStereoGlasses',
  'IconStethoscope',
  'IconStethoscopeOff',
  'IconSticker',
  'IconStorm',
  'IconStormOff',
  'IconStretching',
  'IconStretching2',
  'IconStrikethrough',
  'IconSubmarine',
  'IconSubscript',
  'IconSubtask',
  'IconSum',
  'IconSumOff',
  'IconSun',
  'IconSunFilled',
  'IconSunHigh',
  'IconSunLow',
  'IconSunMoon',
  'IconSunOff',
  'IconSunWind',
  'IconSunglasses',
  'IconSunrise',
  'IconSunset',
  'IconSunset2',
  'IconSuperscript',
  'IconSvg',
  'IconSwimming',
  'IconSwipe',
  'IconSwitch',
  'IconSwitch2',
  'IconSwitch3',
  'IconSwitchHorizontal',
  'IconSwitchVertical',
  'IconSword',
  'IconSwordOff',
  'IconSwords',
  'IconTable',
  'IconTableAlias',
  'IconTableColumn',
  'IconTableDown',
  'IconTableExport',
  'IconTableFilled',
  'IconTableHeart',
  'IconTableImport',
  'IconTableMinus',
  'IconTableOff',
  'IconTableOptions',
  'IconTablePlus',
  'IconTableRow',
  'IconTableShare',
  'IconTableShortcut',
  'IconTag',
  'IconTagOff',
  'IconTags',
  'IconTagsOff',
  'IconTallymark1',
  'IconTallymark2',
  'IconTallymark3',
  'IconTallymark4',
  'IconTallymarks',
  'IconTank',
  'IconTarget',
  'IconTargetArrow',
  'IconTargetOff',
  'IconTeapot',
  'IconTelescope',
  'IconTelescopeOff',
  'IconTemperature',
  'IconTemperatureCelsius',
  'IconTemperatureFahrenheit',
  'IconTemperatureMinus',
  'IconTemperatureOff',
  'IconTemperaturePlus',
  'IconTemplate',
  'IconTemplateOff',
  'IconTent',
  'IconTentOff',
  'IconTerminal',
  'IconTerminal2',
  'IconTestPipe',
  'IconTestPipe2',
  'IconTestPipeOff',
  'IconTex',
  'IconTextCaption',
  'IconTextColor',
  'IconTextDecrease',
  'IconTextDirectionLtr',
  'IconTextDirectionRtl',
  'IconTextIncrease',
  'IconTextOrientation',
  'IconTextPlus',
  'IconTextRecognition',
  'IconTextResize',
  'IconTextSize',
  'IconTextSpellcheck',
  'IconTextWrap',
  'IconTextWrapDisabled',
  'IconTexture',
  'IconTheater',
  'IconThermometer',
  'IconThumbDown',
  'IconThumbDownFilled',
  'IconThumbDownOff',
  'IconThumbUp',
  'IconThumbUpFilled',
  'IconThumbUpOff',
  'IconTicTac',
  'IconTicket',
  'IconTicketOff',
  'IconTie',
  'IconTilde',
  'IconTiltShift',
  'IconTiltShiftOff',
  'IconTimeDuration0',
  'IconTimeDuration10',
  'IconTimeDuration15',
  'IconTimeDuration30',
  'IconTimeDuration45',
  'IconTimeDuration5',
  'IconTimeDuration60',
  'IconTimeDuration90',
  'IconTimeDurationOff',
  'IconTimeline',
  'IconTimelineEvent',
  'IconTimelineEventExclamation',
  'IconTimelineEventMinus',
  'IconTimelineEventPlus',
  'IconTimelineEventText',
  'IconTimelineEventX',
  'IconTir',
  'IconToggleLeft',
  'IconToggleRight',
  'IconToiletPaper',
  'IconToiletPaperOff',
  'IconToml',
  'IconTool',
  'IconTools',
  'IconToolsKitchen',
  'IconToolsKitchen2',
  'IconToolsKitchen2Off',
  'IconToolsKitchenOff',
  'IconToolsOff',
  'IconTooltip',
  'IconTopologyBus',
  'IconTopologyComplex',
  'IconTopologyFull',
  'IconTopologyFullHierarchy',
  'IconTopologyRing',
  'IconTopologyRing2',
  'IconTopologyRing3',
  'IconTopologyStar',
  'IconTopologyStar2',
  'IconTopologyStar3',
  'IconTopologyStarRing',
  'IconTopologyStarRing2',
  'IconTopologyStarRing3',
  'IconTorii',
  'IconTornado',
  'IconTournament',
  'IconTower',
  'IconTowerOff',
  'IconTrack',
  'IconTractor',
  'IconTrademark',
  'IconTrafficCone',
  'IconTrafficConeOff',
  'IconTrafficLights',
  'IconTrafficLightsOff',
  'IconTrain',
  'IconTransfer',
  'IconTransferIn',
  'IconTransferOut',
  'IconTransferVertical',
  'IconTransform',
  'IconTransformFilled',
  'IconTransitionBottom',
  'IconTransitionLeft',
  'IconTransitionRight',
  'IconTransitionTop',
  'IconTrash',
  'IconTrashFilled',
  'IconTrashOff',
  'IconTrashX',
  'IconTrashXFilled',
  'IconTreadmill',
  'IconTree',
  'IconTrees',
  'IconTrekking',
  'IconTrendingDown',
  'IconTrendingDown2',
  'IconTrendingDown3',
  'IconTrendingUp',
  'IconTrendingUp2',
  'IconTrendingUp3',
  'IconTriangle',
  'IconTriangleFilled',
  'IconTriangleInverted',
  'IconTriangleInvertedFilled',
  'IconTriangleOff',
  'IconTriangleSquareCircle',
  'IconTriangleSquareCircleFilled',
  'IconTriangles',
  'IconTrident',
  'IconTrolley',
  'IconTrophy',
  'IconTrophyFilled',
  'IconTrophyOff',
  'IconTrowel',
  'IconTruck',
  'IconTruckDelivery',
  'IconTruckLoading',
  'IconTruckOff',
  'IconTruckReturn',
  'IconTxt',
  'IconTypography',
  'IconTypographyOff',
  'IconUfo',
  'IconUfoOff',
  'IconUmbrella',
  'IconUmbrellaFilled',
  'IconUmbrellaOff',
  'IconUnderline',
  'IconUnlink',
  'IconUpload',
  'IconUrgent',
  'IconUsb',
  'IconUser',
  'IconUserBolt',
  'IconUserCancel',
  'IconUserCheck',
  'IconUserCircle',
  'IconUserCode',
  'IconUserCog',
  'IconUserDollar',
  'IconUserDown',
  'IconUserEdit',
  'IconUserExclamation',
  'IconUserHeart',
  'IconUserHexagon',
  'IconUserMinus',
  'IconUserOff',
  'IconUserPause',
  'IconUserPentagon',
  'IconUserPin',
  'IconUserPlus',
  'IconUserQuestion',
  'IconUserSearch',
  'IconUserShare',
  'IconUserShield',
  'IconUserSquare',
  'IconUserSquareRounded',
  'IconUserStar',
  'IconUserUp',
  'IconUserX',
  'IconUsers',
  'IconUsersGroup',
  'IconUsersMinus',
  'IconUsersPlus',
  'IconUvIndex',
  'IconUxCircle',
  'IconVaccine',
  'IconVaccineBottle',
  'IconVaccineBottleOff',
  'IconVaccineOff',
  'IconVacuumCleaner',
  'IconVariable',
  'IconVariableMinus',
  'IconVariableOff',
  'IconVariablePlus',
  'IconVector',
  'IconVectorBezier',
  'IconVectorBezier2',
  'IconVectorBezierArc',
  'IconVectorBezierCircle',
  'IconVectorOff',
  'IconVectorSpline',
  'IconVectorTriangle',
  'IconVectorTriangleOff',
  'IconVenus',
  'IconVersions',
  'IconVersionsFilled',
  'IconVersionsOff',
  'IconVideo',
  'IconVideoMinus',
  'IconVideoOff',
  'IconVideoPlus',
  'IconView360',
  'IconView360Off',
  'IconViewfinder',
  'IconViewfinderOff',
  'IconViewportNarrow',
  'IconViewportWide',
  'IconVinyl',
  'IconVip',
  'IconVipOff',
  'IconVirus',
  'IconVirusOff',
  'IconVirusSearch',
  'IconVocabulary',
  'IconVocabularyOff',
  'IconVolcano',
  'IconVolume',
  'IconVolume2',
  'IconVolume3',
  'IconVolumeOff',
  'IconVs',
  'IconWalk',
  'IconWall',
  'IconWallOff',
  'IconWallet',
  'IconWalletOff',
  'IconWallpaper',
  'IconWallpaperOff',
  'IconWand',
  'IconWandOff',
  'IconWash',
  'IconWashDry',
  'IconWashDry1',
  'IconWashDry2',
  'IconWashDry3',
  'IconWashDryA',
  'IconWashDryDip',
  'IconWashDryF',
  'IconWashDryFlat',
  'IconWashDryHang',
  'IconWashDryOff',
  'IconWashDryP',
  'IconWashDryShade',
  'IconWashDryW',
  'IconWashDryclean',
  'IconWashDrycleanOff',
  'IconWashEco',
  'IconWashGentle',
  'IconWashHand',
  'IconWashMachine',
  'IconWashOff',
  'IconWashPress',
  'IconWashTemperature1',
  'IconWashTemperature2',
  'IconWashTemperature3',
  'IconWashTemperature4',
  'IconWashTemperature5',
  'IconWashTemperature6',
  'IconWashTumbleDry',
  'IconWashTumbleOff',
  'IconWaterpolo',
  'IconWaveSawTool',
  'IconWaveSine',
  'IconWaveSquare',
  'IconWebhook',
  'IconWebhookOff',
  'IconWeight',
  'IconWheel',
  'IconWheelchair',
  'IconWheelchairOff',
  'IconWhirl',
  'IconWifi',
  'IconWifi0',
  'IconWifi1',
  'IconWifi2',
  'IconWifiOff',
  'IconWind',
  'IconWindOff',
  'IconWindmill',
  'IconWindmillFilled',
  'IconWindmillOff',
  'IconWindow',
  'IconWindowMaximize',
  'IconWindowMinimize',
  'IconWindowOff',
  'IconWindsock',
  'IconWiper',
  'IconWiperWash',
  'IconWoman',
  'IconWood',
  'IconWorld',
  'IconWorldBolt',
  'IconWorldCancel',
  'IconWorldCheck',
  'IconWorldCode',
  'IconWorldCog',
  'IconWorldDollar',
  'IconWorldDown',
  'IconWorldDownload',
  'IconWorldExclamation',
  'IconWorldHeart',
  'IconWorldLatitude',
  'IconWorldLongitude',
  'IconWorldMinus',
  'IconWorldOff',
  'IconWorldPause',
  'IconWorldPin',
  'IconWorldPlus',
  'IconWorldQuestion',
  'IconWorldSearch',
  'IconWorldShare',
  'IconWorldStar',
  'IconWorldUp',
  'IconWorldUpload',
  'IconWorldWww',
  'IconWorldX',
  'IconWreckingBall',
  'IconWriting',
  'IconWritingOff',
  'IconWritingSign',
  'IconWritingSignOff',
  'IconX',
  'IconXboxA',
  'IconXboxB',
  'IconXboxX',
  'IconXboxY',
  'IconXd',
  'IconXxx',
  'IconYinYang',
  'IconYinYangFilled',
  'IconYoga',
  'IconZeppelin',
  'IconZeppelinOff',
  'IconZip',
  'IconZodiacAquarius',
  'IconZodiacAries',
  'IconZodiacCancer',
  'IconZodiacCapricorn',
  'IconZodiacGemini',
  'IconZodiacLeo',
  'IconZodiacLibra',
  'IconZodiacPisces',
  'IconZodiacSagittarius',
  'IconZodiacScorpio',
  'IconZodiacTaurus',
  'IconZodiacVirgo',
  'IconZoomCancel',
  'IconZoomCheck',
  'IconZoomCheckFilled',
  'IconZoomCode',
  'IconZoomExclamation',
  'IconZoomFilled',
  'IconZoomIn',
  'IconZoomInArea',
  'IconZoomInAreaFilled',
  'IconZoomInFilled',
  'IconZoomMoney',
  'IconZoomOut',
  'IconZoomOutArea',
  'IconZoomOutFilled',
  'IconZoomPan',
  'IconZoomQuestion',
  'IconZoomReplace',
  'IconZoomReset',
  'IconZzz',
  'IconZzzOff',
] as const;

export const IconTypes = [...TablerIconTypes, ...WeatherIconTypes, ...StringIconTypes, ...TextIconTypes] as const;
export type Icons = (typeof IconTypes)[number];
export type WeatherIcons = (typeof WeatherIconTypes)[number];
export type CustomStringIcons = (typeof StringIconTypes)[number];
export type TextIcons = (typeof TextIconTypes)[number];

import { animate } from 'motion';
import { ParentComponent } from 'solid-js';
import { easing } from 'ts-easing';
import { LoadingSpinner } from '../LoadingSpinner';
import styles from './PullDownToRefresh.module.scss';

export type onRefreshDone = () => void;

interface Props {
  scrollContainer: HTMLDivElement | undefined;
  onRefresh: (done: onRefreshDone) => void;
}

export const PullDownToRefresh: ParentComponent<Props> = props => {
  const scrollSensitivity = 5;
  const refreshAfterDistance = 60;
  const scrollMultiplier = 0.5;
  let loaderRef: HTMLDivElement | undefined;
  let prevScrollTop = -1;
  let startScroll = 0;
  let isRefreshing = false;

  const onTouchMove = (e: TouchEvent) => {
    if (isRefreshing) {
      return false;
    }
    if (props.scrollContainer && loaderRef) {
      const scrollTop = props.scrollContainer.scrollTop;

      if (scrollTop === 0 && prevScrollTop !== scrollTop) {
        startScroll = e.touches[0].pageY;
      } else if (scrollTop === 0 && prevScrollTop === scrollTop) {
        const scrollDistance = (e.touches[0].pageY - startScroll) * scrollMultiplier;

        if (scrollDistance > scrollSensitivity) {
          const height = Math.min(scrollDistance - scrollSensitivity, refreshAfterDistance);
          loaderRef.style.height = `${height}px`;

          if (height === refreshAfterDistance) {
            isRefreshing = true;
            props.onRefresh(doneRefresh);
          }
        }
      }

      prevScrollTop = scrollTop;
    }
  };

  const doneRefresh = () => {
    isRefreshing = false;
    closeLoader();
  };

  const onTouchEnd = () => {
    closeLoader();
  };

  const closeLoader = () => {
    if (loaderRef && !isRefreshing) {
      animate(
        loaderRef,
        { height: 0 },
        {
          duration: 0.2,
          easing: easing.outCubic,
        },
      ).finished.then(() => {
        startScroll = 0;
        prevScrollTop = -1;
      });
    }
  };

  return (
    <div class={styles.container} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div class={styles.loader} ref={loaderRef}>
        <div class={styles.spinner}>
          <LoadingSpinner size="small" />
        </div>
      </div>
      <div class={styles.content}>{props.children}</div>
    </div>
  );
};

import { ParentComponent, Show } from 'solid-js';
import styles from './HeaderBar.module.scss';
import { Image } from '../Image';
import { IconButton } from '../IconButton';
import cn from 'classnames';
import colors from '../../styles/colors.module.scss';
import headwindLogo from '../../assets/images/headwind-logo.svg';
import { Link } from '../Link';
import { StravaButton } from '../StravaButton';
import { initAuth } from '../../api';
import { EllipsisText } from '../EllipsisText';

interface Props {
  title: string;
  showBack?: boolean;
  showMenuIcon?: boolean;
  onMenuClick?: () => void;
  showAuthButton?: boolean;
  showPoweredBy?: boolean;
}

export const HeaderBar: ParentComponent<Props> = props => {
  const onGoBack = () => {
    window.history.back();
  };

  const onMenuClick = () => {
    props.onMenuClick && props.onMenuClick();
  };

  const signUp = () => {
    window.open('https://headwind.app/?utm_source=headwindapp&utm_medium=app&utm_campaign=embed');
  };

  return (
    <header class={cn(styles.container, { [styles.noBack]: !props.showBack })}>
      <Show when={props.showBack}>
        <div class={styles.back}>
          <IconButton burstColor={colors.primaryLight} icon="IconArrowLeft" dark onClick={onGoBack} burstSize="large" />
        </div>
      </Show>
      <Link href="/" class={styles.logo}>
        <Image
          width={40}
          height={40}
          src={headwindLogo}
          transparentBg
          hideLoading
          alt="Headwind"
          lazyload={false}
          objectFit="contain"
        />
      </Link>
      <EllipsisText heading class={styles.title}>
        {props.title}
      </EllipsisText>
      <div class={styles.right}>
        <Show when={props.showPoweredBy}>
          <div class={styles.embedMessage} onClick={signUp}>
            Powered by Headwind
          </div>
        </Show>
        <Show when={props.showAuthButton}>
          <div class={styles.auth}>
            <StravaButton full onClick={initAuth} />
          </div>
        </Show>
        <Show when={props.showMenuIcon}>
          <div class={styles.menu}>
            <IconButton icon="IconMenu2" dark onClick={onMenuClick} />
          </div>
        </Show>
      </div>
    </header>
  );
};

import { ParentComponent, mergeProps } from 'solid-js';
import styles from './ScrollContainer.module.scss';
import { themeClass } from '../ThemeProvider';

interface Props {
  direction?: 'horizontal' | 'vertical';
  thin?: boolean;
  ref?: HTMLDivElement;
  class?: string;
  disabled?: boolean;
  noOverscroll?: boolean;
}

export const ScrollContainer: ParentComponent<Props> = passedProps => {
  const props = mergeProps(
    {
      direction: 'vertical',
      thin: true,
    },
    passedProps,
  );

  return (
    <div
      ref={props.ref}
      class={themeClass(styles.dark, styles.container, {
        [styles.horizontal]: props.direction === 'horizontal',
        [styles.thin]: props.thin,
        [props.class || '']: props.class ? true : false,
        [styles.disabled]: props.disabled,
        [styles.noOverscroll]: props.noOverscroll,
      })}
    >
      {props.children}
    </div>
  );
};
